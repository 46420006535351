@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");
.avatar {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
  display: inline-block;
  position: relative;
}
.dark .avatar {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.avatar-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg,
.avatar-md {
  line-height: 2.5rem;
}
.avatar-lg {
  height: 3rem;
  width: 3rem;
}
.avatar-rounded {
  border-radius: 0.375rem;
}
.avatar-square {
  border-radius: 0;
}
.avatar-circle {
  border-radius: 9999px;
}
.avatar-img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.avatar-string {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.avatar-inner-sm {
  height: 2rem;
}
.avatar-inner-md {
  height: 2.5rem;
}
.avatar-inner-lg {
  height: 3rem;
}
.avatar-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.avatar-icon-sm {
  font-size: 1.125rem;
}
.avatar-icon-md,
.avatar-icon-sm {
  line-height: 1.75rem;
}
.avatar-icon-md {
  font-size: 1.25rem;
}
.avatar-icon-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group-chained > .avatar,
.avatar-group-chained > .tooltip-wrapper {
  margin-left: -0.5rem;
}
[dir="rtl"] .avatar-group-chained > .avatar,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper {
  margin-right: -0.5rem;
}
.avatar-group-chained > .avatar:first-child,
.avatar-group-chained > .tooltip-wrapper:first-child {
  margin-left: 0;
}
[dir="rtl"] .avatar-group-chained > .avatar:first-child,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper:first-child {
  margin-right: 0;
}
.badge {
  padding: 0.125rem 0.5rem;
}
.badge,
.badge-dot {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  border-radius: 9999px;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
}
.badge-dot {
  height: 0.5rem;
  width: 0.5rem;
}
.badge-wrapper {
  display: flex;
  position: relative;
}
.badge-inner {
  --tw-translate-y: -50%;
  position: absolute;
  top: 0;
  z-index: 10;
}
.badge-inner,
[dir="ltr"] .badge-inner {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .badge-inner {
  --tw-translate-x: 50%;
  right: 0;
}
[dir="rtl"] .badge-inner {
  --tw-translate-x: -50%;
  left: 0;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.button {
  font-weight: 600;
  white-space: nowrap;
}
.button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.button.radius-round {
  border-radius: 0.375rem;
}
.button.radius-circle {
  border-radius: 9999px;
}
.button.radius-none {
  border-radius: 0;
}
.card {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
}
.card,
.dark .card {
  --tw-bg-opacity: 1;
}
.dark .card {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.card-border {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-width: 1px;
}
.card-border,
.dark .card-border {
  --tw-border-opacity: 1;
}
.dark .card-border {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.card-shadow {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .card-shadow {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  border-style: none;
}
.card-header-border,
.dark .card-shadow {
  --tw-border-opacity: 1;
}
.card-header-border {
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark .card-header-border {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.card-header-extra {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.card-footer {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.card-footer-border {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-top-width: 1px;
}
.card-footer-border,
.dark .card-footer-border {
  --tw-border-opacity: 1;
}
.dark .card-footer-border {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.card-footer,
.card-header {
  padding: 0.75rem 1.25rem;
}
.card-body {
  padding: 1.25rem;
}
.card-faq {
  padding: 10px;
}
.card-gutterless,
.checkbox {
  padding: 0;
}
.checkbox {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  -webkit-appearance: none;
  appearance: none;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 1.25rem;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 1.25rem;
}
.dark .checkbox {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  border-color: transparent;
}
.checkbox.disabled {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(229, 231, 235, var(--tw-text-opacity));
  cursor: not-allowed;
}
.checkbox.disabled,
.dark .checkbox.disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .checkbox.disabled {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.checkbox-label {
  cursor: pointer;
  display: inline-flex;
  margin-right: 0.75rem;
}
.checkbox-label.disabled {
  cursor: not-allowed;
}
.close-btn {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.close-btn-default {
  border-radius: 0.25rem;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.25rem;
}
.close-btn-default,
.close-btn-default:hover {
  --tw-text-opacity: 1;
}
.close-btn-default:hover {
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .close-btn-default:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  max-width: 20rem;
  padding: 1rem;
  z-index: 40;
}
.dark .date-picker,
.date-picker {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.date-picker-panel {
  border-radius: 0.5rem;
}
.date-picker-header-label {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  cursor: pointer;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .date-picker-header-label,
.date-picker-header-label {
  --tw-text-opacity: 1;
}
.dark .date-picker-header-label {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.dark .date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.date-picker-available:hover,
.date-picker-selected-date {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.date-picker-today {
  border-width: 1px;
}
.date-picker-month-cell {
  font-weight: 600;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-month-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-month-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-year-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.picker {
  border-radius: 0.5rem;
  z-index: 40;
}
.picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  padding: 1rem;
}
.dark .picker-panel,
.picker-panel {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.picker-table,
.picker-view {
  width: 100%;
}
.picker-table {
  border-collapse: collapse;
}
.picker-header-arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  height: 2.5rem;
  line-height: 1.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  -webkit-user-select: none;
  user-select: none;
}
[dir="rtl"] .picker-header-arrow {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.picker-header-label {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .picker-header-label,
.picker-header-label {
  --tw-text-opacity: 1;
}
.dark .picker-header-label {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.month-table,
.year-table {
  grid-gap: 0.5rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.dark .date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-week-cell {
  font-weight: 600;
}
.week-day-cell {
  height: 1.75rem;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-picker-cell-disabled,
.year-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.dialog {
  margin-left: auto;
  margin-right: auto;
  outline: none;
}
@media (min-width: 640px) {
  .dialog {
    max-width: 36rem;
  }
}
@media (min-width: 768px) {
  .dialog {
    max-width: 42rem;
  }
}
@media (min-width: 1024px) {
  .dialog {
    max-width: 56rem;
  }
}
@media (min-width: 1280px) {
  .dialog {
    max-width: 72rem;
  }
}
.dialog-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  height: 100%;
  padding: 1.5rem;
  position: relative;
}
.dark .dialog-content {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
@media (min-width: 640px) {
  .dialog-content {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}
.dialog-overlay {
  --tw-bg-opacity: 0.6;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.15s ease-in-out;
  z-index: 30;
}
.dark .dialog-overlay {
  --tw-bg-opacity: 0.8;
}
.dialog-overlay-after-open {
  opacity: 1;
}
.dialog-overlay-before-close {
  opacity: 0;
}
.drawer-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  display: flex;
  flex-direction: column;
  position: absolute;
}
.dark .drawer-content {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.drawer-content.vertical {
  height: 100%;
}
.drawer-content.horizontal {
  width: 100%;
}
.drawer-header {
  align-items: center;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.dark .drawer-header,
.drawer-header {
  --tw-border-opacity: 1;
}
.dark .drawer-header {
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.drawer-body {
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem;
}
.drawer-footer {
  align-items: center;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-top-width: 1px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.dark .drawer-footer,
.drawer-footer {
  --tw-border-opacity: 1;
}
.dark .drawer-footer {
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.drawer-open.drawer-lock-scroll {
  overflow: hidden;
}
.drawer-overlay {
  --tw-bg-opacity: 0.8;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 30;
}
.drawer-overlay-after-open {
  opacity: 1;
}
.drawer-overlay-before-close {
  opacity: 0;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.05;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.375rem;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  min-width: 160px;
  padding: 0.375rem;
  position: absolute;
  z-index: 30;
}
.dropdown-menu:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.dark .dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dropdown-menu.top-start {
  bottom: 100%;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.top-start {
  right: 0;
}
.dropdown-menu.top-center {
  left: 50%;
}
.dropdown-menu.top-center,
.dropdown-menu.top-end {
  bottom: 100%;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.top-end {
  left: 0;
}
.dropdown-menu.bottom-start {
  top: 100%;
  -webkit-transform-origin: top;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.bottom-start {
  right: 0;
}
.dropdown-menu.bottom-center {
  left: 50%;
}
.dropdown-menu.bottom-center,
.dropdown-menu.bottom-end {
  top: 100%;
  -webkit-transform-origin: top;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.bottom-end {
  left: 0;
}
.dropdown-menu.middle-start-top {
  left: 100%;
  top: 0;
  -webkit-transform-origin: top;
  transform-origin: top;
}
.dropdown-menu.middle-start-bottom {
  bottom: 0;
  left: 100%;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.dropdown-menu.middle-end-top {
  right: 100%;
  top: 0;
  -webkit-transform-origin: top;
  transform-origin: top;
}
.dropdown-menu.middle-end-bottom {
  bottom: 0;
  right: 100%;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.dropdown-toggle-default {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem;
}
.dropdown-toggle-default.dropdown-toggle-disabled {
  opacity: 0.6;
}
.dropdown-submenu {
  left: 100%;
  top: 0;
  -webkit-transform-origin: top;
  transform-origin: top;
}
.dropdown-submenu-item {
  justify-content: space-between;
}
.dropdown-toggle-disabled {
  cursor: not-allowed;
}
.form-item {
  margin-bottom: 1.75rem;
  position: relative;
}
.form-item.horizontal {
  display: flex;
  flex: 1 1 auto;
}
.form-item.horizontal .form-label {
  justify-content: flex-end;
}
.form-item.vertical {
  display: flex;
  flex-direction: column;
}
.form-item.inline {
  margin-right: 0.75rem;
}
[dir="rtl"] .form-item.inline {
  margin-left: 0.75rem;
}
@media (min-width: 768px) {
  .form-item.inline {
    display: inline-flex;
  }
}
.form-label {
  align-items: center;
  display: flex;
  font-weight: 600;
}
.form-explain,
.form-label.invalid {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.form-explain {
  position: absolute;
}
.form-container.inline {
  align-items: center;
}
@media (min-width: 768px) {
  .form-container.inline {
    display: flex;
  }
}
.input-group {
  align-items: center;
  display: flex;
}
.input-group .input {
  z-index: 10;
}
.input-group .input:focus,
.input-group .input[focus-within] {
  z-index: 20;
}
.input-group .input:focus,
.input-group .input:focus-within {
  z-index: 20;
}
.input-group .date-picker {
  z-index: 11;
}
.input-group .date-picker.picker-expanded,
.input-group .input-suffix-end,
.input-group .input-suffix-start,
.input-group .select__control--is-focused {
  z-index: 20;
}
[dir="ltr"] .input-group > .button:first-child,
[dir="ltr"] .input-group > .input-addon:first-child,
[dir="ltr"] .input-group > .input:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > .button:last-child:not(.picker),
[dir="ltr"] .input-group > .input-addon:last-child:not(.picker),
[dir="ltr"] .input-group > .input:last-child:not(.picker),
[dir="rtl"] .input-group > .button:first-child,
[dir="rtl"] .input-group > .input-addon:first-child,
[dir="rtl"] .input-group > .input:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .input-group > .button:last-child:not(.picker),
[dir="rtl"] .input-group > .input-addon:last-child:not(.picker),
[dir="rtl"] .input-group > .input:last-child:not(.picker) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > .button:not(:first-child),
[dir="ltr"] .input-group > .input-addon:not(:first-child),
[dir="ltr"] .input-group > .input:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .input-group > .button:not(:first-child),
[dir="rtl"] .input-group > .input-addon:not(:first-child),
[dir="rtl"] .input-group > .input:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
[dir="ltr"] .input-group > .button:not(:last-child),
[dir="ltr"] .input-group > .input-addon:not(:last-child),
[dir="ltr"] .input-group > .input:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .input-group > .button:not(:last-child),
[dir="rtl"] .input-group > .input-addon:not(:last-child),
[dir="rtl"] .input-group > .input:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="ltr"] .input-group > :first-child .input,
[dir="ltr"] .input-group > :first-child .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > :last-child .input,
[dir="ltr"] .input-group > :last-child .select__control,
[dir="rtl"] .input-group > :first-child .input,
[dir="rtl"] .input-group > :first-child .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .input-group > :last-child .input,
[dir="rtl"] .input-group > :last-child .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > :not(:first-child) .input,
[dir="ltr"] .input-group > :not(:first-child) .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .input-group > :not(:first-child) .input,
[dir="rtl"] .input-group > :not(:first-child) .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
[dir="ltr"] .input-group > :not(:last-child) .input,
[dir="ltr"] .input-group > :not(:last-child) .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .input-group > :not(:last-child) .input,
[dir="rtl"] .input-group > :not(:last-child) .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input {
  --tw-border-opacity: 1;
  -webkit-appearance: none;
  appearance: none;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  padding: 0.5rem 0.75rem;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.input[focus-within] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.input:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.dark .input {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  background-color: initial;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.input::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.input.input-invalid {
  --tw-border-opacity: 1;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.input.input-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.input-addon {
  align-items: center;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dark .input-addon,
.input-addon {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.dark .input-addon {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.input-textarea {
  min-height: 7rem;
}
.input-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}
.input-disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .input-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.input-disabled::-webkit-input-placeholder {
  opacity: 0.7;
}
.input-disabled::placeholder {
  opacity: 0.7;
}
.input-suffix-start {
  --tw-translate-y: -50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .input-suffix-start {
  left: 0.625rem;
}
[dir="rtl"] .input-suffix-start {
  right: 0.625rem;
}
.input-suffix-end {
  --tw-translate-y: -50%;
  display: flex;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .input-suffix-end {
  right: 0.625rem;
}
[dir="rtl"] .input-suffix-end {
  left: 0.625rem;
}
.menu-item {
  align-items: center;
  border-radius: 0.375rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  white-space: nowrap;
  width: 100%;
}
.menu-item.menu-item-light {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.menu-item.menu-item-light.menu-item-hoverable:hover {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.menu-item.menu-item-light.menu-item-active,
.menu-item.menu-item-light.menu-item-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.menu-item.menu-item-light.menu-item-active,
.menu-item.menu-item-light.menu-item-active:hover {
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.menu-item.menu-item-light.menu-item-active:hover {
  --tw-bg-opacity: 1;
}
.dark .menu-item.menu-item-dark,
.menu-item.menu-item-dark {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.menu-item.menu-item-dark.menu-item-hoverable:hover {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.menu-item.menu-item-dark.menu-item-active,
.menu-item.menu-item-dark.menu-item-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-item.menu-item-dark.menu-item-active,
.menu-item.menu-item-dark.menu-item-active:hover {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.menu-item.menu-item-dark.menu-item-active:hover {
  --tw-bg-opacity: 1;
}
.menu-item.menu-item-themed {
  --tw-text-opacity: 0.8;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-item.menu-item-themed.menu-item-hoverable:hover {
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.menu-item.menu-item-themed.menu-item-active {
  --tw-bg-opacity: 0.3;
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-item.menu-item-themed.menu-item-active:hover {
  --tw-bg-opacity: 0.3;
}
.menu-item.menu-item-transparent {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.menu-item.menu-item-transparent.menu-item-hoverable:hover {
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, 0.1);
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent.menu-item-hoverable:hover {
  --tw-text-opacity: 1;
  background-color: hsla(0, 0%, 100%, 0.1);
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-item.menu-item-transparent.menu-item-active {
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, 0.075);
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent.menu-item-active {
  --tw-text-opacity: 1;
  background-color: hsla(0, 0%, 100%, 0.1);
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-collapse-item {
  align-items: center;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 2.5rem;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  -webkit-user-select: none;
  user-select: none;
}
.menu-collapse-item.menu-collapse-item-light {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-light:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-dark {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-dark:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-dark {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-dark:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-themed {
  --tw-text-opacity: 0.8;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-themed:hover {
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.menu-collapse-item.menu-collapse-item-transparent {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-transparent:hover {
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, 0.1);
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-transparent {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-transparent:hover {
  --tw-text-opacity: 1;
  background-color: hsla(0, 0%, 100%, 0.1);
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-item-divider {
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.dark .menu-item-divider,
.menu-item-divider {
  --tw-border-opacity: 1;
}
.dark .menu-item-divider {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.menu-item-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.menu-item-link {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.menu-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-transform: uppercase;
}
.menu-title.menu-title-light {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.menu-title.menu-title-dark {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.menu-title.menu-title-themed {
  --tw-text-opacity: 0.5;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.menu-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.menu-dark,
.menu-light {
  --tw-bg-opacity: 1;
}
.menu-dark {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.menu-transparent {
  background-color: initial;
}
.notification {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  margin-bottom: 0.75rem;
  position: relative;
}
.dark .notification,
.notification {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .notification {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: transparent;
}
.notification-content {
  display: flex;
  padding: 1rem;
}
.notification-content.no-child {
  align-items: center;
}
.notification-title {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  font-weight: 600;
}
.dark .notification-title {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.notification-close {
  top: 1.25rem;
}
[dir="ltr"] .notification-close {
  right: 1rem;
}
[dir="rtl"] .notification-close {
  left: 1rem;
}
.pagination {
  border-radius: 0.375rem;
  z-index: 0;
}
.pagination,
.pagination-pager {
  align-items: center;
  display: inline-flex;
  position: relative;
}
.pagination-pager {
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  -webkit-user-select: none;
  user-select: none;
  width: 2rem;
}
.pagination-pager-inactive {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.pagination-pager-inactive:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .pagination-pager-inactive {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .pagination-pager-inactive:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.pagination-pager-disabled {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .pagination-pager-disabled {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.pagination-total {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-weight: 600;
  margin-right: 0.75rem;
}
.dark .pagination-total,
.pagination-total {
  --tw-text-opacity: 1;
}
.dark .pagination-total {
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.pagination-pager-next {
  margin-right: 0.25rem;
}
[dir="rtl"] .pagination-pager-next {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.pagination-pager-prev {
  margin-left: 0.25rem;
}
[dir="rtl"] .pagination-pager-prev {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.progress {
  display: inline-block;
}
.progress.circle,
.progress.line {
  position: relative;
  width: 100%;
}
.progress.line {
  align-items: center;
  display: flex;
}
.progress-bg {
  border-radius: 9999px;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
[dir="ltr"] .progress-info.line {
  margin-left: 0.5rem;
}
[dir="rtl"] .progress-info.line {
  margin-right: 0.5rem;
}
.progress-inner {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.dark .progress-inner,
.progress-inner {
  --tw-bg-opacity: 1;
}
.dark .progress-inner {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.progress-wrapper {
  width: 100%;
}
.progress-circle {
  position: relative;
}
.progress-circle-info {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.progress-circle-trail {
  stroke: currentColor;
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.dark .progress-circle-trail {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.progress-circle-stroke {
  stroke: currentColor;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.radio {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  -webkit-appearance: none;
  appearance: none;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 1.25rem;
  padding: 0;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 1.25rem;
}
.dark .radio {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  border-color: transparent;
}
.radio.disabled {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(229, 231, 235, var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .radio.disabled,
.radio.disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .radio.disabled {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.radio-label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}
.radio-label.disabled {
  cursor: not-allowed;
}
.radio-group {
  display: inline-flex;
}
.radio-group.vertical {
  flex-direction: column;
}
.segment {
  display: flex;
}
[dir="ltr"] .segment > .segment-item-default:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .segment > .segment-item-default:last-child:not(.picker),
[dir="rtl"] .segment > .segment-item-default:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .segment > .segment-item-default:last-child:not(.picker) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .segment > .segment-item-default:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .segment > .segment-item-default:not(:first-child) {
  margin-right: -1px;
}
[dir="ltr"] .segment > .segment-item-default:not(:last-child),
[dir="rtl"] .segment > .segment-item-default:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .segment > .segment-item-default:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.segment-item-default {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  cursor: pointer;
  font-weight: 600;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.segment-item-default:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.dark .segment-item-default {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
@media (min-width: 768px) {
  .segment-item-default {
    width: auto;
  }
}
.segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark
  .segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.dark
  .segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.segment-item-default.segment-item-active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .segment-item-default.segment-item-active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.segment-item-default.segment-item-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.select .select__control--is-disabled .select__placeholder {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.select .select__control--is-disabled .select__multi-value {
  opacity: 0.6;
}
.select .select__placeholder {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dark .select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.select .select__multi-value .select__multi-value__label {
  color: inherit;
}
.select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  background-color: initial;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.dark .select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.select.select-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.select.select-lg .select-dropdown-indicator {
  font-size: 1.5rem;
  line-height: 2rem;
}
.select .select__single-value {
  color: inherit;
}
.select .select__single-value--is-disabled {
  opacity: 0.7;
}
.dark .select .select__input input {
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}
.select .select__control {
  height: auto;
}
.dark .select .select__control {
  background-color: initial;
}
.select .select__control.hide-single-value .select__single-value {
  display: none !important;
}
.select__menu {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .select__menu,
.select__menu {
  --tw-bg-opacity: 1;
}
.dark .select__menu {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.select-option {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
}
.select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.select-option.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.select-option.disabled:hover {
  background-color: initial;
}
.dark .select-option.disabled {
  opacity: 0.3;
}
.select-dropdown-indicator {
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-dropdown-indicator:hover {
  opacity: 0.7;
}
.select-clear-indicator {
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-clear-indicator:hover {
  opacity: 0.7;
}
.skeleton {
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  display: flex;
}
.dark .skeleton,
.skeleton {
  --tw-bg-opacity: 1;
}
.dark .skeleton {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.skeleton-circle {
  border-radius: 9999px;
  height: 2.5rem;
  width: 2.5rem;
}
.skeleton-block {
  border-radius: 0.25rem;
  height: 0.625rem;
  width: 100%;
}
.steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.steps.steps-vertical {
  align-items: flex-start;
  flex-direction: column;
}
.step-item {
  align-items: center;
  display: flex;
}
.step-item.step-item-vertical {
  align-items: flex-start;
  flex-direction: column;
}
.step-item-icon,
.step-item-wrapper {
  align-items: center;
  display: flex;
}
.step-item-icon {
  border-radius: 9999px;
  font-size: 1.125rem;
  font-weight: 600;
  height: 2.25rem;
  justify-content: center;
  line-height: 1.75rem;
  min-width: 2.25rem;
  width: 2.25rem;
}
.step-item-icon.step-item-icon-pending {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 2px;
}
.dark .step-item-icon.step-item-icon-pending {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.step-item-icon.step-item-icon-current {
  border-width: 2px;
}
.step-item-content {
  position: relative;
}
[dir="ltr"] .step-item-content {
  margin-left: 0.75rem;
}
[dir="rtl"] .step-item-content {
  margin-right: 0.75rem;
}
.step-item-title {
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  display: block;
  font-weight: 700;
  white-space: nowrap;
}
.dark .step-item-title,
.step-item-title {
  --tw-text-opacity: 1;
}
.dark .step-item-title {
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.step-item-title.step-item-title-error {
  --tw-text-opacity: 1;
}
.step-item-icon-error,
.step-item-title.step-item-title-error {
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.step-item-icon-error {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  border-width: 2px;
}
.step-clickable {
  cursor: pointer;
}
.step-connect {
  height: 2px;
  margin-left: 0.625rem;
  width: 100%;
}
[dir="rtl"] .step-connect {
  margin-right: 0.625rem;
}
.step-connect.inactive {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.dark .step-connect.inactive {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.step-connect.step-connect-vertical {
  min-height: 3.5rem;
  width: 2px;
}
[dir="ltr"] .step-connect.step-connect-vertical {
  margin-left: 1rem;
}
[dir="rtl"] .step-connect.step-connect-vertical {
  margin-right: 1rem;
}
.switcher {
  align-items: center;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  height: 1.5rem;
  min-width: 2.75rem;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dark .switcher,
.switcher {
  --tw-bg-opacity: 1;
}
.dark .switcher {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.switcher input {
  display: none;
}
.switcher.switcher-checked .switcher-toggle,
.switcher.switcher-checked .switcher-toggle-loading {
  left: calc(100% - 1.375rem);
}
.switcher.switcher-checked .switcher-content {
  margin: 0 1.65rem 0 0.45rem;
}
.switcher.switcher-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.switcher-toggle {
  height: 1.25rem;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
}
.switcher-toggle:before {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.switcher-content {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  margin: 0 0.45rem 0 1.65rem;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.switcher-toggle-loading {
  height: 1.25rem;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
}
.dark .switcher-uncheck-loading,
.switcher-checked-loading {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.table-default {
  min-width: 100%;
  table-layout: auto;
}
.table-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.table-default thead > tr > th {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
}
.dark .table-default thead > tr > th,
.table-default thead > tr > th {
  --tw-text-opacity: 1;
}
.dark .table-default thead > tr > th {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.table-default tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-default tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.table-default tbody > tr > td {
  padding: 1rem 1.5rem;
}
.table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}
.dark .table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.table-default.borderless-row tbody > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}
.table-flex {
  min-width: 100%;
}
.table-flex > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-flex > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.table-flex .thead .tr .th {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
}
.dark .table-flex .thead .tr .th,
.table-flex .thead .tr .th {
  --tw-text-opacity: 1;
}
.dark .table-flex .thead .tr .th {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.table-flex .tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-flex .tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.table-flex .tbody .tr .td {
  padding: 1rem 1.5rem;
}
.table-flex .tfoot .tr .td {
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}
.dark .table-flex .tfoot .tr .td {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.table-virtual > div > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-virtual > div > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.table-compact thead > tr > th {
  font-size: 0.75rem;
  line-height: 1rem;
}
.table-compact .thead > .tr > .th,
.table-compact tbody > tr > td,
.table-compact thead > tr > th {
  padding: 0.5rem 0.75rem;
}
.table-compact .thead > .tr > .th {
  font-size: 0.75rem;
  line-height: 1rem;
}
.table-compact .tbody > .tr > .td {
  padding: 0.5rem 0.75rem;
}
.table-hover > tbody > tr:hover > * {
  background-color: rgba(243, 244, 246, 0.5);
}
.dark .table-hover > tbody > tr:hover > * {
  background-color: rgba(55, 65, 81, 0.4);
}
.table-hover > .tbody > .tr:hover > * {
  background-color: rgba(243, 244, 246, 0.5);
}
.dark .table-hover > .tbody > .tr:hover > * {
  background-color: rgba(55, 65, 81, 0.4);
}
.table-resizer {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-right-width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.5rem;
  z-index: 10;
}
.dark .table-resizer,
.table-resizer {
  --tw-border-opacity: 1;
}
.dark .table-resizer {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.table-resizer {
  touch-action: none;
}
td,
th {
  word-wrap: break-word;
}
.tab-list {
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-list-underline {
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark .tab-list-underline,
.tab-list-underline {
  --tw-border-opacity: 1;
}
.dark .tab-list-underline {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.tab-nav {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tab-nav.tab-nav-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.tab-nav-underline {
  border-bottom-width: 2px;
  border-color: transparent;
  padding: 0.75rem 1.25rem;
}
.tab-nav-pill {
  border-radius: 0.375rem;
  margin-right: 0.25rem;
  padding: 0.5rem 1.25rem;
}
[dir="rtl"] .tab-nav-pill {
  margin-left: 0.25rem;
}
.tab-nav-icon {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
[dir="ltr"] .tab-nav-icon {
  margin-right: 0.375rem;
}
[dir="rtl"] .tab-nav-icon {
  margin-left: 0.375rem;
}
.tag {
  align-items: center;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.25rem 0.625rem;
  white-space: nowrap;
}
.dark .tag,
.tag {
  --tw-border-opacity: 1;
}
.dark .tag {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.tag-affix {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;
}
.tag-prefix {
  margin-right: 0.375rem;
}
.tag-suffix,
[dir="rtl"] .tag-prefix {
  margin-left: 0.375rem;
}
[dir="rtl"] .tag-suffix {
  margin-right: 0.375rem;
}
.time-input-field {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  background-color: initial;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  outline: 2px solid transparent;
  outline-offset: 2px;
  text-align: center;
  width: 1.5rem;
}
.time-input-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
}
.time-input-separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.timeline {
  align-items: flex-start;
  flex-direction: column;
}
.timeline-item {
  display: flex;
  min-height: 70px;
}
.timeline-item-last {
  min-height: -webkit-max-content;
  min-height: max-content;
}
.timeline-item-media {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.timeline-item-media-content {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 1rem;
  width: 1rem;
}
.dark .timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.timeline-item-wrapper {
  display: flex;
  flex: 1 1 auto;
}
.timeline-item-content {
  margin-left: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 0.125rem;
  width: 100%;
}
[dir="rtl"] .timeline-item-content {
  margin-right: 1rem;
}
.timeline-item-content-last {
  padding-bottom: 0;
}
.timeline-connect {
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  flex-grow: 1;
  height: 100%;
  width: 2px;
}
.dark .timeline-connect,
.timeline-connect {
  --tw-bg-opacity: 1;
}
.dark .timeline-connect {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.toast {
  position: fixed;
  z-index: 40;
}
.tooltip {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  max-width: 250px;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
}
.tooltip-wrapper {
  display: inline-flex;
}
.upload {
  display: inline-block;
  position: relative;
}
.upload.disabled {
  opacity: 0.6;
}
.upload.disabled,
.upload.disabled .upload-input {
  cursor: not-allowed;
}
.upload-input {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.upload-input.draggable {
  cursor: pointer;
  display: block;
  opacity: 0;
  width: 100%;
}
.upload-draggable {
  --tw-border-opacity: 1;
  align-items: center;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 7rem;
}
.dark .upload-draggable {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.upload-file-list {
  margin-top: 1rem;
}
.upload-file-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
}
[dir="ltr"] .upload-file-info {
  margin-left: 0.75rem;
}
[dir="rtl"] .upload-file-info {
  margin-right: 0.75rem;
}
.upload-file {
  align-items: center;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.dark .upload-file,
.upload-file {
  --tw-border-opacity: 1;
}
.dark .upload-file {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.upload-file-thumbnail {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  padding: 0.5rem;
  width: 4rem;
}
.upload-file-image {
  max-width: 100%;
}
.upload-file-remove {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.75rem;
}
.header {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}
.dark .header,
.header {
  --tw-bg-opacity: 1;
}
.dark .header {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.header-wrapper {
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}
.header-action,
.header-wrapper {
  align-items: center;
  display: flex;
}
.header-action-item {
  border-radius: 9999px;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.5rem;
}
.header-action-item-hoverable {
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.header-action-item-hoverable:hover {
  --tw-bg-opacity: 0.05;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .header-action-item-hoverable:hover,
.header-action-item-hoverable:hover {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.dark .header-action-item-hoverable:hover {
  --tw-bg-opacity: 0.4;
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.side-nav {
  flex: 1 1 auto;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 20;
}
.side-nav-expand {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.side-nav-content {
  height: calc(90vh - 4rem);
  overflow-y: auto;
}
.side-nav-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-right-width: 1px;
}
.side-nav-dark,
.side-nav-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.side-nav-dark {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  border-right-width: 1px;
}
.side-nav-transparent {
  background-color: initial;
}
.stacked-side-nav {
  display: flex;
  flex: 1 1 auto;
  flex-shrink: 0;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 20;
}
.stacked-side-nav-content {
  height: calc(100vh - 4rem);
  overflow-y: auto;
}
.stacked-side-nav-mini {
  z-index: 10;
}
.stacked-side-nav-secondary {
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.stacked-side-nav-mini-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-mini-dark,
.stacked-side-nav-mini-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.stacked-side-nav-mini-dark {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-secondary-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-secondary-dark,
.stacked-side-nav-secondary-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.stacked-side-nav-secondary-dark {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  border-right-width: 1px;
}
.secondary-header-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.secondary-header-dark,
.secondary-header-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.secondary-header-dark {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  border-bottom-width: 1px;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.apexcharts-tooltip {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow) !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  border-width: 1px !important;
}
.apexcharts-tooltip.apexcharts-theme-light,
.dark .apexcharts-tooltip.apexcharts-theme-light {
  --tw-border-opacity: 1 !important;
  --tw-bg-opacity: 1 !important;
}
.dark .apexcharts-tooltip.apexcharts-theme-light {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: initial !important;
  border-bottom-width: 0 !important;
}
.apexcharts-canvas {
  margin-left: auto;
  margin-right: auto;
}
.apexcharts-datalabel-label {
  fill: #111827 !important;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
.dark .apexcharts-datalabel-label {
  fill: #f3f4f6 !important;
}
.apexcharts-datalabel-value {
  fill: #6b7280 !important;
  font-size: 1rem;
  line-height: 1.5rem;
}
.dark .apexcharts-datalabel-value {
  fill: #9ca3af !important;
}
.apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}
.apexcharts-gridline {
  stroke: #e5e7eb;
}
.dark .apexcharts-gridline {
  stroke: #4b5563;
}
.apexcharts-text tspan {
  fill: #6b7280 !important;
}
.dark .apexcharts-text tspan {
  fill: #9ca3af !important;
}
.apexcharts-menu-item {
  text-align: left;
}
.apexcharts-xaxistooltip {
  opacity: 0 !important;
}
.apexcharts-legend-text {
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
}
.apexcharts-legend-text,
.dark .apexcharts-legend-text {
  --tw-text-opacity: 1 !important;
}
.dark .apexcharts-legend-text {
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}
.rich-text-editor .ql-toolbar.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-width: 1px;
}
.dark .rich-text-editor .ql-toolbar.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.rich-text-editor .ql-container.ql-snow {
  --tw-border-opacity: 1;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
}
.dark .rich-text-editor .ql-container.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.rich-text-editor .ql-editor {
  min-height: 120px;
  padding: 1.5rem;
}
.rich-text-editor .ql-snow .ql-stroke {
  stroke: #4b5563;
}
.dark .rich-text-editor .ql-snow .ql-stroke {
  stroke: #f3f4f6;
}
.rich-text-editor .ql-snow .ql-picker {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .rich-text-editor .ql-snow .ql-picker {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar button,
.rich-text-editor .ql-snow.ql-toolbar button {
  border-radius: 0.25rem;
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #111827;
}
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item:hover
  .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label:hover
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item:hover
  .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label:hover
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #f3f4f6;
}
.rich-text-editor .ql-snow .ql-picker-options .ql-picker-item {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rich-text-editor
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.dark
  .rich-text-editor
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.rich-text-editor .ql-snow .ql-picker-options {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
}
.dark .rich-text-editor .ql-snow .ql-picker-options {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar,
.calendar .fc {
  height: 100%;
}
.calendar .fc-theme-standard .fc-scrollgrid {
  border-width: 0;
}
.calendar .fc-theme-standard td,
.calendar .fc-theme-standard th {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark .calendar .fc-theme-standard td,
.dark .calendar .fc-theme-standard th {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.calendar .fc-theme-standard td:last-child,
.calendar .fc-theme-standard th:last-child {
  border-right: 0;
}
.calendar .fc .fc-col-header-cell-cushion {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.dark .calendar .fc .fc-col-header-cell-cushion {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc-col-header-cell {
  border-width: 0 0 1px;
}
.calendar .fc .fc-daygrid-day-number {
  font-weight: 600;
  padding: 0.5rem;
}
.calendar .fc-view {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
}
.calendar .fc-view,
.dark .calendar .fc-view {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.dark .calendar .fc-view {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.calendar .fc-daygrid-dot-event .fc-event-title {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .calendar .fc-daygrid-dot-event .fc-event-title {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-button {
  border-radius: 0.375rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.calendar .fc .fc-toolbar {
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 1024px) {
  .calendar .fc .fc-toolbar {
    align-items: center;
    flex-direction: row;
  }
}
.calendar .fc .fc-button-primary {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  font-weight: 600;
}
.calendar .fc .fc-button-primary,
.dark .calendar .fc .fc-button-primary {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:focus {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.calendar .fc .fc-button-primary:hover:hover {
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:hover:hover,
.dark .calendar .fc .fc-button-primary:hover:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:hover:hover {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  cursor: not-allowed;
}
.calendar .fc .fc-button-primary:disabled,
.dark .calendar .fc .fc-button-primary:disabled {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:disabled {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled:hover {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled:hover,
.dark .calendar .fc .fc-button-primary:disabled:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:disabled:hover {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.calendar .fc .fc-button-primary:not(:disabled):active:focus {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active,
.calendar .fc .fc-button-primary:not(:disabled):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .calendar .fc .fc-button-primary:not(:disabled).fc-button-active,
.dark .calendar .fc .fc-button-primary:not(:disabled):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.calendar .fc .fc-dayGridMonth-button,
.calendar .fc .fc-timeGridDay-button,
.calendar .fc .fc-timeGridWeek-button,
.calendar .fc .fc-today-button {
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: capitalize;
}
.calendar .fc .fc-highlight,
.calendar .fc-daygrid-dot-event.fc-event-mirror,
.calendar .fc-daygrid-dot-event:hover {
  background-color: initial;
}
.calendar .fc .fc-daygrid-event {
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  padding: 0;
}
.calendar .fc-h-event {
  background-color: initial;
  border-width: 0;
}
.calendar .fc-event-selected,
.calendar .fc-event:focus {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.calendar .fc-event-selected:after,
.calendar .fc-event:focus:after {
  background-color: initial;
}
.calendar .fc .fc-cell-shaded,
.calendar .fc .fc-day-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.dark .calendar .fc .fc-cell-shaded,
.dark .calendar .fc .fc-day-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.calendar .fc-daygrid-bg-harness .fc-highlight {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .calendar .fc-daygrid-bg-harness .fc-highlight {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.calendar .fc-daygrid-event {
  font-size: 0.75rem;
  line-height: 1rem;
}
.calendar .fc .custom-calendar-event {
  align-items: center;
  border-radius: 0.375rem;
  display: flex;
  min-height: 28px;
  overflow: hidden;
  padding: 0.375rem;
  text-overflow: ellipsis;
  width: 100%;
}
.calendar .fc-v-event {
  background-color: initial;
  border-width: 0;
}
.calendar .fc .fc-daygrid-body,
.calendar .fc .fc-scrollgrid-section table {
  width: 100% !important;
}
.calendar .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0;
  margin-right: 0;
}
.calendar .fc-toolbar-chunk {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
@media (min-width: 640px) {
  .calendar .fc-toolbar-chunk {
    flex-direction: row;
    width: auto;
  }
}
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
:after,
:before {
  --tw-content: "";
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue,
    Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
}
body {
  line-height: inherit;
  margin: 0;
}
hr {
  border-top-width: 1px;
  color: inherit;
  height: 0;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: initial;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
}
fieldset,
legend {
  padding: 0;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}
[role="button"],
button {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img,
video {
  height: auto;
  max-width: 100%;
}
body {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.5;
}
.dark body,
body {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark body {
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
body {
  -webkit-font-smoothing: antialiased;
}
.h1,
h1 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}
.dark .h1,
.dark h1,
.h1,
h1 {
  --tw-text-opacity: 1;
}
.dark .h1,
.dark h1 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.h2,
h2 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.dark .h2,
.dark h2,
.h2,
h2 {
  --tw-text-opacity: 1;
}
.dark .h2,
.dark h2 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.h3,
h3 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}
.dark .h3,
.dark h3,
.h3,
h3 {
  --tw-text-opacity: 1;
}
.dark .h3,
.dark h3 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.h4,
h4 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.dark .h4,
.dark h4,
.h4,
h4 {
  --tw-text-opacity: 1;
}
.dark .h4,
.dark h4 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.h5,
h5 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.dark .h5,
.dark h5,
.h5,
h5 {
  --tw-text-opacity: 1;
}
.dark .h5,
.dark h5 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.h6,
h6 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dark .h6,
.dark h6,
.h6,
h6 {
  --tw-text-opacity: 1;
}
.dark .h6,
.dark h6 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
hr {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark hr,
hr {
  --tw-border-opacity: 1;
}
.dark hr {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.heading-text {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .heading-text,
.heading-text {
  --tw-text-opacity: 1;
}
.dark .heading-text {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  width: 100%;
}
@media (min-width: 576) {
  .container {
    max-width: 576;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.prose {
  color: #6b7280;
  max-width: 65ch;
}
.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-bottom: 1.2em;
  margin-top: 1.2em;
}
.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}
.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}
.prose :where(a strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(blockquote strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
  padding-left: 1.625em;
}
.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.prose :where(ol[type="A s"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a s"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.prose :where(ol[type="I s"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.prose :where(ol[type="i s"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}
.prose :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
  padding-left: 1.625em;
}
.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}
.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}
.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-bottom: 3em;
  margin-top: 3em;
}
.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  border-left-color: var(--tw-prose-quote-borders);
  border-left-width: 0.25rem;
  color: var(--tw-prose-quotes);
  font-style: italic;
  font-weight: 500;
  margin-bottom: 1.6em;
  margin-top: 1.6em;
  padding-left: 1em;
  quotes: "\201C""\201D""\2018""\2019";
}
.prose
  :where(blockquote p:first-of-type):not(
    :where([class~="not-prose"] *)
  ):before {
  content: open-quote;
}
.prose
  :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}
.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.1111111;
  margin-bottom: 0.8888889em;
  margin-top: 0;
}
.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}
.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.3333333;
  margin-bottom: 1em;
  margin-top: 2em;
}
.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}
.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 0.6em;
  margin-top: 1.6em;
}
.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}
.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}
.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}
.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-bottom: 2em;
  margin-top: 2em;
}
.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
  margin-top: 0;
}
.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: 0.875em;
  font-weight: 600;
}
.prose :where(code):not(:where([class~="not-prose"] *)):before {
  content: "`";
}
.prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}
.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}
.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}
.prose :where(h4 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(blockquote code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}
.prose :where(pre):not(:where([class~="not-prose"] *)) {
  background-color: var(--tw-prose-pre-bg);
  border-radius: 0.375rem;
  color: var(--tw-prose-pre-code);
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.7142857;
  margin-bottom: 1.7142857em;
  margin-top: 1.7142857em;
  overflow-x: auto;
  padding: 0.8571429em 1.1428571em;
}
.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: initial;
  border-radius: 0;
  border-width: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}
.prose :where(pre code):not(:where([class~="not-prose"] *)):before {
  content: none;
}
.prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}
.prose :where(table):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-bottom: 2em;
  margin-top: 2em;
  table-layout: auto;
  text-align: left;
  width: 100%;
}
.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: var(--tw-prose-th-borders);
  border-bottom-width: 1px;
}
.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
  padding-right: 0.5714286em;
  vertical-align: bottom;
}
.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-color: var(--tw-prose-td-borders);
  border-bottom-width: 1px;
}
.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}
.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: initial;
}
.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-color: var(--tw-prose-th-borders);
  border-top-width: 1px;
}
.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}
.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgba(0, 0, 0, 0.5);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
  margin-top: 1.25em;
}
.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-bottom: 2em;
  margin-top: 2em;
}
.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-bottom: 2em;
  margin-top: 2em;
}
.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.prose
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: 0.5714286em;
}
.prose
  :where(tbody td:first-child, tfoot td:first-child):not(
    :where([class~="not-prose"] *)
  ) {
  padding-left: 0;
}
.prose
  :where(tbody td:last-child, tfoot td:last-child):not(
    :where([class~="not-prose"] *)
  ) {
  padding-right: 0;
}
.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose-sm :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.5714286em;
  margin-top: 0.5714286em;
}
.prose-sm
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}
.prose-sm
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}
.prose-sm
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}
.prose-sm
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}
.prose-sm :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose-sm :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose-base :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}
.prose-base
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose-base
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose-base
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose-base
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose-base :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose-base :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose-lg :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.8888889em;
  margin-top: 0.8888889em;
}
.prose-lg
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}
.prose-lg
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}
.prose-lg
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}
.prose-lg
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}
.prose-lg :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose-lg :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose-xl :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.8em;
  margin-top: 0.8em;
}
.prose-xl
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
}
.prose-xl
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}
.prose-xl
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
}
.prose-xl
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}
.prose-xl :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose-xl :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose-2xl :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0.8333333em;
  margin-top: 0.8333333em;
}
.prose-2xl
  :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}
.prose-2xl
  :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}
.prose-2xl
  :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}
.prose-2xl
  :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}
.prose-2xl :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose-2xl :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.visible {
  visibility: visible;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.inset-0 {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.inset-2 {
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  top: 0.5rem;
}
.top-1\/2 {
  top: 50%;
}
.left-1\/2 {
  left: 50%;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.top-4 {
  top: 1rem;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.top-full {
  top: 100%;
}
.bottom-full {
  bottom: 100%;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.-left-2 {
  left: -0.5rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.-top-2 {
  top: -0.5rem;
}
.-right-2 {
  right: -0.5rem;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.bottom-1 {
  bottom: 0.25rem;
}
.z-10 {
  z-index: 10;
}
.z-50 {
  z-index: 50;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-end-3 {
  grid-column-end: 3;
}
.col-end-7 {
  grid-column-end: 7;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-start-2 {
  grid-row-start: 2;
}
.row-start-1 {
  grid-row-start: 1;
}
.row-end-3 {
  grid-row-end: 3;
}
.row-end-4 {
  grid-row-end: 4;
}
.m-4 {
  margin: 1rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-0 {
  margin: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-10 {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
.my-8 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.my-6 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.my-1 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.my-16 {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-5 {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
.my-3 {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-8 {
  margin-right: 2rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.\!hidden {
  display: none !important;
}
.h-0 {
  height: 0;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-28 {
  height: 7rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-52 {
  height: 13rem;
}
.h-56 {
  height: 14rem;
}
.h-60 {
  height: 15rem;
}
.h-64 {
  height: 16rem;
}
.h-72 {
  height: 18rem;
}
.h-80 {
  height: 20rem;
}
.h-96 {
  height: 24rem;
}
.h-36rem {
  max-height: 36rem;
}
.h-auto {
  height: auto;
}
.h-px {
  height: 1px;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-1\.5 {
  height: 0.375rem;
}
.h-2\.5 {
  height: 0.625rem;
}
.h-3\.5 {
  height: 0.875rem;
}
.h-1\/2 {
  height: 50%;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-1\/4 {
  height: 25%;
}
.h-2\/4 {
  height: 50%;
}
.h-3\/4 {
  height: 75%;
}
.h-1\/5 {
  height: 20%;
}
.h-2\/5 {
  height: 40%;
}
.h-3\/5 {
  height: 60%;
}
.h-4\/5 {
  height: 80%;
}
.h-1\/6 {
  height: 16.666667%;
}
.h-2\/6 {
  height: 33.333333%;
}
.h-3\/6 {
  height: 50%;
}
.h-4\/6 {
  height: 66.666667%;
}
.h-5\/6 {
  height: 83.333333%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-min {
  height: -webkit-min-content;
  height: min-content;
}
.h-max {
  height: -webkit-max-content;
  height: max-content;
}
.h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.h-\[100vh\] {
  height: 100vh;
}
.h-\[300px\] {
  height: 300px;
}
.h-\[200px\] {
  height: 200px;
}
.h-\[30px\] {
  height: 30px;
}
.max-h-\[550px\] {
  max-height: 550px;
}
.max-h-\[700px\] {
  max-height: 700px;
}
.max-h-\[140px\] {
  max-height: 140px;
}
.max-h-\[300px\] {
  max-height: 300px;
}
.max-h-\[400px\] {
  max-height: 400px;
}
.max-h-\[20px\] {
  max-height: 20px;
}
.max-h-96 {
  max-height: 24rem;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-\[80px\] {
  min-height: 80px;
}
.min-h-\[300px\] {
  min-height: 300px;
}
.min-h-fit {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.min-h-\[55px\] {
  min-height: 55px;
}
.min-h-\[60px\] {
  min-height: 60px;
}
.w-0 {
  width: 0;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-72 {
  width: 18rem;
}
.w-80 {
  width: 20rem;
}
.w-96 {
  width: 24rem;
}
.w-auto {
  width: auto;
}
.w-px {
  width: 1px;
}
.w-0\.5 {
  width: 0.125rem;
}
.w-1\.5 {
  width: 0.375rem;
}
.w-2\.5 {
  width: 0.625rem;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/4 {
  width: 50%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-2\/6 {
  width: 33.333333%;
}
.w-3\/6 {
  width: 50%;
}
.w-4\/6 {
  width: 66.666667%;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.w-min {
  width: -webkit-min-content;
  width: min-content;
}
.w-max {
  width: -webkit-max-content;
  width: max-content;
}
.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[280px\] {
  width: 280px;
}
.w-\[520px\] {
  width: 520px;
}
.w-\[250px\] {
  width: 250px;
}
.min-w-0 {
  min-width: 0;
}
.min-w-\[280px\] {
  min-width: 280px;
}
.min-w-\[90px\] {
  min-width: 90px;
}
.min-w-\[320px\] {
  min-width: 320px;
}
.min-w-\[120px\] {
  min-width: 120px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.min-w-\[130px\] {
  min-width: 130px;
}
.min-w-full {
  min-width: 100%;
}
.min-w-\[360px\] {
  min-width: 360px;
}
.min-w-\[30px\] {
  min-width: 30px;
}
.max-w-\[150px\] {
  max-width: 150px;
}
.max-w-\[700px\] {
  max-width: 700px;
}
.max-w-\[35px\] {
  max-width: 35px;
}
.max-w-\[800px\] {
  max-width: 800px;
}
.max-w-none {
  max-width: none;
}
.max-w-full {
  max-width: 100%;
}
.max-w-\[300px\] {
  max-width: 300px;
}
.max-w-\[900px\] {
  max-width: 900px;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-\[200px\] {
  max-width: 200px;
}
.max-w-\[1000px\] {
  max-width: 1000px;
}
.max-w-\[350px\] {
  max-width: 350px;
}
.max-w-\[360px\] {
  max-width: 360px;
}
.max-w-\[600px\] {
  max-width: 600px;
}
.max-w-\[420px\] {
  max-width: 420px;
}
.max-w-\[180px\] {
  max-width: 180px;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-\[1200px\] {
  max-width: 1200px;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-\[260px\] {
  max-width: 260px;
}
.max-w-xs {
  max-width: 20rem;
}
.flex-auto {
  flex: 1 1 auto;
}
.shrink {
  flex-shrink: 1;
}
.grow {
  flex-grow: 1;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.-translate-x-1\/2,
.-translate-y-1\/2 {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-default {
  cursor: default;
}
.select-none {
  -webkit-user-select: none;
  user-select: none;
}
.resize {
  resize: both;
}
.list-inside {
  list-style-position: inside;
}
.list-disc {
  list-style-type: disc;
}
.list-decimal {
  list-style-type: decimal;
}
.list-none {
  list-style-type: none;
}
.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
}
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.place-content-center {
  align-content: center;
  justify-content: center;
  place-content: center;
}
.items-start {
  align-items: flex-start;
}
.\!items-start {
  align-items: flex-start !important;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.\!justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-y-10 {
  row-gap: 2.5rem;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.gap-y-0\.5 {
  row-gap: 0.125rem;
}
.gap-y-0 {
  row-gap: 0;
}
.gap-y-7 {
  row-gap: 1.75rem;
}
.gap-x-4 {
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}
.gap-x-6 {
  -webkit-column-gap: 1.5rem;
  column-gap: 1.5rem;
}
.gap-y-6 {
  row-gap: 1.5rem;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
}
.self-end {
  align-self: flex-end;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.truncate {
  overflow: hidden;
  white-space: nowrap;
}
.text-ellipsis,
.truncate {
  text-overflow: ellipsis;
}
.text-clip {
  text-overflow: clip;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-none {
  border-radius: 0;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-tr-none {
  border-top-right-radius: 0;
}
.rounded-br-none {
  border-bottom-right-radius: 0;
}
.rounded-tl-none {
  border-top-left-radius: 0;
}
.rounded-bl-none {
  border-bottom-left-radius: 0;
}
.\!rounded-tl-none {
  border-top-left-radius: 0 !important;
}
.\!rounded-bl-none {
  border-bottom-left-radius: 0 !important;
}
.\!rounded-tr-none {
  border-top-right-radius: 0 !important;
}
.\!rounded-br-none {
  border-bottom-right-radius: 0 !important;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.border-0 {
  border-width: 0;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.hover\:border-2:hover {
  border-width: 2px;
}
.border-3 {
  border-width: 3px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.\!border-t-0 {
  border-top-width: 0 !important;
}
.border-dashed {
  border-style: dashed;
}
.border-inherit {
  border-color: inherit;
}
.border-current {
  border-color: currentColor;
}
.border-transparent {
  border-color: transparent;
}
.border-black {
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-black,
.border-white {
  --tw-border-opacity: 1;
}
.border-white {
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-slate-50 {
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}
.border-slate-100,
.border-slate-50 {
  --tw-border-opacity: 1;
}
.border-slate-100 {
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}
.border-slate-200 {
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}
.border-slate-200,
.border-slate-300 {
  --tw-border-opacity: 1;
}
.border-slate-300 {
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}
.border-slate-400 {
  border-color: rgba(148, 163, 184, var(--tw-border-opacity));
}
.border-slate-400,
.border-slate-500 {
  --tw-border-opacity: 1;
}
.border-slate-500 {
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}
.border-slate-600 {
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}
.border-slate-600,
.border-slate-700 {
  --tw-border-opacity: 1;
}
.border-slate-700 {
  border-color: rgba(51, 65, 85, var(--tw-border-opacity));
}
.border-slate-800 {
  border-color: rgba(30, 41, 59, var(--tw-border-opacity));
}
.border-slate-800,
.border-slate-900 {
  --tw-border-opacity: 1;
}
.border-slate-900 {
  border-color: rgba(15, 23, 42, var(--tw-border-opacity));
}
.border-gray-50 {
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.border-gray-100,
.border-gray-50 {
  --tw-border-opacity: 1;
}
.border-gray-100 {
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-200 {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-200,
.border-gray-300 {
  --tw-border-opacity: 1;
}
.border-gray-300 {
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-gray-400 {
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.border-gray-400,
.border-gray-500 {
  --tw-border-opacity: 1;
}
.border-gray-500 {
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-gray-600 {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.border-gray-600,
.border-gray-700 {
  --tw-border-opacity: 1;
}
.border-gray-700 {
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.border-gray-800 {
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.border-gray-800,
.border-gray-900 {
  --tw-border-opacity: 1;
}
.border-gray-900 {
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.border-zinc-50 {
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.border-zinc-100,
.border-zinc-50 {
  --tw-border-opacity: 1;
}
.border-zinc-100 {
  border-color: rgba(244, 244, 245, var(--tw-border-opacity));
}
.border-zinc-200 {
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}
.border-zinc-200,
.border-zinc-300 {
  --tw-border-opacity: 1;
}
.border-zinc-300 {
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}
.border-zinc-400 {
  border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}
.border-zinc-400,
.border-zinc-500 {
  --tw-border-opacity: 1;
}
.border-zinc-500 {
  border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}
.border-zinc-600 {
  border-color: rgba(82, 82, 91, var(--tw-border-opacity));
}
.border-zinc-600,
.border-zinc-700 {
  --tw-border-opacity: 1;
}
.border-zinc-700 {
  border-color: rgba(63, 63, 70, var(--tw-border-opacity));
}
.border-zinc-800 {
  border-color: rgba(39, 39, 42, var(--tw-border-opacity));
}
.border-zinc-800,
.border-zinc-900 {
  --tw-border-opacity: 1;
}
.border-zinc-900 {
  border-color: rgba(24, 24, 27, var(--tw-border-opacity));
}
.border-neutral-50 {
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.border-neutral-100,
.border-neutral-50 {
  --tw-border-opacity: 1;
}
.border-neutral-100 {
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.border-neutral-200 {
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}
.border-neutral-200,
.border-neutral-300 {
  --tw-border-opacity: 1;
}
.border-neutral-300 {
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
}
.border-neutral-400 {
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}
.border-neutral-400,
.border-neutral-500 {
  --tw-border-opacity: 1;
}
.border-neutral-500 {
  border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}
.border-neutral-600 {
  border-color: rgba(82, 82, 82, var(--tw-border-opacity));
}
.border-neutral-600,
.border-neutral-700 {
  --tw-border-opacity: 1;
}
.border-neutral-700 {
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}
.border-neutral-800 {
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
}
.border-neutral-800,
.border-neutral-900 {
  --tw-border-opacity: 1;
}
.border-neutral-900 {
  border-color: rgba(23, 23, 23, var(--tw-border-opacity));
}
.border-stone-50 {
  border-color: rgba(250, 250, 249, var(--tw-border-opacity));
}
.border-stone-100,
.border-stone-50 {
  --tw-border-opacity: 1;
}
.border-stone-100 {
  border-color: rgba(245, 245, 244, var(--tw-border-opacity));
}
.border-stone-200 {
  border-color: rgba(231, 229, 228, var(--tw-border-opacity));
}
.border-stone-200,
.border-stone-300 {
  --tw-border-opacity: 1;
}
.border-stone-300 {
  border-color: rgba(214, 211, 209, var(--tw-border-opacity));
}
.border-stone-400 {
  border-color: rgba(168, 162, 158, var(--tw-border-opacity));
}
.border-stone-400,
.border-stone-500 {
  --tw-border-opacity: 1;
}
.border-stone-500 {
  border-color: rgba(120, 113, 108, var(--tw-border-opacity));
}
.border-stone-600 {
  border-color: rgba(87, 83, 78, var(--tw-border-opacity));
}
.border-stone-600,
.border-stone-700 {
  --tw-border-opacity: 1;
}
.border-stone-700 {
  border-color: rgba(68, 64, 60, var(--tw-border-opacity));
}
.border-stone-800 {
  border-color: rgba(41, 37, 36, var(--tw-border-opacity));
}
.border-stone-800,
.border-stone-900 {
  --tw-border-opacity: 1;
}
.border-stone-900 {
  border-color: rgba(28, 25, 23, var(--tw-border-opacity));
}
.border-red-50 {
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.border-red-100,
.border-red-50 {
  --tw-border-opacity: 1;
}
.border-red-100 {
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.border-red-200 {
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.border-red-200,
.border-red-300 {
  --tw-border-opacity: 1;
}
.border-red-300 {
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.border-red-400 {
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.border-red-400,
.border-red-500 {
  --tw-border-opacity: 1;
}
.border-red-500 {
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-red-600 {
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.border-red-600,
.border-red-700 {
  --tw-border-opacity: 1;
}
.border-red-700 {
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.border-red-800 {
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.border-red-800,
.border-red-900 {
  --tw-border-opacity: 1;
}
.border-red-900 {
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.border-orange-50 {
  border-color: rgba(255, 247, 237, var(--tw-border-opacity));
}
.border-orange-100,
.border-orange-50 {
  --tw-border-opacity: 1;
}
.border-orange-100 {
  border-color: rgba(255, 237, 213, var(--tw-border-opacity));
}
.border-orange-200 {
  border-color: rgba(254, 215, 170, var(--tw-border-opacity));
}
.border-orange-200,
.border-orange-300 {
  --tw-border-opacity: 1;
}
.border-orange-300 {
  border-color: rgba(253, 186, 116, var(--tw-border-opacity));
}
.border-orange-400 {
  border-color: rgba(251, 146, 60, var(--tw-border-opacity));
}
.border-orange-400,
.border-orange-500 {
  --tw-border-opacity: 1;
}
.border-orange-500 {
  border-color: rgba(249, 115, 22, var(--tw-border-opacity));
}
.border-orange-600 {
  border-color: rgba(234, 88, 12, var(--tw-border-opacity));
}
.border-orange-600,
.border-orange-700 {
  --tw-border-opacity: 1;
}
.border-orange-700 {
  border-color: rgba(194, 65, 12, var(--tw-border-opacity));
}
.border-orange-800 {
  border-color: rgba(154, 52, 18, var(--tw-border-opacity));
}
.border-orange-800,
.border-orange-900 {
  --tw-border-opacity: 1;
}
.border-orange-900 {
  border-color: rgba(124, 45, 18, var(--tw-border-opacity));
}
.border-amber-50 {
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.border-amber-100,
.border-amber-50 {
  --tw-border-opacity: 1;
}
.border-amber-100 {
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.border-amber-200 {
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.border-amber-200,
.border-amber-300 {
  --tw-border-opacity: 1;
}
.border-amber-300 {
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.border-amber-400 {
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.border-amber-400,
.border-amber-500 {
  --tw-border-opacity: 1;
}
.border-amber-500 {
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.border-amber-600 {
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.border-amber-600,
.border-amber-700 {
  --tw-border-opacity: 1;
}
.border-amber-700 {
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.border-amber-800 {
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.border-amber-800,
.border-amber-900 {
  --tw-border-opacity: 1;
}
.border-amber-900 {
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.border-yellow-50 {
  border-color: rgba(254, 252, 232, var(--tw-border-opacity));
}
.border-yellow-100,
.border-yellow-50 {
  --tw-border-opacity: 1;
}
.border-yellow-100 {
  border-color: rgba(254, 249, 195, var(--tw-border-opacity));
}
.border-yellow-200 {
  border-color: rgba(254, 240, 138, var(--tw-border-opacity));
}
.border-yellow-200,
.border-yellow-300 {
  --tw-border-opacity: 1;
}
.border-yellow-300 {
  border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}
.border-yellow-400 {
  border-color: rgba(250, 204, 21, var(--tw-border-opacity));
}
.border-yellow-400,
.border-yellow-500 {
  --tw-border-opacity: 1;
}
.border-yellow-500 {
  border-color: rgba(234, 179, 8, var(--tw-border-opacity));
}
.border-yellow-600 {
  border-color: rgba(202, 138, 4, var(--tw-border-opacity));
}
.border-yellow-600,
.border-yellow-700 {
  --tw-border-opacity: 1;
}
.border-yellow-700 {
  border-color: rgba(161, 98, 7, var(--tw-border-opacity));
}
.border-yellow-800 {
  border-color: rgba(133, 77, 14, var(--tw-border-opacity));
}
.border-yellow-800,
.border-yellow-900 {
  --tw-border-opacity: 1;
}
.border-yellow-900 {
  border-color: rgba(113, 63, 18, var(--tw-border-opacity));
}
.border-lime-50 {
  border-color: rgba(247, 254, 231, var(--tw-border-opacity));
}
.border-lime-100,
.border-lime-50 {
  --tw-border-opacity: 1;
}
.border-lime-100 {
  border-color: rgba(236, 252, 203, var(--tw-border-opacity));
}
.border-lime-200 {
  border-color: rgba(217, 249, 157, var(--tw-border-opacity));
}
.border-lime-200,
.border-lime-300 {
  --tw-border-opacity: 1;
}
.border-lime-300 {
  border-color: rgba(190, 242, 100, var(--tw-border-opacity));
}
.border-lime-400 {
  border-color: rgba(163, 230, 53, var(--tw-border-opacity));
}
.border-lime-400,
.border-lime-500 {
  --tw-border-opacity: 1;
}
.border-lime-500 {
  border-color: rgba(132, 204, 22, var(--tw-border-opacity));
}
.border-lime-600 {
  border-color: rgba(101, 163, 13, var(--tw-border-opacity));
}
.border-lime-600,
.border-lime-700 {
  --tw-border-opacity: 1;
}
.border-lime-700 {
  border-color: rgba(77, 124, 15, var(--tw-border-opacity));
}
.border-lime-800 {
  border-color: rgba(63, 98, 18, var(--tw-border-opacity));
}
.border-lime-800,
.border-lime-900 {
  --tw-border-opacity: 1;
}
.border-lime-900 {
  border-color: rgba(54, 83, 20, var(--tw-border-opacity));
}
.border-green-50 {
  border-color: rgba(240, 253, 244, var(--tw-border-opacity));
}
.border-green-100,
.border-green-50 {
  --tw-border-opacity: 1;
}
.border-green-100 {
  border-color: rgba(220, 252, 231, var(--tw-border-opacity));
}
.border-green-200 {
  border-color: rgba(187, 247, 208, var(--tw-border-opacity));
}
.border-green-200,
.border-green-300 {
  --tw-border-opacity: 1;
}
.border-green-300 {
  border-color: rgba(134, 239, 172, var(--tw-border-opacity));
}
.border-green-400 {
  border-color: rgba(74, 222, 128, var(--tw-border-opacity));
}
.border-green-400,
.border-green-500 {
  --tw-border-opacity: 1;
}
.border-green-500 {
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}
.border-green-600 {
  border-color: rgba(22, 163, 74, var(--tw-border-opacity));
}
.border-green-600,
.border-green-700 {
  --tw-border-opacity: 1;
}
.border-green-700 {
  border-color: rgba(21, 128, 61, var(--tw-border-opacity));
}
.border-green-800 {
  border-color: rgba(22, 101, 52, var(--tw-border-opacity));
}
.border-green-800,
.border-green-900 {
  --tw-border-opacity: 1;
}
.border-green-900 {
  border-color: rgba(20, 83, 45, var(--tw-border-opacity));
}
.border-emerald-50 {
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.border-emerald-100,
.border-emerald-50 {
  --tw-border-opacity: 1;
}
.border-emerald-100 {
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.border-emerald-200 {
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.border-emerald-200,
.border-emerald-300 {
  --tw-border-opacity: 1;
}
.border-emerald-300 {
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.border-emerald-400 {
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.border-emerald-400,
.border-emerald-500 {
  --tw-border-opacity: 1;
}
.border-emerald-500 {
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.border-emerald-600 {
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.border-emerald-600,
.border-emerald-700 {
  --tw-border-opacity: 1;
}
.border-emerald-700 {
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.border-emerald-800 {
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.border-emerald-800,
.border-emerald-900 {
  --tw-border-opacity: 1;
}
.border-emerald-900 {
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.border-teal-50 {
  border-color: rgba(240, 253, 250, var(--tw-border-opacity));
}
.border-teal-100,
.border-teal-50 {
  --tw-border-opacity: 1;
}
.border-teal-100 {
  border-color: rgba(204, 251, 241, var(--tw-border-opacity));
}
.border-teal-200 {
  border-color: rgba(153, 246, 228, var(--tw-border-opacity));
}
.border-teal-200,
.border-teal-300 {
  --tw-border-opacity: 1;
}
.border-teal-300 {
  border-color: rgba(94, 234, 212, var(--tw-border-opacity));
}
.border-teal-400 {
  border-color: rgba(45, 212, 191, var(--tw-border-opacity));
}
.border-teal-400,
.border-teal-500 {
  --tw-border-opacity: 1;
}
.border-teal-500 {
  border-color: rgba(20, 184, 166, var(--tw-border-opacity));
}
.border-teal-600 {
  border-color: rgba(13, 148, 136, var(--tw-border-opacity));
}
.border-teal-600,
.border-teal-700 {
  --tw-border-opacity: 1;
}
.border-teal-700 {
  border-color: rgba(15, 118, 110, var(--tw-border-opacity));
}
.border-teal-800 {
  border-color: rgba(17, 94, 89, var(--tw-border-opacity));
}
.border-teal-800,
.border-teal-900 {
  --tw-border-opacity: 1;
}
.border-teal-900 {
  border-color: rgba(19, 78, 74, var(--tw-border-opacity));
}
.border-cyan-50 {
  border-color: rgba(236, 254, 255, var(--tw-border-opacity));
}
.border-cyan-100,
.border-cyan-50 {
  --tw-border-opacity: 1;
}
.border-cyan-100 {
  border-color: rgba(207, 250, 254, var(--tw-border-opacity));
}
.border-cyan-200 {
  border-color: rgba(165, 243, 252, var(--tw-border-opacity));
}
.border-cyan-200,
.border-cyan-300 {
  --tw-border-opacity: 1;
}
.border-cyan-300 {
  border-color: rgba(103, 232, 249, var(--tw-border-opacity));
}
.border-cyan-400 {
  border-color: rgba(34, 211, 238, var(--tw-border-opacity));
}
.border-cyan-400,
.border-cyan-500 {
  --tw-border-opacity: 1;
}
.border-cyan-500 {
  border-color: rgba(6, 182, 212, var(--tw-border-opacity));
}
.border-cyan-600 {
  border-color: rgba(8, 145, 178, var(--tw-border-opacity));
}
.border-cyan-600,
.border-cyan-700 {
  --tw-border-opacity: 1;
}
.border-cyan-700 {
  border-color: rgba(14, 116, 144, var(--tw-border-opacity));
}
.border-cyan-800 {
  border-color: rgba(21, 94, 117, var(--tw-border-opacity));
}
.border-cyan-800,
.border-cyan-900 {
  --tw-border-opacity: 1;
}
.border-cyan-900 {
  border-color: rgba(22, 78, 99, var(--tw-border-opacity));
}
.border-sky-50 {
  border-color: rgba(240, 249, 255, var(--tw-border-opacity));
}
.border-sky-100,
.border-sky-50 {
  --tw-border-opacity: 1;
}
.border-sky-100 {
  border-color: rgba(224, 242, 254, var(--tw-border-opacity));
}
.border-sky-200 {
  border-color: rgba(186, 230, 253, var(--tw-border-opacity));
}
.border-sky-200,
.border-sky-300 {
  --tw-border-opacity: 1;
}
.border-sky-300 {
  border-color: rgba(125, 211, 252, var(--tw-border-opacity));
}
.border-sky-400 {
  border-color: rgba(56, 189, 248, var(--tw-border-opacity));
}
.border-sky-400,
.border-sky-500 {
  --tw-border-opacity: 1;
}
.border-sky-500 {
  border-color: rgba(14, 165, 233, var(--tw-border-opacity));
}
.border-sky-600 {
  border-color: rgba(2, 132, 199, var(--tw-border-opacity));
}
.border-sky-600,
.border-sky-700 {
  --tw-border-opacity: 1;
}
.border-sky-700 {
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}
.border-sky-800 {
  border-color: rgba(7, 89, 133, var(--tw-border-opacity));
}
.border-sky-800,
.border-sky-900 {
  --tw-border-opacity: 1;
}
.border-sky-900 {
  border-color: rgba(12, 74, 110, var(--tw-border-opacity));
}
.border-blue-50 {
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.border-blue-100,
.border-blue-50 {
  --tw-border-opacity: 1;
}
.border-blue-100 {
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.border-blue-200 {
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.border-blue-200,
.border-blue-300 {
  --tw-border-opacity: 1;
}
.border-blue-300 {
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.border-blue-400 {
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.border-blue-400,
.border-blue-500 {
  --tw-border-opacity: 1;
}
.border-blue-500 {
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.border-blue-600 {
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.border-blue-600,
.border-blue-700 {
  --tw-border-opacity: 1;
}
.border-blue-700 {
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.border-blue-800 {
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.border-blue-800,
.border-blue-900 {
  --tw-border-opacity: 1;
}
.border-blue-900 {
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.border-indigo-50 {
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.border-indigo-100,
.border-indigo-50 {
  --tw-border-opacity: 1;
}
.border-indigo-100 {
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.border-indigo-200 {
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.border-indigo-200,
.border-indigo-300 {
  --tw-border-opacity: 1;
}
.border-indigo-300 {
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.border-indigo-400 {
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.border-indigo-400,
.border-indigo-500 {
  --tw-border-opacity: 1;
}
.border-indigo-500 {
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.border-indigo-600 {
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.border-indigo-600,
.border-indigo-700 {
  --tw-border-opacity: 1;
}
.border-indigo-700 {
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.border-indigo-800 {
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.border-indigo-800,
.border-indigo-900 {
  --tw-border-opacity: 1;
}
.border-indigo-900 {
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.border-violet-50 {
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.border-violet-100,
.border-violet-50 {
  --tw-border-opacity: 1;
}
.border-violet-100 {
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.border-violet-200 {
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.border-violet-200,
.border-violet-300 {
  --tw-border-opacity: 1;
}
.border-violet-300 {
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.border-violet-400 {
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.border-violet-400,
.border-violet-500 {
  --tw-border-opacity: 1;
}
.border-violet-500 {
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.border-violet-600 {
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.border-violet-600,
.border-violet-700 {
  --tw-border-opacity: 1;
}
.border-violet-700 {
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.border-violet-800 {
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.border-violet-800,
.border-violet-900 {
  --tw-border-opacity: 1;
}
.border-violet-900 {
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.border-purple-50 {
  border-color: rgba(250, 245, 255, var(--tw-border-opacity));
}
.border-purple-100,
.border-purple-50 {
  --tw-border-opacity: 1;
}
.border-purple-100 {
  border-color: rgba(243, 232, 255, var(--tw-border-opacity));
}
.border-purple-200 {
  border-color: rgba(233, 213, 255, var(--tw-border-opacity));
}
.border-purple-200,
.border-purple-300 {
  --tw-border-opacity: 1;
}
.border-purple-300 {
  border-color: rgba(216, 180, 254, var(--tw-border-opacity));
}
.border-purple-400 {
  border-color: rgba(192, 132, 252, var(--tw-border-opacity));
}
.border-purple-400,
.border-purple-500 {
  --tw-border-opacity: 1;
}
.border-purple-500 {
  border-color: rgba(168, 85, 247, var(--tw-border-opacity));
}
.border-purple-600 {
  border-color: rgba(147, 51, 234, var(--tw-border-opacity));
}
.border-purple-600,
.border-purple-700 {
  --tw-border-opacity: 1;
}
.border-purple-700 {
  border-color: rgba(126, 34, 206, var(--tw-border-opacity));
}
.border-purple-800 {
  border-color: rgba(107, 33, 168, var(--tw-border-opacity));
}
.border-purple-800,
.border-purple-900 {
  --tw-border-opacity: 1;
}
.border-purple-900 {
  border-color: rgba(88, 28, 135, var(--tw-border-opacity));
}
.border-fuchsia-50 {
  border-color: rgba(253, 244, 255, var(--tw-border-opacity));
}
.border-fuchsia-100,
.border-fuchsia-50 {
  --tw-border-opacity: 1;
}
.border-fuchsia-100 {
  border-color: rgba(250, 232, 255, var(--tw-border-opacity));
}
.border-fuchsia-200 {
  border-color: rgba(245, 208, 254, var(--tw-border-opacity));
}
.border-fuchsia-200,
.border-fuchsia-300 {
  --tw-border-opacity: 1;
}
.border-fuchsia-300 {
  border-color: rgba(240, 171, 252, var(--tw-border-opacity));
}
.border-fuchsia-400 {
  border-color: rgba(232, 121, 249, var(--tw-border-opacity));
}
.border-fuchsia-400,
.border-fuchsia-500 {
  --tw-border-opacity: 1;
}
.border-fuchsia-500 {
  border-color: rgba(217, 70, 239, var(--tw-border-opacity));
}
.border-fuchsia-600 {
  border-color: rgba(192, 38, 211, var(--tw-border-opacity));
}
.border-fuchsia-600,
.border-fuchsia-700 {
  --tw-border-opacity: 1;
}
.border-fuchsia-700 {
  border-color: rgba(162, 28, 175, var(--tw-border-opacity));
}
.border-fuchsia-800 {
  border-color: rgba(134, 25, 143, var(--tw-border-opacity));
}
.border-fuchsia-800,
.border-fuchsia-900 {
  --tw-border-opacity: 1;
}
.border-fuchsia-900 {
  border-color: rgba(112, 26, 117, var(--tw-border-opacity));
}
.border-pink-50 {
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.border-pink-100,
.border-pink-50 {
  --tw-border-opacity: 1;
}
.border-pink-100 {
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.border-pink-200 {
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.border-pink-200,
.border-pink-300 {
  --tw-border-opacity: 1;
}
.border-pink-300 {
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.border-pink-400 {
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.border-pink-400,
.border-pink-500 {
  --tw-border-opacity: 1;
}
.border-pink-500 {
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.border-pink-600 {
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.border-pink-600,
.border-pink-700 {
  --tw-border-opacity: 1;
}
.border-pink-700 {
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.border-pink-800 {
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.border-pink-800,
.border-pink-900 {
  --tw-border-opacity: 1;
}
.border-pink-900 {
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.border-rose-50 {
  border-color: rgba(255, 241, 242, var(--tw-border-opacity));
}
.border-rose-100,
.border-rose-50 {
  --tw-border-opacity: 1;
}
.border-rose-100 {
  border-color: rgba(255, 228, 230, var(--tw-border-opacity));
}
.border-rose-200 {
  border-color: rgba(254, 205, 211, var(--tw-border-opacity));
}
.border-rose-200,
.border-rose-300 {
  --tw-border-opacity: 1;
}
.border-rose-300 {
  border-color: rgba(253, 164, 175, var(--tw-border-opacity));
}
.border-rose-400 {
  border-color: rgba(251, 113, 133, var(--tw-border-opacity));
}
.border-rose-400,
.border-rose-500 {
  --tw-border-opacity: 1;
}
.border-rose-500 {
  border-color: rgba(244, 63, 94, var(--tw-border-opacity));
}
.border-rose-600 {
  border-color: rgba(225, 29, 72, var(--tw-border-opacity));
}
.border-rose-600,
.border-rose-700 {
  --tw-border-opacity: 1;
}
.border-rose-700 {
  border-color: rgba(190, 18, 60, var(--tw-border-opacity));
}
.border-rose-800 {
  border-color: rgba(159, 18, 57, var(--tw-border-opacity));
}
.border-rose-800,
.border-rose-900 {
  --tw-border-opacity: 1;
}
.border-rose-900 {
  border-color: rgba(136, 19, 55, var(--tw-border-opacity));
}
.border-double {
  border-style: double;
}
.bg-inherit {
  background-color: inherit;
}
.bg-current {
  background-color: currentColor;
}
.bg-transparent {
  background-color: initial;
}
.bg-black {
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-black,
.bg-white {
  --tw-bg-opacity: 1;
}
.bg-white {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-slate-50 {
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.bg-slate-100,
.bg-slate-50 {
  --tw-bg-opacity: 1;
}
.bg-slate-100 {
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.bg-slate-200 {
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.bg-slate-200,
.bg-slate-300 {
  --tw-bg-opacity: 1;
}
.bg-slate-300 {
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.bg-slate-400 {
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.bg-slate-400,
.bg-slate-500 {
  --tw-bg-opacity: 1;
}
.bg-slate-500 {
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.bg-slate-600 {
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.bg-slate-600,
.bg-slate-700 {
  --tw-bg-opacity: 1;
}
.bg-slate-700 {
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.bg-slate-800 {
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.bg-slate-800,
.bg-slate-900 {
  --tw-bg-opacity: 1;
}
.bg-slate-900 {
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.bg-gray-50 {
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.bg-gray-100,
.bg-gray-50 {
  --tw-bg-opacity: 1;
}
.bg-gray-100 {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-200,
.bg-gray-300 {
  --tw-bg-opacity: 1;
}
.bg-gray-300 {
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.bg-gray-400 {
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.bg-gray-400,
.bg-gray-500 {
  --tw-bg-opacity: 1;
}
.bg-gray-500 {
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.bg-gray-600 {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-gray-600,
.bg-gray-700 {
  --tw-bg-opacity: 1;
}
.bg-gray-700 {
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-gray-800 {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-800,
.bg-gray-900 {
  --tw-bg-opacity: 1;
}
.bg-gray-900 {
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-zinc-50 {
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.bg-zinc-100,
.bg-zinc-50 {
  --tw-bg-opacity: 1;
}
.bg-zinc-100 {
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.bg-zinc-200 {
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.bg-zinc-200,
.bg-zinc-300 {
  --tw-bg-opacity: 1;
}
.bg-zinc-300 {
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.bg-zinc-400 {
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.bg-zinc-400,
.bg-zinc-500 {
  --tw-bg-opacity: 1;
}
.bg-zinc-500 {
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.bg-zinc-600 {
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.bg-zinc-600,
.bg-zinc-700 {
  --tw-bg-opacity: 1;
}
.bg-zinc-700 {
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.bg-zinc-800 {
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.bg-zinc-800,
.bg-zinc-900 {
  --tw-bg-opacity: 1;
}
.bg-zinc-900 {
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.bg-neutral-50 {
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.bg-neutral-100,
.bg-neutral-50 {
  --tw-bg-opacity: 1;
}
.bg-neutral-100 {
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.bg-neutral-200 {
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.bg-neutral-200,
.bg-neutral-300 {
  --tw-bg-opacity: 1;
}
.bg-neutral-300 {
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.bg-neutral-400 {
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.bg-neutral-400,
.bg-neutral-500 {
  --tw-bg-opacity: 1;
}
.bg-neutral-500 {
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.bg-neutral-600 {
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.bg-neutral-600,
.bg-neutral-700 {
  --tw-bg-opacity: 1;
}
.bg-neutral-700 {
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.bg-neutral-800 {
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.bg-neutral-800,
.bg-neutral-900 {
  --tw-bg-opacity: 1;
}
.bg-neutral-900 {
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.bg-stone-50 {
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.bg-stone-100,
.bg-stone-50 {
  --tw-bg-opacity: 1;
}
.bg-stone-100 {
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.bg-stone-200 {
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.bg-stone-200,
.bg-stone-300 {
  --tw-bg-opacity: 1;
}
.bg-stone-300 {
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.bg-stone-400 {
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.bg-stone-400,
.bg-stone-500 {
  --tw-bg-opacity: 1;
}
.bg-stone-500 {
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.bg-stone-600 {
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.bg-stone-600,
.bg-stone-700 {
  --tw-bg-opacity: 1;
}
.bg-stone-700 {
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.bg-stone-800 {
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.bg-stone-800,
.bg-stone-900 {
  --tw-bg-opacity: 1;
}
.bg-stone-900 {
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.bg-red-50 {
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.bg-red-100,
.bg-red-50 {
  --tw-bg-opacity: 1;
}
.bg-red-100 {
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.bg-red-200 {
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.bg-red-200,
.bg-red-300 {
  --tw-bg-opacity: 1;
}
.bg-red-300 {
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.bg-red-400 {
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.bg-red-400,
.bg-red-500 {
  --tw-bg-opacity: 1;
}
.bg-red-500 {
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-red-600 {
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-red-600,
.bg-red-700 {
  --tw-bg-opacity: 1;
}
.bg-red-700 {
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.bg-red-800 {
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.bg-red-800,
.bg-red-900 {
  --tw-bg-opacity: 1;
}
.bg-red-900 {
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.bg-orange-50 {
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.bg-orange-100,
.bg-orange-50 {
  --tw-bg-opacity: 1;
}
.bg-orange-100 {
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.bg-orange-200 {
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.bg-orange-200,
.bg-orange-300 {
  --tw-bg-opacity: 1;
}
.bg-orange-300 {
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.bg-orange-400 {
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.bg-orange-400,
.bg-orange-500 {
  --tw-bg-opacity: 1;
}
.bg-orange-500 {
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.bg-orange-600 {
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.bg-orange-600,
.bg-orange-700 {
  --tw-bg-opacity: 1;
}
.bg-orange-700 {
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.bg-orange-800 {
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.bg-orange-800,
.bg-orange-900 {
  --tw-bg-opacity: 1;
}
.bg-orange-900 {
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.bg-amber-50 {
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.bg-amber-100,
.bg-amber-50 {
  --tw-bg-opacity: 1;
}
.bg-amber-100 {
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.bg-amber-200 {
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.bg-amber-200,
.bg-amber-300 {
  --tw-bg-opacity: 1;
}
.bg-amber-300 {
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.bg-amber-400 {
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.bg-amber-400,
.bg-amber-500 {
  --tw-bg-opacity: 1;
}
.bg-amber-500 {
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.bg-amber-600 {
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.bg-amber-600,
.bg-amber-700 {
  --tw-bg-opacity: 1;
}
.bg-amber-700 {
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.bg-amber-800 {
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.bg-amber-800,
.bg-amber-900 {
  --tw-bg-opacity: 1;
}
.bg-amber-900 {
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.bg-yellow-50 {
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.bg-yellow-100,
.bg-yellow-50 {
  --tw-bg-opacity: 1;
}
.bg-yellow-100 {
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.bg-yellow-200 {
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.bg-yellow-200,
.bg-yellow-300 {
  --tw-bg-opacity: 1;
}
.bg-yellow-300 {
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.bg-yellow-400 {
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.bg-yellow-400,
.bg-yellow-500 {
  --tw-bg-opacity: 1;
}
.bg-yellow-500 {
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.bg-yellow-600 {
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.bg-yellow-600,
.bg-yellow-700 {
  --tw-bg-opacity: 1;
}
.bg-yellow-700 {
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.bg-yellow-800 {
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.bg-yellow-800,
.bg-yellow-900 {
  --tw-bg-opacity: 1;
}
.bg-yellow-900 {
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.bg-lime-50 {
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.bg-lime-100,
.bg-lime-50 {
  --tw-bg-opacity: 1;
}
.bg-lime-100 {
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.bg-lime-200 {
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.bg-lime-200,
.bg-lime-300 {
  --tw-bg-opacity: 1;
}
.bg-lime-300 {
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.bg-lime-400 {
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.bg-lime-400,
.bg-lime-500 {
  --tw-bg-opacity: 1;
}
.bg-lime-500 {
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.bg-lime-600 {
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.bg-lime-600,
.bg-lime-700 {
  --tw-bg-opacity: 1;
}
.bg-lime-700 {
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.bg-lime-800 {
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.bg-lime-800,
.bg-lime-900 {
  --tw-bg-opacity: 1;
}
.bg-lime-900 {
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.bg-green-50 {
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.bg-green-100,
.bg-green-50 {
  --tw-bg-opacity: 1;
}
.bg-green-100 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-200 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-200,
.bg-green-300 {
  --tw-bg-opacity: 1;
}
.bg-green-300 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-400 {
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.bg-green-400,
.bg-green-500 {
  --tw-bg-opacity: 1;
}
.bg-green-500 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-600 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-600,
.bg-green-700 {
  --tw-bg-opacity: 1;
}
.bg-green-700 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-800 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-green-800,
.bg-green-900 {
  --tw-bg-opacity: 1;
}
.bg-green-900 {
  background-color: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.bg-emerald-50 {
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.bg-emerald-100,
.bg-emerald-50 {
  --tw-bg-opacity: 1;
}
.bg-emerald-100 {
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.bg-emerald-200 {
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.bg-emerald-200,
.bg-emerald-300 {
  --tw-bg-opacity: 1;
}
.bg-emerald-300 {
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.bg-emerald-400 {
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.bg-emerald-400,
.bg-emerald-500 {
  --tw-bg-opacity: 1;
}
.bg-emerald-500 {
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.bg-emerald-600 {
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.bg-emerald-600,
.bg-emerald-700 {
  --tw-bg-opacity: 1;
}
.bg-emerald-700 {
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.bg-emerald-800 {
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.bg-emerald-800,
.bg-emerald-900 {
  --tw-bg-opacity: 1;
}
.bg-emerald-900 {
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.bg-teal-50 {
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.bg-teal-100,
.bg-teal-50 {
  --tw-bg-opacity: 1;
}
.bg-teal-100 {
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.bg-teal-200 {
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.bg-teal-200,
.bg-teal-300 {
  --tw-bg-opacity: 1;
}
.bg-teal-300 {
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.bg-teal-400 {
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.bg-teal-400,
.bg-teal-500 {
  --tw-bg-opacity: 1;
}
.bg-teal-500 {
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.bg-teal-600 {
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.bg-teal-600,
.bg-teal-700 {
  --tw-bg-opacity: 1;
}
.bg-teal-700 {
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.bg-teal-800 {
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.bg-teal-800,
.bg-teal-900 {
  --tw-bg-opacity: 1;
}
.bg-teal-900 {
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.bg-cyan-50 {
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.bg-cyan-100,
.bg-cyan-50 {
  --tw-bg-opacity: 1;
}
.bg-cyan-100 {
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.bg-cyan-200 {
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.bg-cyan-200,
.bg-cyan-300 {
  --tw-bg-opacity: 1;
}
.bg-cyan-300 {
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.bg-cyan-400 {
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.bg-cyan-400,
.bg-cyan-500 {
  --tw-bg-opacity: 1;
}
.bg-cyan-500 {
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.bg-cyan-600 {
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.bg-cyan-600,
.bg-cyan-700 {
  --tw-bg-opacity: 1;
}
.bg-cyan-700 {
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.bg-cyan-800 {
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.bg-cyan-800,
.bg-cyan-900 {
  --tw-bg-opacity: 1;
}
.bg-cyan-900 {
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.bg-sky-50 {
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.bg-sky-100,
.bg-sky-50 {
  --tw-bg-opacity: 1;
}
.bg-sky-100 {
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.bg-sky-200 {
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.bg-sky-200,
.bg-sky-300 {
  --tw-bg-opacity: 1;
}
.bg-sky-300 {
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.bg-sky-400 {
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.bg-sky-400,
.bg-sky-500 {
  --tw-bg-opacity: 1;
}
.bg-sky-500 {
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.bg-sky-600 {
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.bg-sky-600,
.bg-sky-700 {
  --tw-bg-opacity: 1;
}
.bg-sky-700 {
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.bg-sky-800 {
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.bg-sky-800,
.bg-sky-900 {
  --tw-bg-opacity: 1;
}
.bg-sky-900 {
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.bg-blue-50 {
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.bg-blue-100,
.bg-blue-50 {
  --tw-bg-opacity: 1;
}
.bg-blue-100 {
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.bg-blue-200 {
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.bg-blue-200,
.bg-blue-300 {
  --tw-bg-opacity: 1;
}
.bg-blue-300 {
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.bg-blue-400 {
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.bg-blue-400,
.bg-blue-500 {
  --tw-bg-opacity: 1;
}
.bg-blue-500 {
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.bg-blue-600 {
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.bg-blue-600,
.bg-blue-700 {
  --tw-bg-opacity: 1;
}
.bg-blue-700 {
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.bg-blue-800 {
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.bg-blue-800,
.bg-blue-900 {
  --tw-bg-opacity: 1;
}
.bg-blue-900 {
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.bg-indigo-50 {
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.bg-indigo-100,
.bg-indigo-50 {
  --tw-bg-opacity: 1;
}
.bg-indigo-100 {
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.bg-indigo-200 {
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.bg-indigo-200,
.bg-indigo-300 {
  --tw-bg-opacity: 1;
}
.bg-indigo-300 {
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.bg-indigo-400 {
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.bg-indigo-400,
.bg-indigo-500 {
  --tw-bg-opacity: 1;
}
.bg-indigo-500 {
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.bg-indigo-600 {
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.bg-indigo-600,
.bg-indigo-700 {
  --tw-bg-opacity: 1;
}
.bg-indigo-700 {
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.bg-indigo-800 {
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.bg-indigo-800,
.bg-indigo-900 {
  --tw-bg-opacity: 1;
}
.bg-indigo-900 {
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.bg-violet-50 {
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.bg-violet-100,
.bg-violet-50 {
  --tw-bg-opacity: 1;
}
.bg-violet-100 {
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.bg-violet-200 {
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.bg-violet-200,
.bg-violet-300 {
  --tw-bg-opacity: 1;
}
.bg-violet-300 {
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.bg-violet-400 {
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.bg-violet-400,
.bg-violet-500 {
  --tw-bg-opacity: 1;
}
.bg-violet-500 {
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.bg-violet-600 {
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.bg-violet-600,
.bg-violet-700 {
  --tw-bg-opacity: 1;
}
.bg-violet-700 {
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.bg-violet-800 {
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.bg-violet-800,
.bg-violet-900 {
  --tw-bg-opacity: 1;
}
.bg-violet-900 {
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.bg-purple-50 {
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.bg-purple-100,
.bg-purple-50 {
  --tw-bg-opacity: 1;
}
.bg-purple-100 {
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.bg-purple-200 {
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.bg-purple-200,
.bg-purple-300 {
  --tw-bg-opacity: 1;
}
.bg-purple-300 {
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.bg-purple-400 {
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.bg-purple-400,
.bg-purple-500 {
  --tw-bg-opacity: 1;
}
.bg-purple-500 {
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.bg-purple-600 {
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.bg-purple-600,
.bg-purple-700 {
  --tw-bg-opacity: 1;
}
.bg-purple-700 {
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.bg-purple-800 {
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.bg-purple-800,
.bg-purple-900 {
  --tw-bg-opacity: 1;
}
.bg-purple-900 {
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.bg-fuchsia-50 {
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.bg-fuchsia-100,
.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
}
.bg-fuchsia-100 {
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.bg-fuchsia-200 {
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.bg-fuchsia-200,
.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
}
.bg-fuchsia-300 {
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.bg-fuchsia-400 {
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.bg-fuchsia-400,
.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
}
.bg-fuchsia-500 {
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.bg-fuchsia-600 {
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.bg-fuchsia-600,
.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
}
.bg-fuchsia-700 {
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.bg-fuchsia-800 {
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.bg-fuchsia-800,
.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
}
.bg-fuchsia-900 {
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.bg-pink-50 {
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.bg-pink-100,
.bg-pink-50 {
  --tw-bg-opacity: 1;
}
.bg-pink-100 {
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.bg-pink-200 {
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.bg-pink-200,
.bg-pink-300 {
  --tw-bg-opacity: 1;
}
.bg-pink-300 {
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.bg-pink-400 {
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.bg-pink-400,
.bg-pink-500 {
  --tw-bg-opacity: 1;
}
.bg-pink-500 {
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.bg-pink-600 {
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.bg-pink-600,
.bg-pink-700 {
  --tw-bg-opacity: 1;
}
.bg-pink-700 {
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.bg-pink-800 {
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.bg-pink-800,
.bg-pink-900 {
  --tw-bg-opacity: 1;
}
.bg-pink-900 {
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.bg-rose-50 {
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.bg-rose-100,
.bg-rose-50 {
  --tw-bg-opacity: 1;
}
.bg-rose-100 {
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.bg-rose-200 {
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.bg-rose-200,
.bg-rose-300 {
  --tw-bg-opacity: 1;
}
.bg-rose-300 {
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.bg-rose-400 {
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.bg-rose-400,
.bg-rose-500 {
  --tw-bg-opacity: 1;
}
.bg-rose-500 {
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.bg-rose-600 {
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.bg-rose-600,
.bg-rose-700 {
  --tw-bg-opacity: 1;
}
.bg-rose-700 {
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.bg-rose-800 {
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.bg-rose-800,
.bg-rose-900 {
  --tw-bg-opacity: 1;
}
.bg-rose-900 {
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.bg-gray-900\/\[\.7\] {
  background-color: rgba(17, 24, 39, 0.7);
}
.\!bg-transparent {
  background-color: initial !important;
}
.\!bg-indigo-600 {
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}
.\!bg-blue-500,
.\!bg-indigo-600 {
  --tw-bg-opacity: 1 !important;
}
.\!bg-blue-500 {
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}
.\!bg-emerald-500 {
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}
.\!bg-emerald-500,
.\!bg-red-500 {
  --tw-bg-opacity: 1 !important;
}
.\!bg-red-500 {
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}
.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}
.bg-cover {
  background-size: cover;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.p-4 {
  padding: 1rem;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.p-3\.5 {
  padding: 0.875rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-0\.5 {
  padding: 0.125rem;
}
.\!p-3 {
  padding: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}
.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.\!py-5 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.text-center {
  text-align: center;
}
.\!text-center {
  text-align: center !important;
}
.text-right {
  text-align: right;
}
.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-lg,
.text-xl {
  line-height: 1.75rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-\[70px\] {
  font-size: 70px;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.not-italic {
  font-style: normal;
}
.leading-none {
  line-height: 1;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.text-inherit {
  color: inherit;
}
.text-current {
  color: currentColor;
}
.text-transparent {
  color: transparent;
}
.text-black {
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-black,
.text-white {
  --tw-text-opacity: 1;
}
.text-white {
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-slate-50 {
  color: rgba(248, 250, 252, var(--tw-text-opacity));
}
.text-slate-100,
.text-slate-50 {
  --tw-text-opacity: 1;
}
.text-slate-100 {
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}
.text-slate-200 {
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}
.text-slate-200,
.text-slate-300 {
  --tw-text-opacity: 1;
}
.text-slate-300 {
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}
.text-slate-400 {
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.text-slate-400,
.text-slate-500 {
  --tw-text-opacity: 1;
}
.text-slate-500 {
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.text-slate-600 {
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}
.text-slate-600,
.text-slate-700 {
  --tw-text-opacity: 1;
}
.text-slate-700 {
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}
.text-slate-800 {
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}
.text-slate-800,
.text-slate-900 {
  --tw-text-opacity: 1;
}
.text-slate-900 {
  color: rgba(15, 23, 42, var(--tw-text-opacity));
}
.text-gray-50 {
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.text-gray-100,
.text-gray-50 {
  --tw-text-opacity: 1;
}
.text-gray-100 {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.text-gray-200 {
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-200,
.text-gray-300 {
  --tw-text-opacity: 1;
}
.text-gray-300 {
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-gray-400 {
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-400,
.text-gray-500 {
  --tw-text-opacity: 1;
}
.text-gray-500 {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-600 {
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-600,
.text-gray-700 {
  --tw-text-opacity: 1;
}
.text-gray-700 {
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-800,
.text-gray-900 {
  --tw-text-opacity: 1;
}
.text-gray-900 {
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.text-zinc-50 {
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.text-zinc-100,
.text-zinc-50 {
  --tw-text-opacity: 1;
}
.text-zinc-100 {
  color: rgba(244, 244, 245, var(--tw-text-opacity));
}
.text-zinc-200 {
  color: rgba(228, 228, 231, var(--tw-text-opacity));
}
.text-zinc-200,
.text-zinc-300 {
  --tw-text-opacity: 1;
}
.text-zinc-300 {
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.text-zinc-400 {
  color: rgba(161, 161, 170, var(--tw-text-opacity));
}
.text-zinc-400,
.text-zinc-500 {
  --tw-text-opacity: 1;
}
.text-zinc-500 {
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}
.text-zinc-600 {
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.text-zinc-600,
.text-zinc-700 {
  --tw-text-opacity: 1;
}
.text-zinc-700 {
  color: rgba(63, 63, 70, var(--tw-text-opacity));
}
.text-zinc-800 {
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.text-zinc-800,
.text-zinc-900 {
  --tw-text-opacity: 1;
}
.text-zinc-900 {
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}
.text-neutral-50 {
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.text-neutral-100,
.text-neutral-50 {
  --tw-text-opacity: 1;
}
.text-neutral-100 {
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}
.text-neutral-200 {
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}
.text-neutral-200,
.text-neutral-300 {
  --tw-text-opacity: 1;
}
.text-neutral-300 {
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}
.text-neutral-400 {
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
.text-neutral-400,
.text-neutral-500 {
  --tw-text-opacity: 1;
}
.text-neutral-500 {
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
.text-neutral-600 {
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.text-neutral-600,
.text-neutral-700 {
  --tw-text-opacity: 1;
}
.text-neutral-700 {
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}
.text-neutral-800 {
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}
.text-neutral-800,
.text-neutral-900 {
  --tw-text-opacity: 1;
}
.text-neutral-900 {
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}
.text-stone-50 {
  color: rgba(250, 250, 249, var(--tw-text-opacity));
}
.text-stone-100,
.text-stone-50 {
  --tw-text-opacity: 1;
}
.text-stone-100 {
  color: rgba(245, 245, 244, var(--tw-text-opacity));
}
.text-stone-200 {
  color: rgba(231, 229, 228, var(--tw-text-opacity));
}
.text-stone-200,
.text-stone-300 {
  --tw-text-opacity: 1;
}
.text-stone-300 {
  color: rgba(214, 211, 209, var(--tw-text-opacity));
}
.text-stone-400 {
  color: rgba(168, 162, 158, var(--tw-text-opacity));
}
.text-stone-400,
.text-stone-500 {
  --tw-text-opacity: 1;
}
.text-stone-500 {
  color: rgba(120, 113, 108, var(--tw-text-opacity));
}
.text-stone-600 {
  color: rgba(87, 83, 78, var(--tw-text-opacity));
}
.text-stone-600,
.text-stone-700 {
  --tw-text-opacity: 1;
}
.text-stone-700 {
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}
.text-stone-800 {
  color: rgba(41, 37, 36, var(--tw-text-opacity));
}
.text-stone-800,
.text-stone-900 {
  --tw-text-opacity: 1;
}
.text-stone-900 {
  color: rgba(28, 25, 23, var(--tw-text-opacity));
}
.text-red-50 {
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.text-red-100,
.text-red-50 {
  --tw-text-opacity: 1;
}
.text-red-100 {
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.text-red-200 {
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-red-200,
.text-red-300 {
  --tw-text-opacity: 1;
}
.text-red-300 {
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.text-red-400 {
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.text-red-400,
.text-red-500 {
  --tw-text-opacity: 1;
}
.text-red-500 {
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-red-600 {
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.text-red-600,
.text-red-700 {
  --tw-text-opacity: 1;
}
.text-red-700 {
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.text-red-800 {
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.text-red-800,
.text-red-900 {
  --tw-text-opacity: 1;
}
.text-red-900 {
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.text-orange-50 {
  color: rgba(255, 247, 237, var(--tw-text-opacity));
}
.text-orange-100,
.text-orange-50 {
  --tw-text-opacity: 1;
}
.text-orange-100 {
  color: rgba(255, 237, 213, var(--tw-text-opacity));
}
.text-orange-200 {
  color: rgba(254, 215, 170, var(--tw-text-opacity));
}
.text-orange-200,
.text-orange-300 {
  --tw-text-opacity: 1;
}
.text-orange-300 {
  color: rgba(253, 186, 116, var(--tw-text-opacity));
}
.text-orange-400 {
  color: rgba(251, 146, 60, var(--tw-text-opacity));
}
.text-orange-400,
.text-orange-500 {
  --tw-text-opacity: 1;
}
.text-orange-500 {
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.text-orange-600 {
  color: rgba(234, 88, 12, var(--tw-text-opacity));
}
.text-orange-600,
.text-orange-700 {
  --tw-text-opacity: 1;
}
.text-orange-700 {
  color: rgba(194, 65, 12, var(--tw-text-opacity));
}
.text-orange-800 {
  color: rgba(154, 52, 18, var(--tw-text-opacity));
}
.text-orange-800,
.text-orange-900 {
  --tw-text-opacity: 1;
}
.text-orange-900 {
  color: rgba(124, 45, 18, var(--tw-text-opacity));
}
.text-amber-50 {
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.text-amber-100,
.text-amber-50 {
  --tw-text-opacity: 1;
}
.text-amber-100 {
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.text-amber-200 {
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.text-amber-200,
.text-amber-300 {
  --tw-text-opacity: 1;
}
.text-amber-300 {
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.text-amber-400 {
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.text-amber-400,
.text-amber-500 {
  --tw-text-opacity: 1;
}
.text-amber-500 {
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-amber-600 {
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.text-amber-600,
.text-amber-700 {
  --tw-text-opacity: 1;
}
.text-amber-700 {
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.text-amber-800 {
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.text-amber-800,
.text-amber-900 {
  --tw-text-opacity: 1;
}
.text-amber-900 {
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.text-yellow-50 {
  color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.text-yellow-100,
.text-yellow-50 {
  --tw-text-opacity: 1;
}
.text-yellow-100 {
  color: rgba(254, 249, 195, var(--tw-text-opacity));
}
.text-yellow-200 {
  color: rgba(254, 240, 138, var(--tw-text-opacity));
}
.text-yellow-200,
.text-yellow-300 {
  --tw-text-opacity: 1;
}
.text-yellow-300 {
  color: rgba(253, 224, 71, var(--tw-text-opacity));
}
.text-yellow-400 {
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}
.text-yellow-400,
.text-yellow-500 {
  --tw-text-opacity: 1;
}
.text-yellow-500 {
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}
.text-yellow-600 {
  color: rgba(202, 138, 4, var(--tw-text-opacity));
}
.text-yellow-600,
.text-yellow-700 {
  --tw-text-opacity: 1;
}
.text-yellow-700 {
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}
.text-yellow-800 {
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}
.text-yellow-800,
.text-yellow-900 {
  --tw-text-opacity: 1;
}
.text-yellow-900 {
  color: rgba(113, 63, 18, var(--tw-text-opacity));
}
.text-lime-50 {
  color: rgba(247, 254, 231, var(--tw-text-opacity));
}
.text-lime-100,
.text-lime-50 {
  --tw-text-opacity: 1;
}
.text-lime-100 {
  color: rgba(236, 252, 203, var(--tw-text-opacity));
}
.text-lime-200 {
  color: rgba(217, 249, 157, var(--tw-text-opacity));
}
.text-lime-200,
.text-lime-300 {
  --tw-text-opacity: 1;
}
.text-lime-300 {
  color: rgba(190, 242, 100, var(--tw-text-opacity));
}
.text-lime-400 {
  color: rgba(163, 230, 53, var(--tw-text-opacity));
}
.text-lime-400,
.text-lime-500 {
  --tw-text-opacity: 1;
}
.text-lime-500 {
  color: rgba(132, 204, 22, var(--tw-text-opacity));
}
.text-lime-600 {
  color: rgba(101, 163, 13, var(--tw-text-opacity));
}
.text-lime-600,
.text-lime-700 {
  --tw-text-opacity: 1;
}
.text-lime-700 {
  color: rgba(77, 124, 15, var(--tw-text-opacity));
}
.text-lime-800 {
  color: rgba(63, 98, 18, var(--tw-text-opacity));
}
.text-lime-800,
.text-lime-900 {
  --tw-text-opacity: 1;
}
.text-lime-900 {
  color: rgba(54, 83, 20, var(--tw-text-opacity));
}
.text-green-50 {
  color: rgba(240, 253, 244, var(--tw-text-opacity));
}
.text-green-100,
.text-green-50 {
  --tw-text-opacity: 1;
}
.text-green-100 {
  color: rgba(220, 252, 231, var(--tw-text-opacity));
}
.text-green-200 {
  color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.text-green-200,
.text-green-300 {
  --tw-text-opacity: 1;
}
.text-green-300 {
  color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.text-green-400 {
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}
.text-green-400,
.text-green-500 {
  --tw-text-opacity: 1;
}
.text-green-500 {
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}
.text-green-600 {
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}
.text-green-600,
.text-green-700 {
  --tw-text-opacity: 1;
}
.text-green-700 {
  color: rgba(21, 128, 61, var(--tw-text-opacity));
}
.text-green-800 {
  color: rgba(22, 101, 52, var(--tw-text-opacity));
}
.text-green-800,
.text-green-900 {
  --tw-text-opacity: 1;
}
.text-green-900 {
  color: rgba(20, 83, 45, var(--tw-text-opacity));
}
.text-emerald-50 {
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.text-emerald-100,
.text-emerald-50 {
  --tw-text-opacity: 1;
}
.text-emerald-100 {
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.text-emerald-200 {
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.text-emerald-200,
.text-emerald-300 {
  --tw-text-opacity: 1;
}
.text-emerald-300 {
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.text-emerald-400 {
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.text-emerald-400,
.text-emerald-500 {
  --tw-text-opacity: 1;
}
.text-emerald-500 {
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-emerald-600 {
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.text-emerald-600,
.text-emerald-700 {
  --tw-text-opacity: 1;
}
.text-emerald-700 {
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.text-emerald-800 {
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.text-emerald-800,
.text-emerald-900 {
  --tw-text-opacity: 1;
}
.text-emerald-900 {
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.text-teal-50 {
  color: rgba(240, 253, 250, var(--tw-text-opacity));
}
.text-teal-100,
.text-teal-50 {
  --tw-text-opacity: 1;
}
.text-teal-100 {
  color: rgba(204, 251, 241, var(--tw-text-opacity));
}
.text-teal-200 {
  color: rgba(153, 246, 228, var(--tw-text-opacity));
}
.text-teal-200,
.text-teal-300 {
  --tw-text-opacity: 1;
}
.text-teal-300 {
  color: rgba(94, 234, 212, var(--tw-text-opacity));
}
.text-teal-400 {
  color: rgba(45, 212, 191, var(--tw-text-opacity));
}
.text-teal-400,
.text-teal-500 {
  --tw-text-opacity: 1;
}
.text-teal-500 {
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}
.text-teal-600 {
  color: rgba(13, 148, 136, var(--tw-text-opacity));
}
.text-teal-600,
.text-teal-700 {
  --tw-text-opacity: 1;
}
.text-teal-700 {
  color: rgba(15, 118, 110, var(--tw-text-opacity));
}
.text-teal-800 {
  color: rgba(17, 94, 89, var(--tw-text-opacity));
}
.text-teal-800,
.text-teal-900 {
  --tw-text-opacity: 1;
}
.text-teal-900 {
  color: rgba(19, 78, 74, var(--tw-text-opacity));
}
.text-cyan-50 {
  color: rgba(236, 254, 255, var(--tw-text-opacity));
}
.text-cyan-100,
.text-cyan-50 {
  --tw-text-opacity: 1;
}
.text-cyan-100 {
  color: rgba(207, 250, 254, var(--tw-text-opacity));
}
.text-cyan-200 {
  color: rgba(165, 243, 252, var(--tw-text-opacity));
}
.text-cyan-200,
.text-cyan-300 {
  --tw-text-opacity: 1;
}
.text-cyan-300 {
  color: rgba(103, 232, 249, var(--tw-text-opacity));
}
.text-cyan-400 {
  color: rgba(34, 211, 238, var(--tw-text-opacity));
}
.text-cyan-400,
.text-cyan-500 {
  --tw-text-opacity: 1;
}
.text-cyan-500 {
  color: rgba(6, 182, 212, var(--tw-text-opacity));
}
.text-cyan-600 {
  color: rgba(8, 145, 178, var(--tw-text-opacity));
}
.text-cyan-600,
.text-cyan-700 {
  --tw-text-opacity: 1;
}
.text-cyan-700 {
  color: rgba(14, 116, 144, var(--tw-text-opacity));
}
.text-cyan-800 {
  color: rgba(21, 94, 117, var(--tw-text-opacity));
}
.text-cyan-800,
.text-cyan-900 {
  --tw-text-opacity: 1;
}
.text-cyan-900 {
  color: rgba(22, 78, 99, var(--tw-text-opacity));
}
.text-sky-50 {
  color: rgba(240, 249, 255, var(--tw-text-opacity));
}
.text-sky-100,
.text-sky-50 {
  --tw-text-opacity: 1;
}
.text-sky-100 {
  color: rgba(224, 242, 254, var(--tw-text-opacity));
}
.text-sky-200 {
  color: rgba(186, 230, 253, var(--tw-text-opacity));
}
.text-sky-200,
.text-sky-300 {
  --tw-text-opacity: 1;
}
.text-sky-300 {
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}
.text-sky-400 {
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}
.text-sky-400,
.text-sky-500 {
  --tw-text-opacity: 1;
}
.text-sky-500 {
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}
.text-sky-600 {
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}
.text-sky-600,
.text-sky-700 {
  --tw-text-opacity: 1;
}
.text-sky-700 {
  color: rgba(3, 105, 161, var(--tw-text-opacity));
}
.text-sky-800 {
  color: rgba(7, 89, 133, var(--tw-text-opacity));
}
.text-sky-800,
.text-sky-900 {
  --tw-text-opacity: 1;
}
.text-sky-900 {
  color: rgba(12, 74, 110, var(--tw-text-opacity));
}
.text-blue-50 {
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.text-blue-100,
.text-blue-50 {
  --tw-text-opacity: 1;
}
.text-blue-100 {
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.text-blue-200 {
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.text-blue-200,
.text-blue-300 {
  --tw-text-opacity: 1;
}
.text-blue-300 {
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.text-blue-400 {
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.text-blue-400,
.text-blue-500 {
  --tw-text-opacity: 1;
}
.text-blue-500 {
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-blue-600 {
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.text-blue-600,
.text-blue-700 {
  --tw-text-opacity: 1;
}
.text-blue-700 {
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-blue-800 {
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.text-blue-800,
.text-blue-900 {
  --tw-text-opacity: 1;
}
.text-blue-900 {
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.text-indigo-50 {
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.text-indigo-100,
.text-indigo-50 {
  --tw-text-opacity: 1;
}
.text-indigo-100 {
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.text-indigo-200 {
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.text-indigo-200,
.text-indigo-300 {
  --tw-text-opacity: 1;
}
.text-indigo-300 {
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.text-indigo-400 {
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.text-indigo-400,
.text-indigo-500 {
  --tw-text-opacity: 1;
}
.text-indigo-500 {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.text-indigo-600 {
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.text-indigo-600,
.text-indigo-700 {
  --tw-text-opacity: 1;
}
.text-indigo-700 {
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.text-indigo-800 {
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.text-indigo-800,
.text-indigo-900 {
  --tw-text-opacity: 1;
}
.text-indigo-900 {
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.text-violet-50 {
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.text-violet-100,
.text-violet-50 {
  --tw-text-opacity: 1;
}
.text-violet-100 {
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.text-violet-200 {
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.text-violet-200,
.text-violet-300 {
  --tw-text-opacity: 1;
}
.text-violet-300 {
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.text-violet-400 {
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.text-violet-400,
.text-violet-500 {
  --tw-text-opacity: 1;
}
.text-violet-500 {
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.text-violet-600 {
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.text-violet-600,
.text-violet-700 {
  --tw-text-opacity: 1;
}
.text-violet-700 {
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.text-violet-800 {
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.text-violet-800,
.text-violet-900 {
  --tw-text-opacity: 1;
}
.text-violet-900 {
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.text-purple-50 {
  color: rgba(250, 245, 255, var(--tw-text-opacity));
}
.text-purple-100,
.text-purple-50 {
  --tw-text-opacity: 1;
}
.text-purple-100 {
  color: rgba(243, 232, 255, var(--tw-text-opacity));
}
.text-purple-200 {
  color: rgba(233, 213, 255, var(--tw-text-opacity));
}
.text-purple-200,
.text-purple-300 {
  --tw-text-opacity: 1;
}
.text-purple-300 {
  color: rgba(216, 180, 254, var(--tw-text-opacity));
}
.text-purple-400 {
  color: rgba(192, 132, 252, var(--tw-text-opacity));
}
.text-purple-400,
.text-purple-500 {
  --tw-text-opacity: 1;
}
.text-purple-500 {
  color: rgba(168, 85, 247, var(--tw-text-opacity));
}
.text-purple-600 {
  color: rgba(147, 51, 234, var(--tw-text-opacity));
}
.text-purple-600,
.text-purple-700 {
  --tw-text-opacity: 1;
}
.text-purple-700 {
  color: rgba(126, 34, 206, var(--tw-text-opacity));
}
.text-purple-800 {
  color: rgba(107, 33, 168, var(--tw-text-opacity));
}
.text-purple-800,
.text-purple-900 {
  --tw-text-opacity: 1;
}
.text-purple-900 {
  color: rgba(88, 28, 135, var(--tw-text-opacity));
}
.text-fuchsia-50 {
  color: rgba(253, 244, 255, var(--tw-text-opacity));
}
.text-fuchsia-100,
.text-fuchsia-50 {
  --tw-text-opacity: 1;
}
.text-fuchsia-100 {
  color: rgba(250, 232, 255, var(--tw-text-opacity));
}
.text-fuchsia-200 {
  color: rgba(245, 208, 254, var(--tw-text-opacity));
}
.text-fuchsia-200,
.text-fuchsia-300 {
  --tw-text-opacity: 1;
}
.text-fuchsia-300 {
  color: rgba(240, 171, 252, var(--tw-text-opacity));
}
.text-fuchsia-400 {
  color: rgba(232, 121, 249, var(--tw-text-opacity));
}
.text-fuchsia-400,
.text-fuchsia-500 {
  --tw-text-opacity: 1;
}
.text-fuchsia-500 {
  color: rgba(217, 70, 239, var(--tw-text-opacity));
}
.text-fuchsia-600 {
  color: rgba(192, 38, 211, var(--tw-text-opacity));
}
.text-fuchsia-600,
.text-fuchsia-700 {
  --tw-text-opacity: 1;
}
.text-fuchsia-700 {
  color: rgba(162, 28, 175, var(--tw-text-opacity));
}
.text-fuchsia-800 {
  color: rgba(134, 25, 143, var(--tw-text-opacity));
}
.text-fuchsia-800,
.text-fuchsia-900 {
  --tw-text-opacity: 1;
}
.text-fuchsia-900 {
  color: rgba(112, 26, 117, var(--tw-text-opacity));
}
.text-pink-50 {
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.text-pink-100,
.text-pink-50 {
  --tw-text-opacity: 1;
}
.text-pink-100 {
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.text-pink-200 {
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.text-pink-200,
.text-pink-300 {
  --tw-text-opacity: 1;
}
.text-pink-300 {
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.text-pink-400 {
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.text-pink-400,
.text-pink-500 {
  --tw-text-opacity: 1;
}
.text-pink-500 {
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.text-pink-600 {
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.text-pink-600,
.text-pink-700 {
  --tw-text-opacity: 1;
}
.text-pink-700 {
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.text-pink-800 {
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.text-pink-800,
.text-pink-900 {
  --tw-text-opacity: 1;
}
.text-pink-900 {
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.text-rose-50 {
  color: rgba(255, 241, 242, var(--tw-text-opacity));
}
.text-rose-100,
.text-rose-50 {
  --tw-text-opacity: 1;
}
.text-rose-100 {
  color: rgba(255, 228, 230, var(--tw-text-opacity));
}
.text-rose-200 {
  color: rgba(254, 205, 211, var(--tw-text-opacity));
}
.text-rose-200,
.text-rose-300 {
  --tw-text-opacity: 1;
}
.text-rose-300 {
  color: rgba(253, 164, 175, var(--tw-text-opacity));
}
.text-rose-400 {
  color: rgba(251, 113, 133, var(--tw-text-opacity));
}
.text-rose-400,
.text-rose-500 {
  --tw-text-opacity: 1;
}
.text-rose-500 {
  color: rgba(244, 63, 94, var(--tw-text-opacity));
}
.text-rose-600 {
  color: rgba(225, 29, 72, var(--tw-text-opacity));
}
.text-rose-600,
.text-rose-700 {
  --tw-text-opacity: 1;
}
.text-rose-700 {
  color: rgba(190, 18, 60, var(--tw-text-opacity));
}
.text-rose-800 {
  color: rgba(159, 18, 57, var(--tw-text-opacity));
}
.text-rose-800,
.text-rose-900 {
  --tw-text-opacity: 1;
}
.text-rose-900 {
  color: rgba(136, 19, 55, var(--tw-text-opacity));
}
.text-\[\#1773ea\] {
  color: rgba(23, 115, 234, var(--tw-text-opacity));
}
.text-\[\#1773ea\],
.text-\[\#1da1f3\] {
  --tw-text-opacity: 1;
}
.text-\[\#1da1f3\] {
  color: rgba(29, 161, 243, var(--tw-text-opacity));
}
.text-\[\#df0018\] {
  color: rgba(223, 0, 24, var(--tw-text-opacity));
}
.text-\[\#0077b5\],
.text-\[\#df0018\] {
  --tw-text-opacity: 1;
}
.text-\[\#0077b5\] {
  color: rgba(0, 119, 181, var(--tw-text-opacity));
}
.fill-inherit {
  fill: inherit;
}
.fill-current {
  fill: currentColor;
}
.fill-transparent {
  fill: initial;
}
.fill-black {
  fill: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.fill-black,
.fill-white {
  --tw-bg-opacity: 1;
}
.fill-white {
  fill: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.fill-slate-50 {
  fill: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.fill-slate-100,
.fill-slate-50 {
  --tw-bg-opacity: 1;
}
.fill-slate-100 {
  fill: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.fill-slate-200 {
  fill: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.fill-slate-200,
.fill-slate-300 {
  --tw-bg-opacity: 1;
}
.fill-slate-300 {
  fill: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.fill-slate-400 {
  fill: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.fill-slate-400,
.fill-slate-500 {
  --tw-bg-opacity: 1;
}
.fill-slate-500 {
  fill: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.fill-slate-600 {
  fill: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.fill-slate-600,
.fill-slate-700 {
  --tw-bg-opacity: 1;
}
.fill-slate-700 {
  fill: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.fill-slate-800 {
  fill: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.fill-slate-800,
.fill-slate-900 {
  --tw-bg-opacity: 1;
}
.fill-slate-900 {
  fill: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.fill-gray-50 {
  fill: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.fill-gray-100,
.fill-gray-50 {
  --tw-bg-opacity: 1;
}
.fill-gray-100 {
  fill: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.fill-gray-200 {
  fill: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.fill-gray-200,
.fill-gray-300 {
  --tw-bg-opacity: 1;
}
.fill-gray-300 {
  fill: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.fill-gray-400 {
  fill: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.fill-gray-400,
.fill-gray-500 {
  --tw-bg-opacity: 1;
}
.fill-gray-500 {
  fill: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.fill-gray-600 {
  fill: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.fill-gray-600,
.fill-gray-700 {
  --tw-bg-opacity: 1;
}
.fill-gray-700 {
  fill: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.fill-gray-800 {
  fill: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.fill-gray-800,
.fill-gray-900 {
  --tw-bg-opacity: 1;
}
.fill-gray-900 {
  fill: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.fill-zinc-50 {
  fill: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.fill-zinc-100,
.fill-zinc-50 {
  --tw-bg-opacity: 1;
}
.fill-zinc-100 {
  fill: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.fill-zinc-200 {
  fill: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.fill-zinc-200,
.fill-zinc-300 {
  --tw-bg-opacity: 1;
}
.fill-zinc-300 {
  fill: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.fill-zinc-400 {
  fill: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.fill-zinc-400,
.fill-zinc-500 {
  --tw-bg-opacity: 1;
}
.fill-zinc-500 {
  fill: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.fill-zinc-600 {
  fill: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.fill-zinc-600,
.fill-zinc-700 {
  --tw-bg-opacity: 1;
}
.fill-zinc-700 {
  fill: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.fill-zinc-800 {
  fill: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.fill-zinc-800,
.fill-zinc-900 {
  --tw-bg-opacity: 1;
}
.fill-zinc-900 {
  fill: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.fill-neutral-50 {
  fill: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.fill-neutral-100,
.fill-neutral-50 {
  --tw-bg-opacity: 1;
}
.fill-neutral-100 {
  fill: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.fill-neutral-200 {
  fill: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.fill-neutral-200,
.fill-neutral-300 {
  --tw-bg-opacity: 1;
}
.fill-neutral-300 {
  fill: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.fill-neutral-400 {
  fill: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.fill-neutral-400,
.fill-neutral-500 {
  --tw-bg-opacity: 1;
}
.fill-neutral-500 {
  fill: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.fill-neutral-600 {
  fill: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.fill-neutral-600,
.fill-neutral-700 {
  --tw-bg-opacity: 1;
}
.fill-neutral-700 {
  fill: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.fill-neutral-800 {
  fill: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.fill-neutral-800,
.fill-neutral-900 {
  --tw-bg-opacity: 1;
}
.fill-neutral-900 {
  fill: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.fill-stone-50 {
  fill: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.fill-stone-100,
.fill-stone-50 {
  --tw-bg-opacity: 1;
}
.fill-stone-100 {
  fill: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.fill-stone-200 {
  fill: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.fill-stone-200,
.fill-stone-300 {
  --tw-bg-opacity: 1;
}
.fill-stone-300 {
  fill: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.fill-stone-400 {
  fill: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.fill-stone-400,
.fill-stone-500 {
  --tw-bg-opacity: 1;
}
.fill-stone-500 {
  fill: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.fill-stone-600 {
  fill: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.fill-stone-600,
.fill-stone-700 {
  --tw-bg-opacity: 1;
}
.fill-stone-700 {
  fill: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.fill-stone-800 {
  fill: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.fill-stone-800,
.fill-stone-900 {
  --tw-bg-opacity: 1;
}
.fill-stone-900 {
  fill: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.fill-red-50 {
  fill: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.fill-red-100,
.fill-red-50 {
  --tw-bg-opacity: 1;
}
.fill-red-100 {
  fill: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.fill-red-200 {
  fill: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.fill-red-200,
.fill-red-300 {
  --tw-bg-opacity: 1;
}
.fill-red-300 {
  fill: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.fill-red-400 {
  fill: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.fill-red-400,
.fill-red-500 {
  --tw-bg-opacity: 1;
}
.fill-red-500 {
  fill: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.fill-red-600 {
  fill: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.fill-red-600,
.fill-red-700 {
  --tw-bg-opacity: 1;
}
.fill-red-700 {
  fill: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.fill-red-800 {
  fill: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.fill-red-800,
.fill-red-900 {
  --tw-bg-opacity: 1;
}
.fill-red-900 {
  fill: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.fill-orange-50 {
  fill: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.fill-orange-100,
.fill-orange-50 {
  --tw-bg-opacity: 1;
}
.fill-orange-100 {
  fill: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.fill-orange-200 {
  fill: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.fill-orange-200,
.fill-orange-300 {
  --tw-bg-opacity: 1;
}
.fill-orange-300 {
  fill: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.fill-orange-400 {
  fill: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.fill-orange-400,
.fill-orange-500 {
  --tw-bg-opacity: 1;
}
.fill-orange-500 {
  fill: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.fill-orange-600 {
  fill: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.fill-orange-600,
.fill-orange-700 {
  --tw-bg-opacity: 1;
}
.fill-orange-700 {
  fill: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.fill-orange-800 {
  fill: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.fill-orange-800,
.fill-orange-900 {
  --tw-bg-opacity: 1;
}
.fill-orange-900 {
  fill: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.fill-amber-50 {
  fill: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.fill-amber-100,
.fill-amber-50 {
  --tw-bg-opacity: 1;
}
.fill-amber-100 {
  fill: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.fill-amber-200 {
  fill: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.fill-amber-200,
.fill-amber-300 {
  --tw-bg-opacity: 1;
}
.fill-amber-300 {
  fill: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.fill-amber-400 {
  fill: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.fill-amber-400,
.fill-amber-500 {
  --tw-bg-opacity: 1;
}
.fill-amber-500 {
  fill: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.fill-amber-600 {
  fill: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.fill-amber-600,
.fill-amber-700 {
  --tw-bg-opacity: 1;
}
.fill-amber-700 {
  fill: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.fill-amber-800 {
  fill: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.fill-amber-800,
.fill-amber-900 {
  --tw-bg-opacity: 1;
}
.fill-amber-900 {
  fill: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.fill-yellow-50 {
  fill: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.fill-yellow-100,
.fill-yellow-50 {
  --tw-bg-opacity: 1;
}
.fill-yellow-100 {
  fill: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.fill-yellow-200 {
  fill: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.fill-yellow-200,
.fill-yellow-300 {
  --tw-bg-opacity: 1;
}
.fill-yellow-300 {
  fill: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.fill-yellow-400 {
  fill: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.fill-yellow-400,
.fill-yellow-500 {
  --tw-bg-opacity: 1;
}
.fill-yellow-500 {
  fill: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.fill-yellow-600 {
  fill: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.fill-yellow-600,
.fill-yellow-700 {
  --tw-bg-opacity: 1;
}
.fill-yellow-700 {
  fill: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.fill-yellow-800 {
  fill: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.fill-yellow-800,
.fill-yellow-900 {
  --tw-bg-opacity: 1;
}
.fill-yellow-900 {
  fill: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.fill-lime-50 {
  fill: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.fill-lime-100,
.fill-lime-50 {
  --tw-bg-opacity: 1;
}
.fill-lime-100 {
  fill: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.fill-lime-200 {
  fill: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.fill-lime-200,
.fill-lime-300 {
  --tw-bg-opacity: 1;
}
.fill-lime-300 {
  fill: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.fill-lime-400 {
  fill: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.fill-lime-400,
.fill-lime-500 {
  --tw-bg-opacity: 1;
}
.fill-lime-500 {
  fill: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.fill-lime-600 {
  fill: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.fill-lime-600,
.fill-lime-700 {
  --tw-bg-opacity: 1;
}
.fill-lime-700 {
  fill: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.fill-lime-800 {
  fill: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.fill-lime-800,
.fill-lime-900 {
  --tw-bg-opacity: 1;
}
.fill-lime-900 {
  fill: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.fill-green-50 {
  fill: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.fill-green-100,
.fill-green-50 {
  --tw-bg-opacity: 1;
}
.fill-green-100 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-200 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-200,
.fill-green-300 {
  --tw-bg-opacity: 1;
}
.fill-green-300 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-400 {
  fill: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.fill-green-400,
.fill-green-500 {
  --tw-bg-opacity: 1;
}
.fill-green-500 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-600 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-600,
.fill-green-700 {
  --tw-bg-opacity: 1;
}
.fill-green-700 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-800 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-green-800,
.fill-green-900 {
  --tw-bg-opacity: 1;
}
.fill-green-900 {
  fill: rgba(67, 160, 3, var(--tw-bg-opacity));
}
.fill-emerald-50 {
  fill: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.fill-emerald-100,
.fill-emerald-50 {
  --tw-bg-opacity: 1;
}
.fill-emerald-100 {
  fill: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.fill-emerald-200 {
  fill: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.fill-emerald-200,
.fill-emerald-300 {
  --tw-bg-opacity: 1;
}
.fill-emerald-300 {
  fill: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.fill-emerald-400 {
  fill: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.fill-emerald-400,
.fill-emerald-500 {
  --tw-bg-opacity: 1;
}
.fill-emerald-500 {
  fill: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.fill-emerald-600 {
  fill: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.fill-emerald-600,
.fill-emerald-700 {
  --tw-bg-opacity: 1;
}
.fill-emerald-700 {
  fill: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.fill-emerald-800 {
  fill: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.fill-emerald-800,
.fill-emerald-900 {
  --tw-bg-opacity: 1;
}
.fill-emerald-900 {
  fill: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.fill-teal-50 {
  fill: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.fill-teal-100,
.fill-teal-50 {
  --tw-bg-opacity: 1;
}
.fill-teal-100 {
  fill: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.fill-teal-200 {
  fill: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.fill-teal-200,
.fill-teal-300 {
  --tw-bg-opacity: 1;
}
.fill-teal-300 {
  fill: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.fill-teal-400 {
  fill: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.fill-teal-400,
.fill-teal-500 {
  --tw-bg-opacity: 1;
}
.fill-teal-500 {
  fill: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.fill-teal-600 {
  fill: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.fill-teal-600,
.fill-teal-700 {
  --tw-bg-opacity: 1;
}
.fill-teal-700 {
  fill: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.fill-teal-800 {
  fill: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.fill-teal-800,
.fill-teal-900 {
  --tw-bg-opacity: 1;
}
.fill-teal-900 {
  fill: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.fill-cyan-50 {
  fill: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.fill-cyan-100,
.fill-cyan-50 {
  --tw-bg-opacity: 1;
}
.fill-cyan-100 {
  fill: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.fill-cyan-200 {
  fill: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.fill-cyan-200,
.fill-cyan-300 {
  --tw-bg-opacity: 1;
}
.fill-cyan-300 {
  fill: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.fill-cyan-400 {
  fill: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.fill-cyan-400,
.fill-cyan-500 {
  --tw-bg-opacity: 1;
}
.fill-cyan-500 {
  fill: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.fill-cyan-600 {
  fill: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.fill-cyan-600,
.fill-cyan-700 {
  --tw-bg-opacity: 1;
}
.fill-cyan-700 {
  fill: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.fill-cyan-800 {
  fill: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.fill-cyan-800,
.fill-cyan-900 {
  --tw-bg-opacity: 1;
}
.fill-cyan-900 {
  fill: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.fill-sky-50 {
  fill: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.fill-sky-100,
.fill-sky-50 {
  --tw-bg-opacity: 1;
}
.fill-sky-100 {
  fill: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.fill-sky-200 {
  fill: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.fill-sky-200,
.fill-sky-300 {
  --tw-bg-opacity: 1;
}
.fill-sky-300 {
  fill: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.fill-sky-400 {
  fill: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.fill-sky-400,
.fill-sky-500 {
  --tw-bg-opacity: 1;
}
.fill-sky-500 {
  fill: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.fill-sky-600 {
  fill: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.fill-sky-600,
.fill-sky-700 {
  --tw-bg-opacity: 1;
}
.fill-sky-700 {
  fill: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.fill-sky-800 {
  fill: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.fill-sky-800,
.fill-sky-900 {
  --tw-bg-opacity: 1;
}
.fill-sky-900 {
  fill: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.fill-blue-50 {
  fill: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.fill-blue-100,
.fill-blue-50 {
  --tw-bg-opacity: 1;
}
.fill-blue-100 {
  fill: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.fill-blue-200 {
  fill: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.fill-blue-200,
.fill-blue-300 {
  --tw-bg-opacity: 1;
}
.fill-blue-300 {
  fill: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.fill-blue-400 {
  fill: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.fill-blue-400,
.fill-blue-500 {
  --tw-bg-opacity: 1;
}
.fill-blue-500 {
  fill: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.fill-blue-600 {
  fill: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.fill-blue-600,
.fill-blue-700 {
  --tw-bg-opacity: 1;
}
.fill-blue-700 {
  fill: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.fill-blue-800 {
  fill: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.fill-blue-800,
.fill-blue-900 {
  --tw-bg-opacity: 1;
}
.fill-blue-900 {
  fill: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.fill-indigo-50 {
  fill: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.fill-indigo-100,
.fill-indigo-50 {
  --tw-bg-opacity: 1;
}
.fill-indigo-100 {
  fill: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.fill-indigo-200 {
  fill: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.fill-indigo-200,
.fill-indigo-300 {
  --tw-bg-opacity: 1;
}
.fill-indigo-300 {
  fill: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.fill-indigo-400 {
  fill: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.fill-indigo-400,
.fill-indigo-500 {
  --tw-bg-opacity: 1;
}
.fill-indigo-500 {
  fill: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.fill-indigo-600 {
  fill: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.fill-indigo-600,
.fill-indigo-700 {
  --tw-bg-opacity: 1;
}
.fill-indigo-700 {
  fill: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.fill-indigo-800 {
  fill: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.fill-indigo-800,
.fill-indigo-900 {
  --tw-bg-opacity: 1;
}
.fill-indigo-900 {
  fill: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.fill-violet-50 {
  fill: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.fill-violet-100,
.fill-violet-50 {
  --tw-bg-opacity: 1;
}
.fill-violet-100 {
  fill: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.fill-violet-200 {
  fill: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.fill-violet-200,
.fill-violet-300 {
  --tw-bg-opacity: 1;
}
.fill-violet-300 {
  fill: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.fill-violet-400 {
  fill: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.fill-violet-400,
.fill-violet-500 {
  --tw-bg-opacity: 1;
}
.fill-violet-500 {
  fill: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.fill-violet-600 {
  fill: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.fill-violet-600,
.fill-violet-700 {
  --tw-bg-opacity: 1;
}
.fill-violet-700 {
  fill: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.fill-violet-800 {
  fill: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.fill-violet-800,
.fill-violet-900 {
  --tw-bg-opacity: 1;
}
.fill-violet-900 {
  fill: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.fill-purple-50 {
  fill: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.fill-purple-100,
.fill-purple-50 {
  --tw-bg-opacity: 1;
}
.fill-purple-100 {
  fill: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.fill-purple-200 {
  fill: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.fill-purple-200,
.fill-purple-300 {
  --tw-bg-opacity: 1;
}
.fill-purple-300 {
  fill: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.fill-purple-400 {
  fill: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.fill-purple-400,
.fill-purple-500 {
  --tw-bg-opacity: 1;
}
.fill-purple-500 {
  fill: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.fill-purple-600 {
  fill: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.fill-purple-600,
.fill-purple-700 {
  --tw-bg-opacity: 1;
}
.fill-purple-700 {
  fill: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.fill-purple-800 {
  fill: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.fill-purple-800,
.fill-purple-900 {
  --tw-bg-opacity: 1;
}
.fill-purple-900 {
  fill: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.fill-fuchsia-50 {
  fill: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.fill-fuchsia-100,
.fill-fuchsia-50 {
  --tw-bg-opacity: 1;
}
.fill-fuchsia-100 {
  fill: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.fill-fuchsia-200 {
  fill: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.fill-fuchsia-200,
.fill-fuchsia-300 {
  --tw-bg-opacity: 1;
}
.fill-fuchsia-300 {
  fill: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.fill-fuchsia-400 {
  fill: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.fill-fuchsia-400,
.fill-fuchsia-500 {
  --tw-bg-opacity: 1;
}
.fill-fuchsia-500 {
  fill: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.fill-fuchsia-600 {
  fill: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.fill-fuchsia-600,
.fill-fuchsia-700 {
  --tw-bg-opacity: 1;
}
.fill-fuchsia-700 {
  fill: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.fill-fuchsia-800 {
  fill: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.fill-fuchsia-800,
.fill-fuchsia-900 {
  --tw-bg-opacity: 1;
}
.fill-fuchsia-900 {
  fill: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.fill-pink-50 {
  fill: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.fill-pink-100,
.fill-pink-50 {
  --tw-bg-opacity: 1;
}
.fill-pink-100 {
  fill: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.fill-pink-200 {
  fill: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.fill-pink-200,
.fill-pink-300 {
  --tw-bg-opacity: 1;
}
.fill-pink-300 {
  fill: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.fill-pink-400 {
  fill: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.fill-pink-400,
.fill-pink-500 {
  --tw-bg-opacity: 1;
}
.fill-pink-500 {
  fill: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.fill-pink-600 {
  fill: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.fill-pink-600,
.fill-pink-700 {
  --tw-bg-opacity: 1;
}
.fill-pink-700 {
  fill: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.fill-pink-800 {
  fill: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.fill-pink-800,
.fill-pink-900 {
  --tw-bg-opacity: 1;
}
.fill-pink-900 {
  fill: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.fill-rose-50 {
  fill: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.fill-rose-100,
.fill-rose-50 {
  --tw-bg-opacity: 1;
}
.fill-rose-100 {
  fill: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.fill-rose-200 {
  fill: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.fill-rose-200,
.fill-rose-300 {
  --tw-bg-opacity: 1;
}
.fill-rose-300 {
  fill: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.fill-rose-400 {
  fill: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.fill-rose-400,
.fill-rose-500 {
  --tw-bg-opacity: 1;
}
.fill-rose-500 {
  fill: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.fill-rose-600 {
  fill: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.fill-rose-600,
.fill-rose-700 {
  --tw-bg-opacity: 1;
}
.fill-rose-700 {
  fill: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.fill-rose-800 {
  fill: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.fill-rose-800,
.fill-rose-900 {
  --tw-bg-opacity: 1;
}
.fill-rose-900 {
  fill: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.overline {
  -webkit-text-decoration-line: overline;
  text-decoration-line: overline;
}
.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-0 {
  opacity: 0;
}
.opacity-60 {
  opacity: 0.6;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
}
.shadow,
.shadow-none {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
}
.shadow-lg,
.shadow-sm {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
}
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline {
  outline-style: solid;
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-0,
.ring-1 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.ring-inherit {
  --tw-ring-color: inherit;
}
.ring-current {
  --tw-ring-color: currentColor;
}
.ring-transparent {
  --tw-ring-color: transparent;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.ring-slate-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 250, 252, var(--tw-ring-opacity));
}
.ring-slate-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 245, 249, var(--tw-ring-opacity));
}
.ring-slate-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}
.ring-slate-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 225, var(--tw-ring-opacity));
}
.ring-slate-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
}
.ring-slate-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 116, 139, var(--tw-ring-opacity));
}
.ring-slate-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(71, 85, 105, var(--tw-ring-opacity));
}
.ring-slate-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 65, 85, var(--tw-ring-opacity));
}
.ring-slate-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 41, 59, var(--tw-ring-opacity));
}
.ring-slate-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 23, 42, var(--tw-ring-opacity));
}
.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.ring-zinc-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.ring-zinc-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 244, 245, var(--tw-ring-opacity));
}
.ring-zinc-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.ring-zinc-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 216, var(--tw-ring-opacity));
}
.ring-zinc-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 161, 170, var(--tw-ring-opacity));
}
.ring-zinc-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}
.ring-zinc-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 91, var(--tw-ring-opacity));
}
.ring-zinc-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 63, 70, var(--tw-ring-opacity));
}
.ring-zinc-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 39, 42, var(--tw-ring-opacity));
}
.ring-zinc-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 24, 27, var(--tw-ring-opacity));
}
.ring-neutral-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.ring-neutral-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity));
}
.ring-neutral-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 229, 229, var(--tw-ring-opacity));
}
.ring-neutral-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}
.ring-neutral-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 163, 163, var(--tw-ring-opacity));
}
.ring-neutral-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}
.ring-neutral-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 82, var(--tw-ring-opacity));
}
.ring-neutral-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 64, 64, var(--tw-ring-opacity));
}
.ring-neutral-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 38, 38, var(--tw-ring-opacity));
}
.ring-neutral-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(23, 23, 23, var(--tw-ring-opacity));
}
.ring-stone-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 249, var(--tw-ring-opacity));
}
.ring-stone-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 244, var(--tw-ring-opacity));
}
.ring-stone-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(231, 229, 228, var(--tw-ring-opacity));
}
.ring-stone-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(214, 211, 209, var(--tw-ring-opacity));
}
.ring-stone-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 162, 158, var(--tw-ring-opacity));
}
.ring-stone-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 113, 108, var(--tw-ring-opacity));
}
.ring-stone-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(87, 83, 78, var(--tw-ring-opacity));
}
.ring-stone-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 64, 60, var(--tw-ring-opacity));
}
.ring-stone-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 37, 36, var(--tw-ring-opacity));
}
.ring-stone-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 25, 23, var(--tw-ring-opacity));
}
.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.ring-orange-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 247, 237, var(--tw-ring-opacity));
}
.ring-orange-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 237, 213, var(--tw-ring-opacity));
}
.ring-orange-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 215, 170, var(--tw-ring-opacity));
}
.ring-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 116, var(--tw-ring-opacity));
}
.ring-orange-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 146, 60, var(--tw-ring-opacity));
}
.ring-orange-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 115, 22, var(--tw-ring-opacity));
}
.ring-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 88, 12, var(--tw-ring-opacity));
}
.ring-orange-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 65, 12, var(--tw-ring-opacity));
}
.ring-orange-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(154, 52, 18, var(--tw-ring-opacity));
}
.ring-orange-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 45, 18, var(--tw-ring-opacity));
}
.ring-amber-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.ring-amber-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.ring-amber-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.ring-amber-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.ring-amber-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.ring-amber-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.ring-amber-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.ring-amber-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.ring-amber-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.ring-amber-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 252, 232, var(--tw-ring-opacity));
}
.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 249, 195, var(--tw-ring-opacity));
}
.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 240, 138, var(--tw-ring-opacity));
}
.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 224, 71, var(--tw-ring-opacity));
}
.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 204, 21, var(--tw-ring-opacity));
}
.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 138, 4, var(--tw-ring-opacity));
}
.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 98, 7, var(--tw-ring-opacity));
}
.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(133, 77, 14, var(--tw-ring-opacity));
}
.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 63, 18, var(--tw-ring-opacity));
}
.ring-lime-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 254, 231, var(--tw-ring-opacity));
}
.ring-lime-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 252, 203, var(--tw-ring-opacity));
}
.ring-lime-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 249, 157, var(--tw-ring-opacity));
}
.ring-lime-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 242, 100, var(--tw-ring-opacity));
}
.ring-lime-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 230, 53, var(--tw-ring-opacity));
}
.ring-lime-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 204, 22, var(--tw-ring-opacity));
}
.ring-lime-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 163, 13, var(--tw-ring-opacity));
}
.ring-lime-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 124, 15, var(--tw-ring-opacity));
}
.ring-lime-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 98, 18, var(--tw-ring-opacity));
}
.ring-lime-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(54, 83, 20, var(--tw-ring-opacity));
}
.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 244, var(--tw-ring-opacity));
}
.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 252, 231, var(--tw-ring-opacity));
}
.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 247, 208, var(--tw-ring-opacity));
}
.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 239, 172, var(--tw-ring-opacity));
}
.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 222, 128, var(--tw-ring-opacity));
}
.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}
.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 163, 74, var(--tw-ring-opacity));
}
.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 128, 61, var(--tw-ring-opacity));
}
.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 101, 52, var(--tw-ring-opacity));
}
.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 83, 45, var(--tw-ring-opacity));
}
.ring-emerald-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.ring-emerald-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.ring-emerald-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.ring-emerald-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.ring-emerald-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.ring-emerald-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.ring-emerald-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.ring-emerald-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.ring-emerald-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.ring-emerald-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.ring-teal-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 250, var(--tw-ring-opacity));
}
.ring-teal-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 251, 241, var(--tw-ring-opacity));
}
.ring-teal-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 246, 228, var(--tw-ring-opacity));
}
.ring-teal-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 234, 212, var(--tw-ring-opacity));
}
.ring-teal-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 212, 191, var(--tw-ring-opacity));
}
.ring-teal-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 184, 166, var(--tw-ring-opacity));
}
.ring-teal-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 148, 136, var(--tw-ring-opacity));
}
.ring-teal-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 118, 110, var(--tw-ring-opacity));
}
.ring-teal-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 94, 89, var(--tw-ring-opacity));
}
.ring-teal-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(19, 78, 74, var(--tw-ring-opacity));
}
.ring-cyan-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 254, 255, var(--tw-ring-opacity));
}
.ring-cyan-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 250, 254, var(--tw-ring-opacity));
}
.ring-cyan-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 243, 252, var(--tw-ring-opacity));
}
.ring-cyan-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 232, 249, var(--tw-ring-opacity));
}
.ring-cyan-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 211, 238, var(--tw-ring-opacity));
}
.ring-cyan-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 182, 212, var(--tw-ring-opacity));
}
.ring-cyan-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(8, 145, 178, var(--tw-ring-opacity));
}
.ring-cyan-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 116, 144, var(--tw-ring-opacity));
}
.ring-cyan-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 94, 117, var(--tw-ring-opacity));
}
.ring-cyan-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 78, 99, var(--tw-ring-opacity));
}
.ring-sky-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 249, 255, var(--tw-ring-opacity));
}
.ring-sky-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 254, var(--tw-ring-opacity));
}
.ring-sky-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 230, 253, var(--tw-ring-opacity));
}
.ring-sky-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(125, 211, 252, var(--tw-ring-opacity));
}
.ring-sky-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 189, 248, var(--tw-ring-opacity));
}
.ring-sky-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}
.ring-sky-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 132, 199, var(--tw-ring-opacity));
}
.ring-sky-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 105, 161, var(--tw-ring-opacity));
}
.ring-sky-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(7, 89, 133, var(--tw-ring-opacity));
}
.ring-sky-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(12, 74, 110, var(--tw-ring-opacity));
}
.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.ring-violet-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.ring-violet-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.ring-violet-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.ring-violet-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.ring-violet-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.ring-violet-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.ring-violet-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.ring-violet-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.ring-violet-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.ring-violet-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 245, 255, var(--tw-ring-opacity));
}
.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 232, 255, var(--tw-ring-opacity));
}
.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 213, 255, var(--tw-ring-opacity));
}
.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 180, 254, var(--tw-ring-opacity));
}
.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 132, 252, var(--tw-ring-opacity));
}
.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 85, 247, var(--tw-ring-opacity));
}
.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 51, 234, var(--tw-ring-opacity));
}
.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 34, 206, var(--tw-ring-opacity));
}
.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 33, 168, var(--tw-ring-opacity));
}
.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(88, 28, 135, var(--tw-ring-opacity));
}
.ring-fuchsia-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 244, 255, var(--tw-ring-opacity));
}
.ring-fuchsia-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 232, 255, var(--tw-ring-opacity));
}
.ring-fuchsia-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 208, 254, var(--tw-ring-opacity));
}
.ring-fuchsia-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 171, 252, var(--tw-ring-opacity));
}
.ring-fuchsia-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 121, 249, var(--tw-ring-opacity));
}
.ring-fuchsia-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 70, 239, var(--tw-ring-opacity));
}
.ring-fuchsia-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 38, 211, var(--tw-ring-opacity));
}
.ring-fuchsia-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(162, 28, 175, var(--tw-ring-opacity));
}
.ring-fuchsia-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 25, 143, var(--tw-ring-opacity));
}
.ring-fuchsia-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(112, 26, 117, var(--tw-ring-opacity));
}
.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.ring-rose-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 242, var(--tw-ring-opacity));
}
.ring-rose-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 228, 230, var(--tw-ring-opacity));
}
.ring-rose-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 205, 211, var(--tw-ring-opacity));
}
.ring-rose-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 164, 175, var(--tw-ring-opacity));
}
.ring-rose-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 113, 133, var(--tw-ring-opacity));
}
.ring-rose-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 63, 94, var(--tw-ring-opacity));
}
.ring-rose-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 29, 72, var(--tw-ring-opacity));
}
.ring-rose-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 18, 60, var(--tw-ring-opacity));
}
.ring-rose-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 18, 57, var(--tw-ring-opacity));
}
.ring-rose-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 19, 55, var(--tw-ring-opacity));
}
.ring-slate-900\/5 {
  --tw-ring-color: rgba(15, 23, 42, 0.05);
}
.blur {
  --tw-blur: blur(8px);
}
.blur,
.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-colors {
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition {
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-200 {
  transition-duration: 0.2s;
}
.duration-300 {
  transition-duration: 0.3s;
}
.duration-150 {
  transition-duration: 0.15s;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.demo-api-table code,
.demo-card .demo-card-description code {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
}
.dark .demo-api-table code,
.dark .demo-card .demo-card-description code {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.last\:border-0:last-child {
  border-width: 0;
}
.focus-within\:border-inherit[focus-within] {
  border-color: inherit;
}
.focus-within\:border-inherit:focus-within {
  border-color: inherit;
}
.focus-within\:border-current[focus-within] {
  border-color: currentColor;
}
.focus-within\:border-current:focus-within {
  border-color: currentColor;
}
.focus-within\:border-transparent[focus-within] {
  border-color: transparent;
}
.focus-within\:border-transparent:focus-within {
  border-color: transparent;
}
.focus-within\:border-black[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus-within\:border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus-within\:border-white[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus-within\:border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus-within\:border-slate-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}
.focus-within\:border-slate-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}
.focus-within\:border-slate-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}
.focus-within\:border-slate-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}
.focus-within\:border-slate-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}
.focus-within\:border-slate-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}
.focus-within\:border-slate-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}
.focus-within\:border-slate-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}
.focus-within\:border-slate-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(148, 163, 184, var(--tw-border-opacity));
}
.focus-within\:border-slate-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(148, 163, 184, var(--tw-border-opacity));
}
.focus-within\:border-slate-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}
.focus-within\:border-slate-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}
.focus-within\:border-slate-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}
.focus-within\:border-slate-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}
.focus-within\:border-slate-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(51, 65, 85, var(--tw-border-opacity));
}
.focus-within\:border-slate-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(51, 65, 85, var(--tw-border-opacity));
}
.focus-within\:border-slate-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(30, 41, 59, var(--tw-border-opacity));
}
.focus-within\:border-slate-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 41, 59, var(--tw-border-opacity));
}
.focus-within\:border-slate-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(15, 23, 42, var(--tw-border-opacity));
}
.focus-within\:border-slate-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(15, 23, 42, var(--tw-border-opacity));
}
.focus-within\:border-gray-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus-within\:border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus-within\:border-gray-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus-within\:border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus-within\:border-gray-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus-within\:border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus-within\:border-gray-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.focus-within\:border-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.focus-within\:border-gray-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus-within\:border-gray-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus-within\:border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus-within\:border-gray-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus-within\:border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus-within\:border-gray-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus-within\:border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus-within\:border-gray-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus-within\:border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus-within\:border-gray-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.focus-within\:border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.focus-within\:border-zinc-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus-within\:border-zinc-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus-within\:border-zinc-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 245, var(--tw-border-opacity));
}
.focus-within\:border-zinc-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 245, var(--tw-border-opacity));
}
.focus-within\:border-zinc-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}
.focus-within\:border-zinc-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}
.focus-within\:border-zinc-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}
.focus-within\:border-zinc-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}
.focus-within\:border-zinc-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}
.focus-within\:border-zinc-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}
.focus-within\:border-zinc-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}
.focus-within\:border-zinc-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}
.focus-within\:border-zinc-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 91, var(--tw-border-opacity));
}
.focus-within\:border-zinc-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 91, var(--tw-border-opacity));
}
.focus-within\:border-zinc-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(63, 63, 70, var(--tw-border-opacity));
}
.focus-within\:border-zinc-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(63, 63, 70, var(--tw-border-opacity));
}
.focus-within\:border-zinc-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(39, 39, 42, var(--tw-border-opacity));
}
.focus-within\:border-zinc-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(39, 39, 42, var(--tw-border-opacity));
}
.focus-within\:border-zinc-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(24, 24, 27, var(--tw-border-opacity));
}
.focus-within\:border-zinc-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(24, 24, 27, var(--tw-border-opacity));
}
.focus-within\:border-neutral-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus-within\:border-neutral-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus-within\:border-neutral-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.focus-within\:border-neutral-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.focus-within\:border-neutral-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}
.focus-within\:border-neutral-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}
.focus-within\:border-neutral-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
}
.focus-within\:border-neutral-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
}
.focus-within\:border-neutral-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}
.focus-within\:border-neutral-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}
.focus-within\:border-neutral-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}
.focus-within\:border-neutral-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}
.focus-within\:border-neutral-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 82, var(--tw-border-opacity));
}
.focus-within\:border-neutral-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 82, var(--tw-border-opacity));
}
.focus-within\:border-neutral-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}
.focus-within\:border-neutral-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}
.focus-within\:border-neutral-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-neutral-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-neutral-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(23, 23, 23, var(--tw-border-opacity));
}
.focus-within\:border-neutral-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(23, 23, 23, var(--tw-border-opacity));
}
.focus-within\:border-stone-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 249, var(--tw-border-opacity));
}
.focus-within\:border-stone-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 249, var(--tw-border-opacity));
}
.focus-within\:border-stone-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 244, var(--tw-border-opacity));
}
.focus-within\:border-stone-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 244, var(--tw-border-opacity));
}
.focus-within\:border-stone-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(231, 229, 228, var(--tw-border-opacity));
}
.focus-within\:border-stone-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(231, 229, 228, var(--tw-border-opacity));
}
.focus-within\:border-stone-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(214, 211, 209, var(--tw-border-opacity));
}
.focus-within\:border-stone-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(214, 211, 209, var(--tw-border-opacity));
}
.focus-within\:border-stone-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(168, 162, 158, var(--tw-border-opacity));
}
.focus-within\:border-stone-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(168, 162, 158, var(--tw-border-opacity));
}
.focus-within\:border-stone-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(120, 113, 108, var(--tw-border-opacity));
}
.focus-within\:border-stone-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 113, 108, var(--tw-border-opacity));
}
.focus-within\:border-stone-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(87, 83, 78, var(--tw-border-opacity));
}
.focus-within\:border-stone-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(87, 83, 78, var(--tw-border-opacity));
}
.focus-within\:border-stone-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(68, 64, 60, var(--tw-border-opacity));
}
.focus-within\:border-stone-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(68, 64, 60, var(--tw-border-opacity));
}
.focus-within\:border-stone-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 36, var(--tw-border-opacity));
}
.focus-within\:border-stone-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 36, var(--tw-border-opacity));
}
.focus-within\:border-stone-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(28, 25, 23, var(--tw-border-opacity));
}
.focus-within\:border-stone-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(28, 25, 23, var(--tw-border-opacity));
}
.focus-within\:border-red-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus-within\:border-red-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus-within\:border-red-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus-within\:border-red-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus-within\:border-red-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus-within\:border-red-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus-within\:border-red-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus-within\:border-red-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus-within\:border-red-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus-within\:border-red-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus-within\:border-red-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus-within\:border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus-within\:border-red-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-red-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-red-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus-within\:border-red-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus-within\:border-red-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus-within\:border-red-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus-within\:border-red-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus-within\:border-red-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus-within\:border-orange-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 247, 237, var(--tw-border-opacity));
}
.focus-within\:border-orange-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 247, 237, var(--tw-border-opacity));
}
.focus-within\:border-orange-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 237, 213, var(--tw-border-opacity));
}
.focus-within\:border-orange-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 237, 213, var(--tw-border-opacity));
}
.focus-within\:border-orange-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 215, 170, var(--tw-border-opacity));
}
.focus-within\:border-orange-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 215, 170, var(--tw-border-opacity));
}
.focus-within\:border-orange-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 186, 116, var(--tw-border-opacity));
}
.focus-within\:border-orange-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 186, 116, var(--tw-border-opacity));
}
.focus-within\:border-orange-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(251, 146, 60, var(--tw-border-opacity));
}
.focus-within\:border-orange-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 146, 60, var(--tw-border-opacity));
}
.focus-within\:border-orange-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(249, 115, 22, var(--tw-border-opacity));
}
.focus-within\:border-orange-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 115, 22, var(--tw-border-opacity));
}
.focus-within\:border-orange-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(234, 88, 12, var(--tw-border-opacity));
}
.focus-within\:border-orange-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(234, 88, 12, var(--tw-border-opacity));
}
.focus-within\:border-orange-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(194, 65, 12, var(--tw-border-opacity));
}
.focus-within\:border-orange-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(194, 65, 12, var(--tw-border-opacity));
}
.focus-within\:border-orange-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(154, 52, 18, var(--tw-border-opacity));
}
.focus-within\:border-orange-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(154, 52, 18, var(--tw-border-opacity));
}
.focus-within\:border-orange-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(124, 45, 18, var(--tw-border-opacity));
}
.focus-within\:border-orange-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 45, 18, var(--tw-border-opacity));
}
.focus-within\:border-amber-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus-within\:border-amber-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus-within\:border-amber-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus-within\:border-amber-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus-within\:border-amber-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus-within\:border-amber-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus-within\:border-amber-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus-within\:border-amber-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus-within\:border-amber-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus-within\:border-amber-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus-within\:border-amber-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus-within\:border-amber-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus-within\:border-amber-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus-within\:border-amber-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus-within\:border-amber-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus-within\:border-amber-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus-within\:border-amber-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus-within\:border-amber-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus-within\:border-amber-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus-within\:border-amber-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus-within\:border-yellow-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 252, 232, var(--tw-border-opacity));
}
.focus-within\:border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 252, 232, var(--tw-border-opacity));
}
.focus-within\:border-yellow-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 249, 195, var(--tw-border-opacity));
}
.focus-within\:border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 249, 195, var(--tw-border-opacity));
}
.focus-within\:border-yellow-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 240, 138, var(--tw-border-opacity));
}
.focus-within\:border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 240, 138, var(--tw-border-opacity));
}
.focus-within\:border-yellow-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}
.focus-within\:border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}
.focus-within\:border-yellow-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 204, 21, var(--tw-border-opacity));
}
.focus-within\:border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 204, 21, var(--tw-border-opacity));
}
.focus-within\:border-yellow-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(234, 179, 8, var(--tw-border-opacity));
}
.focus-within\:border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(234, 179, 8, var(--tw-border-opacity));
}
.focus-within\:border-yellow-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(202, 138, 4, var(--tw-border-opacity));
}
.focus-within\:border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(202, 138, 4, var(--tw-border-opacity));
}
.focus-within\:border-yellow-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(161, 98, 7, var(--tw-border-opacity));
}
.focus-within\:border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(161, 98, 7, var(--tw-border-opacity));
}
.focus-within\:border-yellow-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(133, 77, 14, var(--tw-border-opacity));
}
.focus-within\:border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(133, 77, 14, var(--tw-border-opacity));
}
.focus-within\:border-yellow-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(113, 63, 18, var(--tw-border-opacity));
}
.focus-within\:border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(113, 63, 18, var(--tw-border-opacity));
}
.focus-within\:border-lime-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(247, 254, 231, var(--tw-border-opacity));
}
.focus-within\:border-lime-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(247, 254, 231, var(--tw-border-opacity));
}
.focus-within\:border-lime-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(236, 252, 203, var(--tw-border-opacity));
}
.focus-within\:border-lime-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 252, 203, var(--tw-border-opacity));
}
.focus-within\:border-lime-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(217, 249, 157, var(--tw-border-opacity));
}
.focus-within\:border-lime-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 249, 157, var(--tw-border-opacity));
}
.focus-within\:border-lime-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(190, 242, 100, var(--tw-border-opacity));
}
.focus-within\:border-lime-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 242, 100, var(--tw-border-opacity));
}
.focus-within\:border-lime-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(163, 230, 53, var(--tw-border-opacity));
}
.focus-within\:border-lime-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(163, 230, 53, var(--tw-border-opacity));
}
.focus-within\:border-lime-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(132, 204, 22, var(--tw-border-opacity));
}
.focus-within\:border-lime-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(132, 204, 22, var(--tw-border-opacity));
}
.focus-within\:border-lime-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(101, 163, 13, var(--tw-border-opacity));
}
.focus-within\:border-lime-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(101, 163, 13, var(--tw-border-opacity));
}
.focus-within\:border-lime-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(77, 124, 15, var(--tw-border-opacity));
}
.focus-within\:border-lime-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(77, 124, 15, var(--tw-border-opacity));
}
.focus-within\:border-lime-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(63, 98, 18, var(--tw-border-opacity));
}
.focus-within\:border-lime-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(63, 98, 18, var(--tw-border-opacity));
}
.focus-within\:border-lime-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(54, 83, 20, var(--tw-border-opacity));
}
.focus-within\:border-lime-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(54, 83, 20, var(--tw-border-opacity));
}
.focus-within\:border-green-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 244, var(--tw-border-opacity));
}
.focus-within\:border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 244, var(--tw-border-opacity));
}
.focus-within\:border-green-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(220, 252, 231, var(--tw-border-opacity));
}
.focus-within\:border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 252, 231, var(--tw-border-opacity));
}
.focus-within\:border-green-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(187, 247, 208, var(--tw-border-opacity));
}
.focus-within\:border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(187, 247, 208, var(--tw-border-opacity));
}
.focus-within\:border-green-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(134, 239, 172, var(--tw-border-opacity));
}
.focus-within\:border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(134, 239, 172, var(--tw-border-opacity));
}
.focus-within\:border-green-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(74, 222, 128, var(--tw-border-opacity));
}
.focus-within\:border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(74, 222, 128, var(--tw-border-opacity));
}
.focus-within\:border-green-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}
.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}
.focus-within\:border-green-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(22, 163, 74, var(--tw-border-opacity));
}
.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(22, 163, 74, var(--tw-border-opacity));
}
.focus-within\:border-green-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(21, 128, 61, var(--tw-border-opacity));
}
.focus-within\:border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(21, 128, 61, var(--tw-border-opacity));
}
.focus-within\:border-green-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(22, 101, 52, var(--tw-border-opacity));
}
.focus-within\:border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(22, 101, 52, var(--tw-border-opacity));
}
.focus-within\:border-green-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(20, 83, 45, var(--tw-border-opacity));
}
.focus-within\:border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(20, 83, 45, var(--tw-border-opacity));
}
.focus-within\:border-emerald-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus-within\:border-emerald-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus-within\:border-emerald-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus-within\:border-emerald-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus-within\:border-emerald-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus-within\:border-emerald-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus-within\:border-emerald-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus-within\:border-emerald-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus-within\:border-emerald-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus-within\:border-emerald-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus-within\:border-emerald-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus-within\:border-emerald-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus-within\:border-emerald-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus-within\:border-emerald-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus-within\:border-emerald-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus-within\:border-emerald-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus-within\:border-emerald-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus-within\:border-emerald-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus-within\:border-emerald-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus-within\:border-emerald-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus-within\:border-teal-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 250, var(--tw-border-opacity));
}
.focus-within\:border-teal-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 250, var(--tw-border-opacity));
}
.focus-within\:border-teal-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(204, 251, 241, var(--tw-border-opacity));
}
.focus-within\:border-teal-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(204, 251, 241, var(--tw-border-opacity));
}
.focus-within\:border-teal-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(153, 246, 228, var(--tw-border-opacity));
}
.focus-within\:border-teal-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(153, 246, 228, var(--tw-border-opacity));
}
.focus-within\:border-teal-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(94, 234, 212, var(--tw-border-opacity));
}
.focus-within\:border-teal-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(94, 234, 212, var(--tw-border-opacity));
}
.focus-within\:border-teal-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(45, 212, 191, var(--tw-border-opacity));
}
.focus-within\:border-teal-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(45, 212, 191, var(--tw-border-opacity));
}
.focus-within\:border-teal-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(20, 184, 166, var(--tw-border-opacity));
}
.focus-within\:border-teal-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(20, 184, 166, var(--tw-border-opacity));
}
.focus-within\:border-teal-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(13, 148, 136, var(--tw-border-opacity));
}
.focus-within\:border-teal-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(13, 148, 136, var(--tw-border-opacity));
}
.focus-within\:border-teal-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(15, 118, 110, var(--tw-border-opacity));
}
.focus-within\:border-teal-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(15, 118, 110, var(--tw-border-opacity));
}
.focus-within\:border-teal-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(17, 94, 89, var(--tw-border-opacity));
}
.focus-within\:border-teal-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(17, 94, 89, var(--tw-border-opacity));
}
.focus-within\:border-teal-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(19, 78, 74, var(--tw-border-opacity));
}
.focus-within\:border-teal-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(19, 78, 74, var(--tw-border-opacity));
}
.focus-within\:border-cyan-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(236, 254, 255, var(--tw-border-opacity));
}
.focus-within\:border-cyan-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 254, 255, var(--tw-border-opacity));
}
.focus-within\:border-cyan-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(207, 250, 254, var(--tw-border-opacity));
}
.focus-within\:border-cyan-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(207, 250, 254, var(--tw-border-opacity));
}
.focus-within\:border-cyan-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(165, 243, 252, var(--tw-border-opacity));
}
.focus-within\:border-cyan-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 243, 252, var(--tw-border-opacity));
}
.focus-within\:border-cyan-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(103, 232, 249, var(--tw-border-opacity));
}
.focus-within\:border-cyan-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(103, 232, 249, var(--tw-border-opacity));
}
.focus-within\:border-cyan-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(34, 211, 238, var(--tw-border-opacity));
}
.focus-within\:border-cyan-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(34, 211, 238, var(--tw-border-opacity));
}
.focus-within\:border-cyan-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(6, 182, 212, var(--tw-border-opacity));
}
.focus-within\:border-cyan-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 182, 212, var(--tw-border-opacity));
}
.focus-within\:border-cyan-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(8, 145, 178, var(--tw-border-opacity));
}
.focus-within\:border-cyan-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(8, 145, 178, var(--tw-border-opacity));
}
.focus-within\:border-cyan-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(14, 116, 144, var(--tw-border-opacity));
}
.focus-within\:border-cyan-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(14, 116, 144, var(--tw-border-opacity));
}
.focus-within\:border-cyan-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(21, 94, 117, var(--tw-border-opacity));
}
.focus-within\:border-cyan-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(21, 94, 117, var(--tw-border-opacity));
}
.focus-within\:border-cyan-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(22, 78, 99, var(--tw-border-opacity));
}
.focus-within\:border-cyan-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(22, 78, 99, var(--tw-border-opacity));
}
.focus-within\:border-sky-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(240, 249, 255, var(--tw-border-opacity));
}
.focus-within\:border-sky-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 249, 255, var(--tw-border-opacity));
}
.focus-within\:border-sky-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 254, var(--tw-border-opacity));
}
.focus-within\:border-sky-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 254, var(--tw-border-opacity));
}
.focus-within\:border-sky-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(186, 230, 253, var(--tw-border-opacity));
}
.focus-within\:border-sky-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(186, 230, 253, var(--tw-border-opacity));
}
.focus-within\:border-sky-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(125, 211, 252, var(--tw-border-opacity));
}
.focus-within\:border-sky-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(125, 211, 252, var(--tw-border-opacity));
}
.focus-within\:border-sky-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(56, 189, 248, var(--tw-border-opacity));
}
.focus-within\:border-sky-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(56, 189, 248, var(--tw-border-opacity));
}
.focus-within\:border-sky-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(14, 165, 233, var(--tw-border-opacity));
}
.focus-within\:border-sky-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(14, 165, 233, var(--tw-border-opacity));
}
.focus-within\:border-sky-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(2, 132, 199, var(--tw-border-opacity));
}
.focus-within\:border-sky-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(2, 132, 199, var(--tw-border-opacity));
}
.focus-within\:border-sky-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}
.focus-within\:border-sky-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}
.focus-within\:border-sky-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(7, 89, 133, var(--tw-border-opacity));
}
.focus-within\:border-sky-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(7, 89, 133, var(--tw-border-opacity));
}
.focus-within\:border-sky-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(12, 74, 110, var(--tw-border-opacity));
}
.focus-within\:border-sky-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(12, 74, 110, var(--tw-border-opacity));
}
.focus-within\:border-blue-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus-within\:border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus-within\:border-blue-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus-within\:border-blue-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus-within\:border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus-within\:border-blue-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus-within\:border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus-within\:border-blue-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus-within\:border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus-within\:border-blue-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus-within\:border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus-within\:border-blue-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus-within\:border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus-within\:border-blue-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus-within\:border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus-within\:border-indigo-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus-within\:border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus-within\:border-indigo-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus-within\:border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus-within\:border-indigo-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus-within\:border-indigo-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus-within\:border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus-within\:border-indigo-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus-within\:border-indigo-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus-within\:border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus-within\:border-indigo-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus-within\:border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus-within\:border-indigo-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus-within\:border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus-within\:border-violet-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus-within\:border-violet-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus-within\:border-violet-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus-within\:border-violet-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus-within\:border-violet-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus-within\:border-violet-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus-within\:border-violet-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus-within\:border-violet-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus-within\:border-violet-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus-within\:border-violet-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus-within\:border-violet-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus-within\:border-violet-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus-within\:border-violet-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus-within\:border-violet-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus-within\:border-violet-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus-within\:border-violet-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus-within\:border-violet-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus-within\:border-violet-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus-within\:border-violet-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus-within\:border-violet-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus-within\:border-purple-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 245, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 245, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(243, 232, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 232, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(233, 213, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(233, 213, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(216, 180, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(216, 180, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(192, 132, 252, var(--tw-border-opacity));
}
.focus-within\:border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(192, 132, 252, var(--tw-border-opacity));
}
.focus-within\:border-purple-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(168, 85, 247, var(--tw-border-opacity));
}
.focus-within\:border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(168, 85, 247, var(--tw-border-opacity));
}
.focus-within\:border-purple-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(147, 51, 234, var(--tw-border-opacity));
}
.focus-within\:border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 51, 234, var(--tw-border-opacity));
}
.focus-within\:border-purple-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(126, 34, 206, var(--tw-border-opacity));
}
.focus-within\:border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(126, 34, 206, var(--tw-border-opacity));
}
.focus-within\:border-purple-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(107, 33, 168, var(--tw-border-opacity));
}
.focus-within\:border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 33, 168, var(--tw-border-opacity));
}
.focus-within\:border-purple-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(88, 28, 135, var(--tw-border-opacity));
}
.focus-within\:border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(88, 28, 135, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 244, 255, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 244, 255, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 232, 255, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(250, 232, 255, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(245, 208, 254, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 208, 254, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(240, 171, 252, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(240, 171, 252, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(232, 121, 249, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(232, 121, 249, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(217, 70, 239, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 70, 239, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(192, 38, 211, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(192, 38, 211, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(162, 28, 175, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(162, 28, 175, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(134, 25, 143, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(134, 25, 143, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(112, 26, 117, var(--tw-border-opacity));
}
.focus-within\:border-fuchsia-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(112, 26, 117, var(--tw-border-opacity));
}
.focus-within\:border-pink-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus-within\:border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus-within\:border-pink-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus-within\:border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus-within\:border-pink-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus-within\:border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus-within\:border-pink-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus-within\:border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus-within\:border-pink-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus-within\:border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus-within\:border-pink-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus-within\:border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus-within\:border-pink-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus-within\:border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus-within\:border-pink-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus-within\:border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus-within\:border-pink-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus-within\:border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus-within\:border-pink-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus-within\:border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus-within\:border-rose-50[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 242, var(--tw-border-opacity));
}
.focus-within\:border-rose-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 242, var(--tw-border-opacity));
}
.focus-within\:border-rose-100[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 228, 230, var(--tw-border-opacity));
}
.focus-within\:border-rose-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 228, 230, var(--tw-border-opacity));
}
.focus-within\:border-rose-200[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(254, 205, 211, var(--tw-border-opacity));
}
.focus-within\:border-rose-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 205, 211, var(--tw-border-opacity));
}
.focus-within\:border-rose-300[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 164, 175, var(--tw-border-opacity));
}
.focus-within\:border-rose-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 164, 175, var(--tw-border-opacity));
}
.focus-within\:border-rose-400[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(251, 113, 133, var(--tw-border-opacity));
}
.focus-within\:border-rose-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 113, 133, var(--tw-border-opacity));
}
.focus-within\:border-rose-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(244, 63, 94, var(--tw-border-opacity));
}
.focus-within\:border-rose-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 63, 94, var(--tw-border-opacity));
}
.focus-within\:border-rose-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(225, 29, 72, var(--tw-border-opacity));
}
.focus-within\:border-rose-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(225, 29, 72, var(--tw-border-opacity));
}
.focus-within\:border-rose-700[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(190, 18, 60, var(--tw-border-opacity));
}
.focus-within\:border-rose-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 18, 60, var(--tw-border-opacity));
}
.focus-within\:border-rose-800[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(159, 18, 57, var(--tw-border-opacity));
}
.focus-within\:border-rose-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(159, 18, 57, var(--tw-border-opacity));
}
.focus-within\:border-rose-900[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(136, 19, 55, var(--tw-border-opacity));
}
.focus-within\:border-rose-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(136, 19, 55, var(--tw-border-opacity));
}
.focus-within\:ring-inherit[focus-within] {
  --tw-ring-color: inherit;
}
.focus-within\:ring-inherit:focus-within {
  --tw-ring-color: inherit;
}
.focus-within\:ring-current[focus-within] {
  --tw-ring-color: currentColor;
}
.focus-within\:ring-current:focus-within {
  --tw-ring-color: currentColor;
}
.focus-within\:ring-transparent[focus-within] {
  --tw-ring-color: transparent;
}
.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent;
}
.focus-within\:ring-black[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus-within\:ring-white[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 250, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 250, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 245, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 245, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 225, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 225, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 116, 139, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 116, 139, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(71, 85, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(71, 85, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 65, 85, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 65, 85, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 41, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 41, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 23, 42, var(--tw-ring-opacity));
}
.focus-within\:ring-slate-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 23, 42, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 244, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 244, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 161, 170, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 161, 170, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 91, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 91, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 63, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 63, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 39, 42, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 39, 42, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 24, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-zinc-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 24, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 229, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 229, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 163, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 163, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 82, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 82, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 64, 64, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 64, 64, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(23, 23, 23, var(--tw-ring-opacity));
}
.focus-within\:ring-neutral-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(23, 23, 23, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 244, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 244, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(231, 229, 228, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(231, 229, 228, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(214, 211, 209, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(214, 211, 209, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 162, 158, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 162, 158, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 113, 108, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 113, 108, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(87, 83, 78, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(87, 83, 78, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 64, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 64, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 37, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 37, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 25, 23, var(--tw-ring-opacity));
}
.focus-within\:ring-stone-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 25, 23, var(--tw-ring-opacity));
}
.focus-within\:ring-red-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-red-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-red-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus-within\:ring-red-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus-within\:ring-red-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-red-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-red-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus-within\:ring-red-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus-within\:ring-red-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus-within\:ring-red-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus-within\:ring-red-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus-within\:ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus-within\:ring-red-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-red-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-red-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus-within\:ring-red-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus-within\:ring-red-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-red-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-red-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus-within\:ring-red-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 247, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 247, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 237, 213, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 237, 213, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 215, 170, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 215, 170, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 116, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 116, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 146, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 146, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 115, 22, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 115, 22, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 88, 12, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 88, 12, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 65, 12, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 65, 12, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(154, 52, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(154, 52, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 45, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-orange-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 45, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-amber-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 252, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 252, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 249, 195, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 249, 195, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 240, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 240, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 224, 71, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 224, 71, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 204, 21, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 204, 21, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 138, 4, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 138, 4, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 98, 7, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 98, 7, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(133, 77, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(133, 77, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 63, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 63, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 254, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 254, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 252, 203, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 252, 203, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 249, 157, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 249, 157, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 242, 100, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 242, 100, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 230, 53, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 230, 53, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 204, 22, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 204, 22, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 163, 13, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 163, 13, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 124, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 124, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 98, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 98, 18, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(54, 83, 20, var(--tw-ring-opacity));
}
.focus-within\:ring-lime-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(54, 83, 20, var(--tw-ring-opacity));
}
.focus-within\:ring-green-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 244, var(--tw-ring-opacity));
}
.focus-within\:ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 244, var(--tw-ring-opacity));
}
.focus-within\:ring-green-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 252, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 252, 231, var(--tw-ring-opacity));
}
.focus-within\:ring-green-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 247, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 247, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-green-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 239, 172, var(--tw-ring-opacity));
}
.focus-within\:ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 239, 172, var(--tw-ring-opacity));
}
.focus-within\:ring-green-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 222, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 222, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-green-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}
.focus-within\:ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}
.focus-within\:ring-green-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 163, 74, var(--tw-ring-opacity));
}
.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 163, 74, var(--tw-ring-opacity));
}
.focus-within\:ring-green-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 128, 61, var(--tw-ring-opacity));
}
.focus-within\:ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 128, 61, var(--tw-ring-opacity));
}
.focus-within\:ring-green-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 101, 52, var(--tw-ring-opacity));
}
.focus-within\:ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 101, 52, var(--tw-ring-opacity));
}
.focus-within\:ring-green-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 83, 45, var(--tw-ring-opacity));
}
.focus-within\:ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 83, 45, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-emerald-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 251, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 251, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 246, 228, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 246, 228, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 234, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 234, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 212, 191, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 212, 191, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 184, 166, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 184, 166, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 148, 136, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 148, 136, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 118, 110, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 118, 110, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 94, 89, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 94, 89, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(19, 78, 74, var(--tw-ring-opacity));
}
.focus-within\:ring-teal-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(19, 78, 74, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 254, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 254, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 250, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 250, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 243, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 243, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 232, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 232, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 211, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 211, 238, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 182, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 182, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(8, 145, 178, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(8, 145, 178, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 116, 144, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 116, 144, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 94, 117, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 94, 117, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 78, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-cyan-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 78, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 249, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 249, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 230, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 230, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(125, 211, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(125, 211, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 189, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 189, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 132, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 132, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 105, 161, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 105, 161, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(7, 89, 133, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(7, 89, 133, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(12, 74, 110, var(--tw-ring-opacity));
}
.focus-within\:ring-sky-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(12, 74, 110, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus-within\:ring-violet-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 245, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 245, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 232, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 232, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 213, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 213, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 180, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 180, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 132, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 132, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 85, 247, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 85, 247, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 51, 234, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 51, 234, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 34, 206, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 34, 206, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 33, 168, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 33, 168, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(88, 28, 135, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(88, 28, 135, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 244, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 244, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 232, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 232, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 208, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 208, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 171, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 171, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 121, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 121, 249, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 70, 239, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 70, 239, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 38, 211, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 38, 211, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(162, 28, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(162, 28, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 25, 143, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 25, 143, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(112, 26, 117, var(--tw-ring-opacity));
}
.focus-within\:ring-fuchsia-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(112, 26, 117, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-50[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-100[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 228, 230, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 228, 230, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-200[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 205, 211, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 205, 211, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-300[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 164, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 164, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-400[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 113, 133, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 113, 133, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-500[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 63, 94, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 63, 94, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 29, 72, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 29, 72, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-700[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 18, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 18, 60, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-800[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 18, 57, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 18, 57, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-900[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 19, 55, var(--tw-ring-opacity));
}
.focus-within\:ring-rose-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 19, 55, var(--tw-ring-opacity));
}
.hover\:grid-cols-1:hover {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.hover\:border-cyan-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 182, 212, var(--tw-border-opacity));
}
.hover\:bg-inherit:hover {
  background-color: inherit;
}
.hover\:bg-current:hover {
  background-color: currentColor;
}
.hover\:bg-transparent:hover {
  background-color: initial;
}
.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.hover\:bg-slate-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.hover\:bg-slate-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.hover\:bg-zinc-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.hover\:bg-zinc-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.hover\:bg-zinc-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.hover\:bg-zinc-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.hover\:bg-zinc-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.hover\:bg-zinc-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.hover\:bg-zinc-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.hover\:bg-zinc-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.hover\:bg-zinc-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.hover\:bg-zinc-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.hover\:bg-neutral-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.hover\:bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.hover\:bg-neutral-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.hover\:bg-neutral-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.hover\:bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.hover\:bg-neutral-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-neutral-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.hover\:bg-stone-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.hover\:bg-stone-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.hover\:bg-stone-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.hover\:bg-stone-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.hover\:bg-stone-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.hover\:bg-stone-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.hover\:bg-stone-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.hover\:bg-stone-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.hover\:bg-stone-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.hover\:bg-stone-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.hover\:bg-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.hover\:bg-amber-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.hover\:bg-amber-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.hover\:bg-amber-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.hover\:bg-amber-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.hover\:bg-amber-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.hover\:bg-amber-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.hover\:bg-lime-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.hover\:bg-lime-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.hover\:bg-lime-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.hover\:bg-lime-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.hover\:bg-lime-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.hover\:bg-lime-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.hover\:bg-lime-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}
.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}
.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}
.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.hover\:bg-emerald-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.hover\:bg-emerald-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.hover\:bg-emerald-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.hover\:bg-emerald-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.hover\:bg-emerald-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.hover\:bg-emerald-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.hover\:bg-emerald-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.hover\:bg-emerald-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.hover\:bg-emerald-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.hover\:bg-emerald-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.hover\:bg-teal-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.hover\:bg-teal-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.hover\:bg-teal-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.hover\:bg-teal-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.hover\:bg-teal-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.hover\:bg-teal-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.hover\:bg-cyan-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.hover\:bg-cyan-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.hover\:bg-cyan-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.hover\:bg-cyan-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.hover\:bg-cyan-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.hover\:bg-cyan-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.hover\:bg-cyan-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.hover\:bg-cyan-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.hover\:bg-sky-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.hover\:bg-sky-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.hover\:bg-sky-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.hover\:bg-sky-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.hover\:bg-sky-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.hover\:bg-violet-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.hover\:bg-violet-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.hover\:bg-violet-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.hover\:bg-violet-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.hover\:bg-violet-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.hover\:bg-violet-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.hover\:bg-violet-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.hover\:bg-violet-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.hover\:bg-violet-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.hover\:bg-violet-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.hover\:bg-fuchsia-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.hover\:bg-rose-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.hover\:bg-rose-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.hover\:bg-rose-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.hover\:bg-rose-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.hover\:bg-rose-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.hover\:bg-rose-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.hover\:bg-rose-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.hover\:bg-rose-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.hover\:bg-rose-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.hover\:bg-rose-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.hover\:text-inherit:hover {
  color: inherit;
}
.hover\:text-current:hover {
  color: currentColor;
}
.hover\:text-transparent:hover {
  color: transparent;
}
.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:text-slate-50:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 250, 252, var(--tw-text-opacity));
}
.hover\:text-slate-100:hover {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}
.hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}
.hover\:text-slate-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}
.hover\:text-slate-400:hover {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.hover\:text-slate-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.hover\:text-slate-600:hover {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}
.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}
.hover\:text-slate-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}
.hover\:text-slate-900:hover {
  --tw-text-opacity: 1;
  color: rgba(15, 23, 42, var(--tw-text-opacity));
}
.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.hover\:text-zinc-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.hover\:text-zinc-100:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 244, 245, var(--tw-text-opacity));
}
.hover\:text-zinc-200:hover {
  --tw-text-opacity: 1;
  color: rgba(228, 228, 231, var(--tw-text-opacity));
}
.hover\:text-zinc-300:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.hover\:text-zinc-400:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 161, 170, var(--tw-text-opacity));
}
.hover\:text-zinc-500:hover {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}
.hover\:text-zinc-600:hover {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.hover\:text-zinc-700:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 63, 70, var(--tw-text-opacity));
}
.hover\:text-zinc-800:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.hover\:text-zinc-900:hover {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}
.hover\:text-neutral-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.hover\:text-neutral-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}
.hover\:text-neutral-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}
.hover\:text-neutral-300:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}
.hover\:text-neutral-400:hover {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
.hover\:text-neutral-500:hover {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
.hover\:text-neutral-600:hover {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.hover\:text-neutral-700:hover {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}
.hover\:text-neutral-800:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}
.hover\:text-neutral-900:hover {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}
.hover\:text-stone-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 249, var(--tw-text-opacity));
}
.hover\:text-stone-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 244, var(--tw-text-opacity));
}
.hover\:text-stone-200:hover {
  --tw-text-opacity: 1;
  color: rgba(231, 229, 228, var(--tw-text-opacity));
}
.hover\:text-stone-300:hover {
  --tw-text-opacity: 1;
  color: rgba(214, 211, 209, var(--tw-text-opacity));
}
.hover\:text-stone-400:hover {
  --tw-text-opacity: 1;
  color: rgba(168, 162, 158, var(--tw-text-opacity));
}
.hover\:text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 113, 108, var(--tw-text-opacity));
}
.hover\:text-stone-600:hover {
  --tw-text-opacity: 1;
  color: rgba(87, 83, 78, var(--tw-text-opacity));
}
.hover\:text-stone-700:hover {
  --tw-text-opacity: 1;
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}
.hover\:text-stone-800:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 36, var(--tw-text-opacity));
}
.hover\:text-stone-900:hover {
  --tw-text-opacity: 1;
  color: rgba(28, 25, 23, var(--tw-text-opacity));
}
.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.hover\:text-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 247, 237, var(--tw-text-opacity));
}
.hover\:text-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 237, 213, var(--tw-text-opacity));
}
.hover\:text-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 215, 170, var(--tw-text-opacity));
}
.hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 186, 116, var(--tw-text-opacity));
}
.hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 146, 60, var(--tw-text-opacity));
}
.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 88, 12, var(--tw-text-opacity));
}
.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(194, 65, 12, var(--tw-text-opacity));
}
.hover\:text-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgba(154, 52, 18, var(--tw-text-opacity));
}
.hover\:text-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 45, 18, var(--tw-text-opacity));
}
.hover\:text-amber-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.hover\:text-amber-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.hover\:text-amber-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.hover\:text-amber-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.hover\:text-amber-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.hover\:text-amber-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.hover\:text-amber-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.hover\:text-amber-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.hover\:text-amber-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 249, 195, var(--tw-text-opacity));
}
.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 240, 138, var(--tw-text-opacity));
}
.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 224, 71, var(--tw-text-opacity));
}
.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}
.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}
.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(202, 138, 4, var(--tw-text-opacity));
}
.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}
.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}
.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(113, 63, 18, var(--tw-text-opacity));
}
.hover\:text-lime-50:hover {
  --tw-text-opacity: 1;
  color: rgba(247, 254, 231, var(--tw-text-opacity));
}
.hover\:text-lime-100:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 252, 203, var(--tw-text-opacity));
}
.hover\:text-lime-200:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 249, 157, var(--tw-text-opacity));
}
.hover\:text-lime-300:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 242, 100, var(--tw-text-opacity));
}
.hover\:text-lime-400:hover {
  --tw-text-opacity: 1;
  color: rgba(163, 230, 53, var(--tw-text-opacity));
}
.hover\:text-lime-500:hover {
  --tw-text-opacity: 1;
  color: rgba(132, 204, 22, var(--tw-text-opacity));
}
.hover\:text-lime-600:hover {
  --tw-text-opacity: 1;
  color: rgba(101, 163, 13, var(--tw-text-opacity));
}
.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgba(77, 124, 15, var(--tw-text-opacity));
}
.hover\:text-lime-800:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 98, 18, var(--tw-text-opacity));
}
.hover\:text-lime-900:hover {
  --tw-text-opacity: 1;
  color: rgba(54, 83, 20, var(--tw-text-opacity));
}
.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 244, var(--tw-text-opacity));
}
.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 252, 231, var(--tw-text-opacity));
}
.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}
.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}
.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}
.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(21, 128, 61, var(--tw-text-opacity));
}
.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 101, 52, var(--tw-text-opacity));
}
.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(20, 83, 45, var(--tw-text-opacity));
}
.hover\:text-emerald-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.hover\:text-emerald-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.hover\:text-emerald-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.hover\:text-emerald-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.hover\:text-emerald-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.hover\:text-emerald-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.hover\:text-emerald-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.hover\:text-emerald-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.hover\:text-emerald-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.hover\:text-emerald-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.hover\:text-teal-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 250, var(--tw-text-opacity));
}
.hover\:text-teal-100:hover {
  --tw-text-opacity: 1;
  color: rgba(204, 251, 241, var(--tw-text-opacity));
}
.hover\:text-teal-200:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 246, 228, var(--tw-text-opacity));
}
.hover\:text-teal-300:hover {
  --tw-text-opacity: 1;
  color: rgba(94, 234, 212, var(--tw-text-opacity));
}
.hover\:text-teal-400:hover {
  --tw-text-opacity: 1;
  color: rgba(45, 212, 191, var(--tw-text-opacity));
}
.hover\:text-teal-500:hover {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}
.hover\:text-teal-600:hover {
  --tw-text-opacity: 1;
  color: rgba(13, 148, 136, var(--tw-text-opacity));
}
.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgba(15, 118, 110, var(--tw-text-opacity));
}
.hover\:text-teal-800:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 94, 89, var(--tw-text-opacity));
}
.hover\:text-teal-900:hover {
  --tw-text-opacity: 1;
  color: rgba(19, 78, 74, var(--tw-text-opacity));
}
.hover\:text-cyan-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 254, 255, var(--tw-text-opacity));
}
.hover\:text-cyan-100:hover {
  --tw-text-opacity: 1;
  color: rgba(207, 250, 254, var(--tw-text-opacity));
}
.hover\:text-cyan-200:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 243, 252, var(--tw-text-opacity));
}
.hover\:text-cyan-300:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 232, 249, var(--tw-text-opacity));
}
.hover\:text-cyan-400:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 211, 238, var(--tw-text-opacity));
}
.hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 182, 212, var(--tw-text-opacity));
}
.hover\:text-cyan-600:hover {
  --tw-text-opacity: 1;
  color: rgba(8, 145, 178, var(--tw-text-opacity));
}
.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 116, 144, var(--tw-text-opacity));
}
.hover\:text-cyan-800:hover {
  --tw-text-opacity: 1;
  color: rgba(21, 94, 117, var(--tw-text-opacity));
}
.hover\:text-cyan-900:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 78, 99, var(--tw-text-opacity));
}
.hover\:text-sky-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 249, 255, var(--tw-text-opacity));
}
.hover\:text-sky-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 254, var(--tw-text-opacity));
}
.hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgba(186, 230, 253, var(--tw-text-opacity));
}
.hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}
.hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}
.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}
.hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}
.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgba(3, 105, 161, var(--tw-text-opacity));
}
.hover\:text-sky-800:hover {
  --tw-text-opacity: 1;
  color: rgba(7, 89, 133, var(--tw-text-opacity));
}
.hover\:text-sky-900:hover {
  --tw-text-opacity: 1;
  color: rgba(12, 74, 110, var(--tw-text-opacity));
}
.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.hover\:text-violet-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.hover\:text-violet-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.hover\:text-violet-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.hover\:text-violet-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.hover\:text-violet-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.hover\:text-violet-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.hover\:text-violet-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.hover\:text-violet-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.hover\:text-violet-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 245, 255, var(--tw-text-opacity));
}
.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 232, 255, var(--tw-text-opacity));
}
.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(233, 213, 255, var(--tw-text-opacity));
}
.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(216, 180, 254, var(--tw-text-opacity));
}
.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(192, 132, 252, var(--tw-text-opacity));
}
.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(168, 85, 247, var(--tw-text-opacity));
}
.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 51, 234, var(--tw-text-opacity));
}
.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(126, 34, 206, var(--tw-text-opacity));
}
.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 33, 168, var(--tw-text-opacity));
}
.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(88, 28, 135, var(--tw-text-opacity));
}
.hover\:text-fuchsia-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 244, 255, var(--tw-text-opacity));
}
.hover\:text-fuchsia-100:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 232, 255, var(--tw-text-opacity));
}
.hover\:text-fuchsia-200:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 208, 254, var(--tw-text-opacity));
}
.hover\:text-fuchsia-300:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 171, 252, var(--tw-text-opacity));
}
.hover\:text-fuchsia-400:hover {
  --tw-text-opacity: 1;
  color: rgba(232, 121, 249, var(--tw-text-opacity));
}
.hover\:text-fuchsia-500:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 70, 239, var(--tw-text-opacity));
}
.hover\:text-fuchsia-600:hover {
  --tw-text-opacity: 1;
  color: rgba(192, 38, 211, var(--tw-text-opacity));
}
.hover\:text-fuchsia-700:hover {
  --tw-text-opacity: 1;
  color: rgba(162, 28, 175, var(--tw-text-opacity));
}
.hover\:text-fuchsia-800:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 25, 143, var(--tw-text-opacity));
}
.hover\:text-fuchsia-900:hover {
  --tw-text-opacity: 1;
  color: rgba(112, 26, 117, var(--tw-text-opacity));
}
.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.hover\:text-rose-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 242, var(--tw-text-opacity));
}
.hover\:text-rose-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 228, 230, var(--tw-text-opacity));
}
.hover\:text-rose-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 205, 211, var(--tw-text-opacity));
}
.hover\:text-rose-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 164, 175, var(--tw-text-opacity));
}
.hover\:text-rose-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 113, 133, var(--tw-text-opacity));
}
.hover\:text-rose-500:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 63, 94, var(--tw-text-opacity));
}
.hover\:text-rose-600:hover {
  --tw-text-opacity: 1;
  color: rgba(225, 29, 72, var(--tw-text-opacity));
}
.hover\:text-rose-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 18, 60, var(--tw-text-opacity));
}
.hover\:text-rose-800:hover {
  --tw-text-opacity: 1;
  color: rgba(159, 18, 57, var(--tw-text-opacity));
}
.hover\:text-rose-900:hover {
  --tw-text-opacity: 1;
  color: rgba(136, 19, 55, var(--tw-text-opacity));
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.hover\:ring-1:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}
.hover\:ring-inherit:hover {
  --tw-ring-color: inherit;
}
.hover\:ring-current:hover {
  --tw-ring-color: currentColor;
}
.hover\:ring-transparent:hover {
  --tw-ring-color: transparent;
}
.hover\:ring-black:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.hover\:ring-white:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.hover\:ring-slate-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 250, 252, var(--tw-ring-opacity));
}
.hover\:ring-slate-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 245, 249, var(--tw-ring-opacity));
}
.hover\:ring-slate-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}
.hover\:ring-slate-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 225, var(--tw-ring-opacity));
}
.hover\:ring-slate-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
}
.hover\:ring-slate-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 116, 139, var(--tw-ring-opacity));
}
.hover\:ring-slate-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(71, 85, 105, var(--tw-ring-opacity));
}
.hover\:ring-slate-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 65, 85, var(--tw-ring-opacity));
}
.hover\:ring-slate-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 41, 59, var(--tw-ring-opacity));
}
.hover\:ring-slate-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 23, 42, var(--tw-ring-opacity));
}
.hover\:ring-gray-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.hover\:ring-gray-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.hover\:ring-gray-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.hover\:ring-gray-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.hover\:ring-gray-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.hover\:ring-gray-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.hover\:ring-gray-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.hover\:ring-gray-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.hover\:ring-gray-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.hover\:ring-gray-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.hover\:ring-zinc-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.hover\:ring-zinc-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 244, 245, var(--tw-ring-opacity));
}
.hover\:ring-zinc-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.hover\:ring-zinc-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 216, var(--tw-ring-opacity));
}
.hover\:ring-zinc-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 161, 170, var(--tw-ring-opacity));
}
.hover\:ring-zinc-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}
.hover\:ring-zinc-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 91, var(--tw-ring-opacity));
}
.hover\:ring-zinc-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 63, 70, var(--tw-ring-opacity));
}
.hover\:ring-zinc-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 39, 42, var(--tw-ring-opacity));
}
.hover\:ring-zinc-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 24, 27, var(--tw-ring-opacity));
}
.hover\:ring-neutral-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.hover\:ring-neutral-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity));
}
.hover\:ring-neutral-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 229, 229, var(--tw-ring-opacity));
}
.hover\:ring-neutral-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}
.hover\:ring-neutral-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 163, 163, var(--tw-ring-opacity));
}
.hover\:ring-neutral-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}
.hover\:ring-neutral-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 82, var(--tw-ring-opacity));
}
.hover\:ring-neutral-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 64, 64, var(--tw-ring-opacity));
}
.hover\:ring-neutral-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 38, 38, var(--tw-ring-opacity));
}
.hover\:ring-neutral-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(23, 23, 23, var(--tw-ring-opacity));
}
.hover\:ring-stone-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 249, var(--tw-ring-opacity));
}
.hover\:ring-stone-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 244, var(--tw-ring-opacity));
}
.hover\:ring-stone-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(231, 229, 228, var(--tw-ring-opacity));
}
.hover\:ring-stone-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(214, 211, 209, var(--tw-ring-opacity));
}
.hover\:ring-stone-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 162, 158, var(--tw-ring-opacity));
}
.hover\:ring-stone-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 113, 108, var(--tw-ring-opacity));
}
.hover\:ring-stone-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(87, 83, 78, var(--tw-ring-opacity));
}
.hover\:ring-stone-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 64, 60, var(--tw-ring-opacity));
}
.hover\:ring-stone-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 37, 36, var(--tw-ring-opacity));
}
.hover\:ring-stone-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 25, 23, var(--tw-ring-opacity));
}
.hover\:ring-red-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.hover\:ring-red-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.hover\:ring-red-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.hover\:ring-red-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.hover\:ring-red-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.hover\:ring-red-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.hover\:ring-red-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.hover\:ring-red-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.hover\:ring-red-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.hover\:ring-red-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.hover\:ring-orange-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 247, 237, var(--tw-ring-opacity));
}
.hover\:ring-orange-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 237, 213, var(--tw-ring-opacity));
}
.hover\:ring-orange-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 215, 170, var(--tw-ring-opacity));
}
.hover\:ring-orange-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 116, var(--tw-ring-opacity));
}
.hover\:ring-orange-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 146, 60, var(--tw-ring-opacity));
}
.hover\:ring-orange-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 115, 22, var(--tw-ring-opacity));
}
.hover\:ring-orange-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 88, 12, var(--tw-ring-opacity));
}
.hover\:ring-orange-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 65, 12, var(--tw-ring-opacity));
}
.hover\:ring-orange-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(154, 52, 18, var(--tw-ring-opacity));
}
.hover\:ring-orange-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 45, 18, var(--tw-ring-opacity));
}
.hover\:ring-amber-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.hover\:ring-amber-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.hover\:ring-amber-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.hover\:ring-amber-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.hover\:ring-amber-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.hover\:ring-amber-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.hover\:ring-amber-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.hover\:ring-amber-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.hover\:ring-amber-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.hover\:ring-amber-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.hover\:ring-yellow-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 252, 232, var(--tw-ring-opacity));
}
.hover\:ring-yellow-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 249, 195, var(--tw-ring-opacity));
}
.hover\:ring-yellow-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 240, 138, var(--tw-ring-opacity));
}
.hover\:ring-yellow-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 224, 71, var(--tw-ring-opacity));
}
.hover\:ring-yellow-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 204, 21, var(--tw-ring-opacity));
}
.hover\:ring-yellow-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.hover\:ring-yellow-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 138, 4, var(--tw-ring-opacity));
}
.hover\:ring-yellow-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 98, 7, var(--tw-ring-opacity));
}
.hover\:ring-yellow-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(133, 77, 14, var(--tw-ring-opacity));
}
.hover\:ring-yellow-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 63, 18, var(--tw-ring-opacity));
}
.hover\:ring-lime-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 254, 231, var(--tw-ring-opacity));
}
.hover\:ring-lime-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 252, 203, var(--tw-ring-opacity));
}
.hover\:ring-lime-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 249, 157, var(--tw-ring-opacity));
}
.hover\:ring-lime-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 242, 100, var(--tw-ring-opacity));
}
.hover\:ring-lime-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 230, 53, var(--tw-ring-opacity));
}
.hover\:ring-lime-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 204, 22, var(--tw-ring-opacity));
}
.hover\:ring-lime-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 163, 13, var(--tw-ring-opacity));
}
.hover\:ring-lime-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 124, 15, var(--tw-ring-opacity));
}
.hover\:ring-lime-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 98, 18, var(--tw-ring-opacity));
}
.hover\:ring-lime-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(54, 83, 20, var(--tw-ring-opacity));
}
.hover\:ring-green-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 244, var(--tw-ring-opacity));
}
.hover\:ring-green-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 252, 231, var(--tw-ring-opacity));
}
.hover\:ring-green-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 247, 208, var(--tw-ring-opacity));
}
.hover\:ring-green-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 239, 172, var(--tw-ring-opacity));
}
.hover\:ring-green-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 222, 128, var(--tw-ring-opacity));
}
.hover\:ring-green-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}
.hover\:ring-green-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 163, 74, var(--tw-ring-opacity));
}
.hover\:ring-green-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 128, 61, var(--tw-ring-opacity));
}
.hover\:ring-green-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 101, 52, var(--tw-ring-opacity));
}
.hover\:ring-green-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 83, 45, var(--tw-ring-opacity));
}
.hover\:ring-emerald-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.hover\:ring-emerald-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.hover\:ring-emerald-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.hover\:ring-emerald-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.hover\:ring-emerald-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.hover\:ring-emerald-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.hover\:ring-emerald-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.hover\:ring-emerald-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.hover\:ring-emerald-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.hover\:ring-emerald-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.hover\:ring-teal-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 250, var(--tw-ring-opacity));
}
.hover\:ring-teal-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 251, 241, var(--tw-ring-opacity));
}
.hover\:ring-teal-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 246, 228, var(--tw-ring-opacity));
}
.hover\:ring-teal-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 234, 212, var(--tw-ring-opacity));
}
.hover\:ring-teal-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 212, 191, var(--tw-ring-opacity));
}
.hover\:ring-teal-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 184, 166, var(--tw-ring-opacity));
}
.hover\:ring-teal-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 148, 136, var(--tw-ring-opacity));
}
.hover\:ring-teal-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 118, 110, var(--tw-ring-opacity));
}
.hover\:ring-teal-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 94, 89, var(--tw-ring-opacity));
}
.hover\:ring-teal-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(19, 78, 74, var(--tw-ring-opacity));
}
.hover\:ring-cyan-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 254, 255, var(--tw-ring-opacity));
}
.hover\:ring-cyan-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 250, 254, var(--tw-ring-opacity));
}
.hover\:ring-cyan-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 243, 252, var(--tw-ring-opacity));
}
.hover\:ring-cyan-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 232, 249, var(--tw-ring-opacity));
}
.hover\:ring-cyan-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 211, 238, var(--tw-ring-opacity));
}
.hover\:ring-cyan-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 182, 212, var(--tw-ring-opacity));
}
.hover\:ring-cyan-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(8, 145, 178, var(--tw-ring-opacity));
}
.hover\:ring-cyan-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 116, 144, var(--tw-ring-opacity));
}
.hover\:ring-cyan-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 94, 117, var(--tw-ring-opacity));
}
.hover\:ring-cyan-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 78, 99, var(--tw-ring-opacity));
}
.hover\:ring-sky-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 249, 255, var(--tw-ring-opacity));
}
.hover\:ring-sky-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 254, var(--tw-ring-opacity));
}
.hover\:ring-sky-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 230, 253, var(--tw-ring-opacity));
}
.hover\:ring-sky-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(125, 211, 252, var(--tw-ring-opacity));
}
.hover\:ring-sky-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 189, 248, var(--tw-ring-opacity));
}
.hover\:ring-sky-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}
.hover\:ring-sky-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 132, 199, var(--tw-ring-opacity));
}
.hover\:ring-sky-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 105, 161, var(--tw-ring-opacity));
}
.hover\:ring-sky-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(7, 89, 133, var(--tw-ring-opacity));
}
.hover\:ring-sky-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(12, 74, 110, var(--tw-ring-opacity));
}
.hover\:ring-blue-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.hover\:ring-blue-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.hover\:ring-blue-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.hover\:ring-blue-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.hover\:ring-blue-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.hover\:ring-blue-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.hover\:ring-blue-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.hover\:ring-blue-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.hover\:ring-blue-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.hover\:ring-blue-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.hover\:ring-indigo-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.hover\:ring-indigo-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.hover\:ring-indigo-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.hover\:ring-indigo-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.hover\:ring-indigo-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.hover\:ring-indigo-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.hover\:ring-indigo-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.hover\:ring-indigo-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.hover\:ring-indigo-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.hover\:ring-indigo-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.hover\:ring-violet-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.hover\:ring-violet-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.hover\:ring-violet-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.hover\:ring-violet-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.hover\:ring-violet-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.hover\:ring-violet-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.hover\:ring-violet-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.hover\:ring-violet-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.hover\:ring-violet-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.hover\:ring-violet-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.hover\:ring-purple-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 245, 255, var(--tw-ring-opacity));
}
.hover\:ring-purple-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 232, 255, var(--tw-ring-opacity));
}
.hover\:ring-purple-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 213, 255, var(--tw-ring-opacity));
}
.hover\:ring-purple-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 180, 254, var(--tw-ring-opacity));
}
.hover\:ring-purple-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 132, 252, var(--tw-ring-opacity));
}
.hover\:ring-purple-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 85, 247, var(--tw-ring-opacity));
}
.hover\:ring-purple-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 51, 234, var(--tw-ring-opacity));
}
.hover\:ring-purple-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 34, 206, var(--tw-ring-opacity));
}
.hover\:ring-purple-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 33, 168, var(--tw-ring-opacity));
}
.hover\:ring-purple-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(88, 28, 135, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 244, 255, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 232, 255, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 208, 254, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 171, 252, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 121, 249, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 70, 239, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 38, 211, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(162, 28, 175, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 25, 143, var(--tw-ring-opacity));
}
.hover\:ring-fuchsia-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(112, 26, 117, var(--tw-ring-opacity));
}
.hover\:ring-pink-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.hover\:ring-pink-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.hover\:ring-pink-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.hover\:ring-pink-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.hover\:ring-pink-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.hover\:ring-pink-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.hover\:ring-pink-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.hover\:ring-pink-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.hover\:ring-pink-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.hover\:ring-pink-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.hover\:ring-rose-50:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 242, var(--tw-ring-opacity));
}
.hover\:ring-rose-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 228, 230, var(--tw-ring-opacity));
}
.hover\:ring-rose-200:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 205, 211, var(--tw-ring-opacity));
}
.hover\:ring-rose-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 164, 175, var(--tw-ring-opacity));
}
.hover\:ring-rose-400:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 113, 133, var(--tw-ring-opacity));
}
.hover\:ring-rose-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 63, 94, var(--tw-ring-opacity));
}
.hover\:ring-rose-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 29, 72, var(--tw-ring-opacity));
}
.hover\:ring-rose-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 18, 60, var(--tw-ring-opacity));
}
.hover\:ring-rose-800:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 18, 57, var(--tw-ring-opacity));
}
.hover\:ring-rose-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 19, 55, var(--tw-ring-opacity));
}
.focus\:border-inherit:focus {
  border-color: inherit;
}
.focus\:border-current:focus {
  border-color: currentColor;
}
.focus\:border-transparent:focus {
  border-color: transparent;
}
.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus\:border-slate-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}
.focus\:border-slate-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}
.focus\:border-slate-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}
.focus\:border-slate-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}
.focus\:border-slate-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(148, 163, 184, var(--tw-border-opacity));
}
.focus\:border-slate-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}
.focus\:border-slate-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}
.focus\:border-slate-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(51, 65, 85, var(--tw-border-opacity));
}
.focus\:border-slate-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 41, 59, var(--tw-border-opacity));
}
.focus\:border-slate-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(15, 23, 42, var(--tw-border-opacity));
}
.focus\:border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.focus\:border-zinc-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus\:border-zinc-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 245, var(--tw-border-opacity));
}
.focus\:border-zinc-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}
.focus\:border-zinc-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}
.focus\:border-zinc-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}
.focus\:border-zinc-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(113, 113, 122, var(--tw-border-opacity));
}
.focus\:border-zinc-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 91, var(--tw-border-opacity));
}
.focus\:border-zinc-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 63, 70, var(--tw-border-opacity));
}
.focus\:border-zinc-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(39, 39, 42, var(--tw-border-opacity));
}
.focus\:border-zinc-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(24, 24, 27, var(--tw-border-opacity));
}
.focus\:border-neutral-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.focus\:border-neutral-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}
.focus\:border-neutral-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}
.focus\:border-neutral-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
}
.focus\:border-neutral-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}
.focus\:border-neutral-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}
.focus\:border-neutral-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(82, 82, 82, var(--tw-border-opacity));
}
.focus\:border-neutral-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}
.focus\:border-neutral-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
}
.focus\:border-neutral-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(23, 23, 23, var(--tw-border-opacity));
}
.focus\:border-stone-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 249, var(--tw-border-opacity));
}
.focus\:border-stone-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 244, var(--tw-border-opacity));
}
.focus\:border-stone-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(231, 229, 228, var(--tw-border-opacity));
}
.focus\:border-stone-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(214, 211, 209, var(--tw-border-opacity));
}
.focus\:border-stone-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(168, 162, 158, var(--tw-border-opacity));
}
.focus\:border-stone-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 113, 108, var(--tw-border-opacity));
}
.focus\:border-stone-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(87, 83, 78, var(--tw-border-opacity));
}
.focus\:border-stone-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(68, 64, 60, var(--tw-border-opacity));
}
.focus\:border-stone-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 36, var(--tw-border-opacity));
}
.focus\:border-stone-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(28, 25, 23, var(--tw-border-opacity));
}
.focus\:border-red-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus\:border-red-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus\:border-red-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus\:border-red-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus\:border-red-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus\:border-red-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus\:border-orange-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 247, 237, var(--tw-border-opacity));
}
.focus\:border-orange-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 237, 213, var(--tw-border-opacity));
}
.focus\:border-orange-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 215, 170, var(--tw-border-opacity));
}
.focus\:border-orange-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 186, 116, var(--tw-border-opacity));
}
.focus\:border-orange-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 146, 60, var(--tw-border-opacity));
}
.focus\:border-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 115, 22, var(--tw-border-opacity));
}
.focus\:border-orange-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(234, 88, 12, var(--tw-border-opacity));
}
.focus\:border-orange-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(194, 65, 12, var(--tw-border-opacity));
}
.focus\:border-orange-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(154, 52, 18, var(--tw-border-opacity));
}
.focus\:border-orange-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 45, 18, var(--tw-border-opacity));
}
.focus\:border-amber-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus\:border-amber-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus\:border-amber-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus\:border-amber-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus\:border-amber-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus\:border-amber-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus\:border-amber-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus\:border-amber-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus\:border-amber-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus\:border-amber-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus\:border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 252, 232, var(--tw-border-opacity));
}
.focus\:border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 249, 195, var(--tw-border-opacity));
}
.focus\:border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 240, 138, var(--tw-border-opacity));
}
.focus\:border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}
.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 204, 21, var(--tw-border-opacity));
}
.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(234, 179, 8, var(--tw-border-opacity));
}
.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(202, 138, 4, var(--tw-border-opacity));
}
.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(161, 98, 7, var(--tw-border-opacity));
}
.focus\:border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(133, 77, 14, var(--tw-border-opacity));
}
.focus\:border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(113, 63, 18, var(--tw-border-opacity));
}
.focus\:border-lime-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(247, 254, 231, var(--tw-border-opacity));
}
.focus\:border-lime-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 252, 203, var(--tw-border-opacity));
}
.focus\:border-lime-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 249, 157, var(--tw-border-opacity));
}
.focus\:border-lime-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 242, 100, var(--tw-border-opacity));
}
.focus\:border-lime-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(163, 230, 53, var(--tw-border-opacity));
}
.focus\:border-lime-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(132, 204, 22, var(--tw-border-opacity));
}
.focus\:border-lime-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(101, 163, 13, var(--tw-border-opacity));
}
.focus\:border-lime-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(77, 124, 15, var(--tw-border-opacity));
}
.focus\:border-lime-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 98, 18, var(--tw-border-opacity));
}
.focus\:border-lime-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(54, 83, 20, var(--tw-border-opacity));
}
.focus\:border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 244, var(--tw-border-opacity));
}
.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 252, 231, var(--tw-border-opacity));
}
.focus\:border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(187, 247, 208, var(--tw-border-opacity));
}
.focus\:border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(134, 239, 172, var(--tw-border-opacity));
}
.focus\:border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(74, 222, 128, var(--tw-border-opacity));
}
.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}
.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(22, 163, 74, var(--tw-border-opacity));
}
.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(21, 128, 61, var(--tw-border-opacity));
}
.focus\:border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(22, 101, 52, var(--tw-border-opacity));
}
.focus\:border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(20, 83, 45, var(--tw-border-opacity));
}
.focus\:border-emerald-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus\:border-emerald-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus\:border-emerald-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus\:border-emerald-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus\:border-emerald-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus\:border-emerald-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus\:border-emerald-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus\:border-emerald-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus\:border-emerald-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus\:border-emerald-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus\:border-teal-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 253, 250, var(--tw-border-opacity));
}
.focus\:border-teal-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(204, 251, 241, var(--tw-border-opacity));
}
.focus\:border-teal-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 246, 228, var(--tw-border-opacity));
}
.focus\:border-teal-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(94, 234, 212, var(--tw-border-opacity));
}
.focus\:border-teal-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(45, 212, 191, var(--tw-border-opacity));
}
.focus\:border-teal-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(20, 184, 166, var(--tw-border-opacity));
}
.focus\:border-teal-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(13, 148, 136, var(--tw-border-opacity));
}
.focus\:border-teal-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(15, 118, 110, var(--tw-border-opacity));
}
.focus\:border-teal-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(17, 94, 89, var(--tw-border-opacity));
}
.focus\:border-teal-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(19, 78, 74, var(--tw-border-opacity));
}
.focus\:border-cyan-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 254, 255, var(--tw-border-opacity));
}
.focus\:border-cyan-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(207, 250, 254, var(--tw-border-opacity));
}
.focus\:border-cyan-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 243, 252, var(--tw-border-opacity));
}
.focus\:border-cyan-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(103, 232, 249, var(--tw-border-opacity));
}
.focus\:border-cyan-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 211, 238, var(--tw-border-opacity));
}
.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 182, 212, var(--tw-border-opacity));
}
.focus\:border-cyan-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(8, 145, 178, var(--tw-border-opacity));
}
.focus\:border-cyan-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(14, 116, 144, var(--tw-border-opacity));
}
.focus\:border-cyan-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(21, 94, 117, var(--tw-border-opacity));
}
.focus\:border-cyan-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(22, 78, 99, var(--tw-border-opacity));
}
.focus\:border-sky-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 249, 255, var(--tw-border-opacity));
}
.focus\:border-sky-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 242, 254, var(--tw-border-opacity));
}
.focus\:border-sky-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(186, 230, 253, var(--tw-border-opacity));
}
.focus\:border-sky-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(125, 211, 252, var(--tw-border-opacity));
}
.focus\:border-sky-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(56, 189, 248, var(--tw-border-opacity));
}
.focus\:border-sky-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(14, 165, 233, var(--tw-border-opacity));
}
.focus\:border-sky-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(2, 132, 199, var(--tw-border-opacity));
}
.focus\:border-sky-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}
.focus\:border-sky-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(7, 89, 133, var(--tw-border-opacity));
}
.focus\:border-sky-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(12, 74, 110, var(--tw-border-opacity));
}
.focus\:border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus\:border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus\:border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus\:border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus\:border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus\:border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus\:border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus\:border-violet-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus\:border-violet-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus\:border-violet-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus\:border-violet-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus\:border-violet-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus\:border-violet-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus\:border-violet-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus\:border-violet-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus\:border-violet-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus\:border-violet-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus\:border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 245, 255, var(--tw-border-opacity));
}
.focus\:border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 232, 255, var(--tw-border-opacity));
}
.focus\:border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(233, 213, 255, var(--tw-border-opacity));
}
.focus\:border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(216, 180, 254, var(--tw-border-opacity));
}
.focus\:border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(192, 132, 252, var(--tw-border-opacity));
}
.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(168, 85, 247, var(--tw-border-opacity));
}
.focus\:border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 51, 234, var(--tw-border-opacity));
}
.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(126, 34, 206, var(--tw-border-opacity));
}
.focus\:border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 33, 168, var(--tw-border-opacity));
}
.focus\:border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(88, 28, 135, var(--tw-border-opacity));
}
.focus\:border-fuchsia-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 244, 255, var(--tw-border-opacity));
}
.focus\:border-fuchsia-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(250, 232, 255, var(--tw-border-opacity));
}
.focus\:border-fuchsia-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 208, 254, var(--tw-border-opacity));
}
.focus\:border-fuchsia-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(240, 171, 252, var(--tw-border-opacity));
}
.focus\:border-fuchsia-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(232, 121, 249, var(--tw-border-opacity));
}
.focus\:border-fuchsia-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 70, 239, var(--tw-border-opacity));
}
.focus\:border-fuchsia-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(192, 38, 211, var(--tw-border-opacity));
}
.focus\:border-fuchsia-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(162, 28, 175, var(--tw-border-opacity));
}
.focus\:border-fuchsia-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(134, 25, 143, var(--tw-border-opacity));
}
.focus\:border-fuchsia-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(112, 26, 117, var(--tw-border-opacity));
}
.focus\:border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus\:border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus\:border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus\:border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus\:border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus\:border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus\:border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus\:border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus\:border-rose-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 242, var(--tw-border-opacity));
}
.focus\:border-rose-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 228, 230, var(--tw-border-opacity));
}
.focus\:border-rose-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 205, 211, var(--tw-border-opacity));
}
.focus\:border-rose-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 164, 175, var(--tw-border-opacity));
}
.focus\:border-rose-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 113, 133, var(--tw-border-opacity));
}
.focus\:border-rose-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 63, 94, var(--tw-border-opacity));
}
.focus\:border-rose-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(225, 29, 72, var(--tw-border-opacity));
}
.focus\:border-rose-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 18, 60, var(--tw-border-opacity));
}
.focus\:border-rose-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 18, 57, var(--tw-border-opacity));
}
.focus\:border-rose-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(136, 19, 55, var(--tw-border-opacity));
}
.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.focus\:ring-inherit:focus {
  --tw-ring-color: inherit;
}
.focus\:ring-current:focus {
  --tw-ring-color: currentColor;
}
.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}
.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus\:ring-slate-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 250, 252, var(--tw-ring-opacity));
}
.focus\:ring-slate-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(241, 245, 249, var(--tw-ring-opacity));
}
.focus\:ring-slate-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}
.focus\:ring-slate-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(203, 213, 225, var(--tw-ring-opacity));
}
.focus\:ring-slate-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
}
.focus\:ring-slate-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(100, 116, 139, var(--tw-ring-opacity));
}
.focus\:ring-slate-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(71, 85, 105, var(--tw-ring-opacity));
}
.focus\:ring-slate-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(51, 65, 85, var(--tw-ring-opacity));
}
.focus\:ring-slate-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 41, 59, var(--tw-ring-opacity));
}
.focus\:ring-slate-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 23, 42, var(--tw-ring-opacity));
}
.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus\:ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.focus\:ring-zinc-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus\:ring-zinc-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 244, 245, var(--tw-ring-opacity));
}
.focus\:ring-zinc-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.focus\:ring-zinc-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 216, var(--tw-ring-opacity));
}
.focus\:ring-zinc-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 161, 170, var(--tw-ring-opacity));
}
.focus\:ring-zinc-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 113, 122, var(--tw-ring-opacity));
}
.focus\:ring-zinc-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 91, var(--tw-ring-opacity));
}
.focus\:ring-zinc-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 63, 70, var(--tw-ring-opacity));
}
.focus\:ring-zinc-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(39, 39, 42, var(--tw-ring-opacity));
}
.focus\:ring-zinc-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(24, 24, 27, var(--tw-ring-opacity));
}
.focus\:ring-neutral-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity));
}
.focus\:ring-neutral-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 245, var(--tw-ring-opacity));
}
.focus\:ring-neutral-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 229, 229, var(--tw-ring-opacity));
}
.focus\:ring-neutral-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}
.focus\:ring-neutral-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 163, 163, var(--tw-ring-opacity));
}
.focus\:ring-neutral-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}
.focus\:ring-neutral-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(82, 82, 82, var(--tw-ring-opacity));
}
.focus\:ring-neutral-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(64, 64, 64, var(--tw-ring-opacity));
}
.focus\:ring-neutral-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(38, 38, 38, var(--tw-ring-opacity));
}
.focus\:ring-neutral-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(23, 23, 23, var(--tw-ring-opacity));
}
.focus\:ring-stone-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 249, var(--tw-ring-opacity));
}
.focus\:ring-stone-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 245, 244, var(--tw-ring-opacity));
}
.focus\:ring-stone-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(231, 229, 228, var(--tw-ring-opacity));
}
.focus\:ring-stone-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(214, 211, 209, var(--tw-ring-opacity));
}
.focus\:ring-stone-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 162, 158, var(--tw-ring-opacity));
}
.focus\:ring-stone-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 113, 108, var(--tw-ring-opacity));
}
.focus\:ring-stone-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(87, 83, 78, var(--tw-ring-opacity));
}
.focus\:ring-stone-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(68, 64, 60, var(--tw-ring-opacity));
}
.focus\:ring-stone-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(41, 37, 36, var(--tw-ring-opacity));
}
.focus\:ring-stone-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 25, 23, var(--tw-ring-opacity));
}
.focus\:ring-red-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus\:ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus\:ring-red-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus\:ring-red-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus\:ring-orange-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 247, 237, var(--tw-ring-opacity));
}
.focus\:ring-orange-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 237, 213, var(--tw-ring-opacity));
}
.focus\:ring-orange-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 215, 170, var(--tw-ring-opacity));
}
.focus\:ring-orange-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 116, var(--tw-ring-opacity));
}
.focus\:ring-orange-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 146, 60, var(--tw-ring-opacity));
}
.focus\:ring-orange-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 115, 22, var(--tw-ring-opacity));
}
.focus\:ring-orange-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 88, 12, var(--tw-ring-opacity));
}
.focus\:ring-orange-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 65, 12, var(--tw-ring-opacity));
}
.focus\:ring-orange-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(154, 52, 18, var(--tw-ring-opacity));
}
.focus\:ring-orange-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 45, 18, var(--tw-ring-opacity));
}
.focus\:ring-amber-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus\:ring-amber-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus\:ring-amber-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus\:ring-amber-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus\:ring-amber-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus\:ring-amber-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus\:ring-amber-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus\:ring-amber-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus\:ring-amber-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus\:ring-amber-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus\:ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 252, 232, var(--tw-ring-opacity));
}
.focus\:ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 249, 195, var(--tw-ring-opacity));
}
.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 240, 138, var(--tw-ring-opacity));
}
.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 224, 71, var(--tw-ring-opacity));
}
.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 204, 21, var(--tw-ring-opacity));
}
.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 179, 8, var(--tw-ring-opacity));
}
.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 138, 4, var(--tw-ring-opacity));
}
.focus\:ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(161, 98, 7, var(--tw-ring-opacity));
}
.focus\:ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(133, 77, 14, var(--tw-ring-opacity));
}
.focus\:ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(113, 63, 18, var(--tw-ring-opacity));
}
.focus\:ring-lime-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(247, 254, 231, var(--tw-ring-opacity));
}
.focus\:ring-lime-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 252, 203, var(--tw-ring-opacity));
}
.focus\:ring-lime-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 249, 157, var(--tw-ring-opacity));
}
.focus\:ring-lime-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 242, 100, var(--tw-ring-opacity));
}
.focus\:ring-lime-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(163, 230, 53, var(--tw-ring-opacity));
}
.focus\:ring-lime-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 204, 22, var(--tw-ring-opacity));
}
.focus\:ring-lime-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 163, 13, var(--tw-ring-opacity));
}
.focus\:ring-lime-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(77, 124, 15, var(--tw-ring-opacity));
}
.focus\:ring-lime-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 98, 18, var(--tw-ring-opacity));
}
.focus\:ring-lime-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(54, 83, 20, var(--tw-ring-opacity));
}
.focus\:ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 244, var(--tw-ring-opacity));
}
.focus\:ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 252, 231, var(--tw-ring-opacity));
}
.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(187, 247, 208, var(--tw-ring-opacity));
}
.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 239, 172, var(--tw-ring-opacity));
}
.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 222, 128, var(--tw-ring-opacity));
}
.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}
.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 163, 74, var(--tw-ring-opacity));
}
.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 128, 61, var(--tw-ring-opacity));
}
.focus\:ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 101, 52, var(--tw-ring-opacity));
}
.focus\:ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 83, 45, var(--tw-ring-opacity));
}
.focus\:ring-emerald-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus\:ring-emerald-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus\:ring-emerald-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus\:ring-emerald-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus\:ring-emerald-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus\:ring-emerald-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus\:ring-emerald-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus\:ring-emerald-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus\:ring-emerald-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus\:ring-emerald-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus\:ring-teal-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 253, 250, var(--tw-ring-opacity));
}
.focus\:ring-teal-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 251, 241, var(--tw-ring-opacity));
}
.focus\:ring-teal-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 246, 228, var(--tw-ring-opacity));
}
.focus\:ring-teal-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 234, 212, var(--tw-ring-opacity));
}
.focus\:ring-teal-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(45, 212, 191, var(--tw-ring-opacity));
}
.focus\:ring-teal-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 184, 166, var(--tw-ring-opacity));
}
.focus\:ring-teal-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 148, 136, var(--tw-ring-opacity));
}
.focus\:ring-teal-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(15, 118, 110, var(--tw-ring-opacity));
}
.focus\:ring-teal-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 94, 89, var(--tw-ring-opacity));
}
.focus\:ring-teal-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(19, 78, 74, var(--tw-ring-opacity));
}
.focus\:ring-cyan-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 254, 255, var(--tw-ring-opacity));
}
.focus\:ring-cyan-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(207, 250, 254, var(--tw-ring-opacity));
}
.focus\:ring-cyan-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 243, 252, var(--tw-ring-opacity));
}
.focus\:ring-cyan-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 232, 249, var(--tw-ring-opacity));
}
.focus\:ring-cyan-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 211, 238, var(--tw-ring-opacity));
}
.focus\:ring-cyan-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 182, 212, var(--tw-ring-opacity));
}
.focus\:ring-cyan-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(8, 145, 178, var(--tw-ring-opacity));
}
.focus\:ring-cyan-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 116, 144, var(--tw-ring-opacity));
}
.focus\:ring-cyan-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(21, 94, 117, var(--tw-ring-opacity));
}
.focus\:ring-cyan-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 78, 99, var(--tw-ring-opacity));
}
.focus\:ring-sky-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 249, 255, var(--tw-ring-opacity));
}
.focus\:ring-sky-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 242, 254, var(--tw-ring-opacity));
}
.focus\:ring-sky-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(186, 230, 253, var(--tw-ring-opacity));
}
.focus\:ring-sky-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(125, 211, 252, var(--tw-ring-opacity));
}
.focus\:ring-sky-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 189, 248, var(--tw-ring-opacity));
}
.focus\:ring-sky-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}
.focus\:ring-sky-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(2, 132, 199, var(--tw-ring-opacity));
}
.focus\:ring-sky-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 105, 161, var(--tw-ring-opacity));
}
.focus\:ring-sky-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(7, 89, 133, var(--tw-ring-opacity));
}
.focus\:ring-sky-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(12, 74, 110, var(--tw-ring-opacity));
}
.focus\:ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus\:ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus\:ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus\:ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus\:ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus\:ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus\:ring-violet-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus\:ring-violet-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus\:ring-violet-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus\:ring-violet-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus\:ring-violet-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus\:ring-violet-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus\:ring-violet-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus\:ring-violet-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus\:ring-violet-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus\:ring-violet-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus\:ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 245, 255, var(--tw-ring-opacity));
}
.focus\:ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 232, 255, var(--tw-ring-opacity));
}
.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(233, 213, 255, var(--tw-ring-opacity));
}
.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(216, 180, 254, var(--tw-ring-opacity));
}
.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 132, 252, var(--tw-ring-opacity));
}
.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(168, 85, 247, var(--tw-ring-opacity));
}
.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 51, 234, var(--tw-ring-opacity));
}
.focus\:ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 34, 206, var(--tw-ring-opacity));
}
.focus\:ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 33, 168, var(--tw-ring-opacity));
}
.focus\:ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(88, 28, 135, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 244, 255, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 232, 255, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 208, 254, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 171, 252, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(232, 121, 249, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 70, 239, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(192, 38, 211, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(162, 28, 175, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(134, 25, 143, var(--tw-ring-opacity));
}
.focus\:ring-fuchsia-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(112, 26, 117, var(--tw-ring-opacity));
}
.focus\:ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus\:ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus\:ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus\:ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus\:ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus\:ring-rose-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 241, 242, var(--tw-ring-opacity));
}
.focus\:ring-rose-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 228, 230, var(--tw-ring-opacity));
}
.focus\:ring-rose-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 205, 211, var(--tw-ring-opacity));
}
.focus\:ring-rose-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 164, 175, var(--tw-ring-opacity));
}
.focus\:ring-rose-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 113, 133, var(--tw-ring-opacity));
}
.focus\:ring-rose-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 63, 94, var(--tw-ring-opacity));
}
.focus\:ring-rose-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(225, 29, 72, var(--tw-ring-opacity));
}
.focus\:ring-rose-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 18, 60, var(--tw-ring-opacity));
}
.focus\:ring-rose-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 18, 57, var(--tw-ring-opacity));
}
.focus\:ring-rose-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(136, 19, 55, var(--tw-ring-opacity));
}
.active\:bg-inherit:active {
  background-color: inherit;
}
.active\:bg-current:active {
  background-color: currentColor;
}
.active\:bg-transparent:active {
  background-color: initial;
}
.active\:bg-black:active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.active\:bg-white:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.active\:bg-slate-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.active\:bg-slate-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.active\:bg-slate-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.active\:bg-slate-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.active\:bg-slate-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.active\:bg-slate-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.active\:bg-slate-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.active\:bg-slate-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.active\:bg-slate-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.active\:bg-slate-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.active\:bg-gray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.active\:bg-gray-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.active\:bg-gray-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.active\:bg-gray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.active\:bg-gray-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-zinc-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.active\:bg-zinc-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.active\:bg-zinc-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.active\:bg-zinc-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.active\:bg-zinc-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.active\:bg-zinc-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.active\:bg-zinc-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.active\:bg-zinc-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.active\:bg-zinc-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.active\:bg-zinc-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.active\:bg-neutral-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.active\:bg-neutral-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.active\:bg-neutral-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.active\:bg-neutral-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.active\:bg-neutral-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.active\:bg-neutral-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.active\:bg-neutral-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.active\:bg-neutral-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.active\:bg-neutral-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.active\:bg-neutral-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.active\:bg-stone-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.active\:bg-stone-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.active\:bg-stone-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.active\:bg-stone-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.active\:bg-stone-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.active\:bg-stone-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.active\:bg-stone-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.active\:bg-stone-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.active\:bg-stone-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.active\:bg-stone-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.active\:bg-red-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.active\:bg-red-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.active\:bg-red-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.active\:bg-red-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.active\:bg-red-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.active\:bg-red-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.active\:bg-red-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.active\:bg-red-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.active\:bg-red-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.active\:bg-orange-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.active\:bg-orange-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.active\:bg-orange-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.active\:bg-orange-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.active\:bg-orange-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.active\:bg-orange-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.active\:bg-orange-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.active\:bg-orange-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.active\:bg-orange-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.active\:bg-orange-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.active\:bg-amber-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.active\:bg-amber-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.active\:bg-amber-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.active\:bg-amber-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.active\:bg-amber-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.active\:bg-amber-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.active\:bg-amber-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.active\:bg-amber-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.active\:bg-amber-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.active\:bg-amber-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.active\:bg-yellow-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.active\:bg-yellow-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.active\:bg-yellow-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.active\:bg-yellow-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.active\:bg-yellow-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.active\:bg-yellow-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.active\:bg-yellow-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.active\:bg-yellow-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.active\:bg-yellow-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.active\:bg-yellow-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.active\:bg-lime-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.active\:bg-lime-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.active\:bg-lime-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.active\:bg-lime-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.active\:bg-lime-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.active\:bg-lime-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.active\:bg-lime-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.active\:bg-lime-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.active\:bg-lime-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.active\:bg-lime-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.active\:bg-green-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.active\:bg-green-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}
.active\:bg-green-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.active\:bg-green-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}
.active\:bg-green-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.active\:bg-green-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}
.active\:bg-green-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.active\:bg-green-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.active\:bg-green-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.active\:bg-green-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.active\:bg-emerald-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.active\:bg-emerald-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.active\:bg-emerald-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.active\:bg-emerald-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.active\:bg-emerald-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.active\:bg-emerald-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.active\:bg-emerald-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.active\:bg-emerald-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.active\:bg-emerald-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.active\:bg-emerald-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.active\:bg-teal-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.active\:bg-teal-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.active\:bg-teal-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.active\:bg-teal-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.active\:bg-teal-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.active\:bg-teal-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.active\:bg-teal-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.active\:bg-teal-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.active\:bg-teal-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.active\:bg-teal-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.active\:bg-cyan-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.active\:bg-cyan-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.active\:bg-cyan-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.active\:bg-cyan-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.active\:bg-cyan-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.active\:bg-cyan-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.active\:bg-cyan-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.active\:bg-cyan-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.active\:bg-cyan-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.active\:bg-cyan-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.active\:bg-sky-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.active\:bg-sky-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.active\:bg-sky-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.active\:bg-sky-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.active\:bg-sky-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.active\:bg-sky-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.active\:bg-sky-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.active\:bg-sky-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.active\:bg-sky-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.active\:bg-sky-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.active\:bg-blue-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.active\:bg-blue-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.active\:bg-blue-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.active\:bg-blue-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.active\:bg-blue-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.active\:bg-blue-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-blue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.active\:bg-blue-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.active\:bg-blue-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.active\:bg-blue-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.active\:bg-indigo-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.active\:bg-indigo-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.active\:bg-indigo-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.active\:bg-indigo-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.active\:bg-indigo-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.active\:bg-indigo-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.active\:bg-indigo-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.active\:bg-indigo-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.active\:bg-indigo-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.active\:bg-violet-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.active\:bg-violet-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.active\:bg-violet-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.active\:bg-violet-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.active\:bg-violet-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.active\:bg-violet-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.active\:bg-violet-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.active\:bg-violet-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.active\:bg-violet-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.active\:bg-violet-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.active\:bg-purple-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.active\:bg-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.active\:bg-purple-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.active\:bg-purple-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.active\:bg-purple-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.active\:bg-purple-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.active\:bg-purple-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.active\:bg-purple-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.active\:bg-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.active\:bg-purple-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.active\:bg-fuchsia-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.active\:bg-pink-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.active\:bg-pink-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.active\:bg-pink-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.active\:bg-pink-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.active\:bg-pink-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.active\:bg-pink-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.active\:bg-pink-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.active\:bg-pink-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.active\:bg-pink-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.active\:bg-pink-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.active\:bg-rose-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.active\:bg-rose-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.active\:bg-rose-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.active\:bg-rose-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.active\:bg-rose-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.active\:bg-rose-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.active\:bg-rose-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.active\:bg-rose-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.active\:bg-rose-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.active\:bg-rose-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.group:hover .group-hover\:block {
  display: block;
}
.group:hover .group-hover\:flex {
  display: flex;
}
.group:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.group:hover .group-hover\:underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.group:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
[dir="ltr"] .ltr\:right-4 {
  right: 1rem;
}
[dir="ltr"] .ltr\:right-6 {
  right: 1.5rem;
}
[dir="ltr"] .ltr\:left-0 {
  left: 0;
}
[dir="ltr"] .ltr\:left-\[-280px\] {
  left: -280px;
}
[dir="ltr"] .ltr\:-ml-px {
  margin-left: -1px;
}
[dir="ltr"] .ltr\:mr-2 {
  margin-right: 0.5rem;
}
[dir="ltr"] .ltr\:ml-2 {
  margin-left: 0.5rem;
}
[dir="ltr"] .ltr\:ml-3 {
  margin-left: 0.75rem;
}
[dir="ltr"] .ltr\:ml-4 {
  margin-left: 1rem;
}
[dir="ltr"] .ltr\:ml-1 {
  margin-left: 0.25rem;
}
[dir="ltr"] .ltr\:mr-3 {
  margin-right: 0.75rem;
}
[dir="ltr"] .ltr\:mr-1 {
  margin-right: 0.25rem;
}
[dir="ltr"] .ltr\:border-l {
  border-left-width: 1px;
}
[dir="ltr"] .ltr\:border-r-0 {
  border-right-width: 0;
}
[dir="ltr"] .ltr\:border-l-0 {
  border-left-width: 0;
}
[dir="ltr"] .ltr\:border-r {
  border-right-width: 1px;
}
[dir="ltr"] .ltr\:pr-2 {
  padding-right: 0.5rem;
}
[dir="ltr"] .ltr\:text-right {
  text-align: right;
}
[dir="rtl"] .rtl\:left-4 {
  left: 1rem;
}
[dir="rtl"] .rtl\:left-6 {
  left: 1.5rem;
}
[dir="rtl"] .rtl\:right-0 {
  right: 0;
}
[dir="rtl"] .rtl\:right-\[-280px\] {
  right: -280px;
}
[dir="rtl"] .rtl\:-mr-px {
  margin-right: -1px;
}
[dir="rtl"] .rtl\:ml-1 {
  margin-left: 0.25rem;
}
[dir="rtl"] .rtl\:mr-1 {
  margin-right: 0.25rem;
}
[dir="rtl"] .rtl\:mr-4 {
  margin-right: 1rem;
}
[dir="rtl"] .rtl\:ml-2 {
  margin-left: 0.5rem;
}
[dir="rtl"] .rtl\:mr-2 {
  margin-right: 0.5rem;
}
[dir="rtl"] .rtl\:mr-3 {
  margin-right: 0.75rem;
}
[dir="rtl"] .rtl\:ml-3 {
  margin-left: 0.75rem;
}
[dir="rtl"] .rtl\:rounded-tr-none {
  border-top-right-radius: 0;
}
[dir="rtl"] .rtl\:rounded-br-none {
  border-bottom-right-radius: 0;
}
[dir="rtl"] .rtl\:rounded-tl-none {
  border-top-left-radius: 0;
}
[dir="rtl"] .rtl\:rounded-bl-none {
  border-bottom-left-radius: 0;
}
[dir="rtl"] .rtl\:border-r {
  border-right-width: 1px;
}
[dir="rtl"] .rtl\:border-l-0 {
  border-left-width: 0;
}
[dir="rtl"] .rtl\:border-r-0 {
  border-right-width: 0;
}
[dir="rtl"] .rtl\:border-l {
  border-left-width: 1px;
}
[dir="rtl"] .rtl\:pl-2 {
  padding-left: 0.5rem;
}
[dir="rtl"] .rtl\:text-left {
  text-align: left;
}
.dark .dark\:prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
  color: #9ca3af;
}
.dark .dark\:border {
  border-width: 1px;
}
.dark .dark\:border-solid {
  border-style: solid;
}
.dark .dark\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.dark .dark\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.dark .dark\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.dark .dark\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.dark .dark\:bg-inherit {
  background-color: inherit;
}
.dark .dark\:bg-current {
  background-color: currentColor;
}
.dark .dark\:bg-transparent {
  background-color: initial;
}
.dark .dark\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.dark .dark\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.dark .dark\:bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.dark .dark\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.dark .dark\:bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.dark .dark\:bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.dark .dark\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.dark .dark\:bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.dark .dark\:bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.dark .dark\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.dark .dark\:bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.dark .dark\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.dark .dark\:bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.dark .dark\:bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.dark .dark\:bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.dark .dark\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.dark .dark\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.dark .dark\:bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.dark .dark\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.dark .dark\:bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.dark .dark\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.dark .dark\:bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.dark .dark\:bg-emerald-500\/20 {
  background-color: rgba(16, 185, 129, 0.2);
}
.dark .dark\:bg-red-500\/20 {
  background-color: rgba(239, 68, 68, 0.2);
}
.dark .dark\:bg-red-500\/10 {
  background-color: rgba(239, 68, 68, 0.1);
}
.dark .dark\:bg-orange-500\/10 {
  background-color: rgba(249, 115, 22, 0.1);
}
.dark .dark\:bg-amber-500\/10 {
  background-color: rgba(245, 158, 11, 0.1);
}
.dark .dark\:bg-yellow-500\/10 {
  background-color: rgba(234, 179, 8, 0.1);
}
.dark .dark\:bg-lime-500\/10 {
  background-color: rgba(132, 204, 22, 0.1);
}
.dark .dark\:bg-green-500\/10 {
  background-color: rgba(34, 197, 94, 0.1);
}
.dark .dark\:bg-emerald-500\/10 {
  background-color: rgba(16, 185, 129, 0.1);
}
.dark .dark\:bg-teal-500\/10 {
  background-color: rgba(20, 184, 166, 0.1);
}
.dark .dark\:bg-cyan-500\/10 {
  background-color: rgba(6, 182, 212, 0.1);
}
.dark .dark\:bg-sky-500\/10 {
  background-color: rgba(14, 165, 233, 0.1);
}
.dark .dark\:bg-blue-500\/10 {
  background-color: rgba(59, 130, 246, 0.1);
}
.dark .dark\:bg-indigo-500\/10 {
  background-color: rgba(99, 102, 241, 0.1);
}
.dark .dark\:bg-purple-500\/10 {
  background-color: rgba(168, 85, 247, 0.1);
}
.dark .dark\:bg-fuchsia-500\/10 {
  background-color: rgba(217, 70, 239, 0.1);
}
.dark .dark\:bg-pink-500\/10 {
  background-color: rgba(236, 72, 153, 0.1);
}
.dark .dark\:bg-rose-500\/10 {
  background-color: rgba(244, 63, 94, 0.1);
}
.dark .dark\:bg-blue-500\/20 {
  background-color: rgba(59, 130, 246, 0.2);
}
.dark .dark\:bg-gray-700\/60 {
  background-color: rgba(55, 65, 81, 0.6);
}
.dark .dark\:bg-cyan-500\/20 {
  background-color: rgba(6, 182, 212, 0.2);
}
.dark .dark\:bg-amber-500\/20 {
  background-color: rgba(245, 158, 11, 0.2);
}
.dark .dark\:bg-sky-500\/20 {
  background-color: rgba(14, 165, 233, 0.2);
}
.dark .dark\:bg-indigo-500\/20 {
  background-color: rgba(99, 102, 241, 0.2);
}
.dark .dark\:bg-purple-500\/20 {
  background-color: rgba(168, 85, 247, 0.2);
}
.dark .dark\:bg-pink-500\/20 {
  background-color: rgba(236, 72, 153, 0.2);
}
.dark .dark\:bg-orange-500\/20 {
  background-color: rgba(249, 115, 22, 0.2);
}
.dark .dark\:bg-teal-500\/20 {
  background-color: rgba(20, 184, 166, 0.2);
}
.dark .dark\:\!bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.dark .dark\:bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.dark .dark\:text-inherit {
  color: inherit;
}
.dark .dark\:text-current {
  color: currentColor;
}
.dark .dark\:text-transparent {
  color: transparent;
}
.dark .dark\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .dark\:text-slate-50 {
  --tw-text-opacity: 1;
  color: rgba(248, 250, 252, var(--tw-text-opacity));
}
.dark .dark\:text-slate-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}
.dark .dark\:text-slate-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}
.dark .dark\:text-slate-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}
.dark .dark\:text-slate-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.dark .dark\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.dark .dark\:text-slate-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}
.dark .dark\:text-slate-700 {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}
.dark .dark\:text-slate-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}
.dark .dark\:text-slate-900 {
  --tw-text-opacity: 1;
  color: rgba(15, 23, 42, var(--tw-text-opacity));
}
.dark .dark\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.dark .dark\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.dark .dark\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.dark .dark\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .dark\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.dark .dark\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .dark\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.dark .dark\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.dark .dark\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgba(244, 244, 245, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgba(228, 228, 231, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgba(161, 161, 170, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgba(63, 63, 70, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.dark .dark\:text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}
.dark .dark\:text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}
.dark .dark\:text-stone-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 249, var(--tw-text-opacity));
}
.dark .dark\:text-stone-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 244, var(--tw-text-opacity));
}
.dark .dark\:text-stone-200 {
  --tw-text-opacity: 1;
  color: rgba(231, 229, 228, var(--tw-text-opacity));
}
.dark .dark\:text-stone-300 {
  --tw-text-opacity: 1;
  color: rgba(214, 211, 209, var(--tw-text-opacity));
}
.dark .dark\:text-stone-400 {
  --tw-text-opacity: 1;
  color: rgba(168, 162, 158, var(--tw-text-opacity));
}
.dark .dark\:text-stone-500 {
  --tw-text-opacity: 1;
  color: rgba(120, 113, 108, var(--tw-text-opacity));
}
.dark .dark\:text-stone-600 {
  --tw-text-opacity: 1;
  color: rgba(87, 83, 78, var(--tw-text-opacity));
}
.dark .dark\:text-stone-700 {
  --tw-text-opacity: 1;
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}
.dark .dark\:text-stone-800 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 36, var(--tw-text-opacity));
}
.dark .dark\:text-stone-900 {
  --tw-text-opacity: 1;
  color: rgba(28, 25, 23, var(--tw-text-opacity));
}
.dark .dark\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.dark .dark\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.dark .dark\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.dark .dark\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.dark .dark\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.dark .dark\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.dark .dark\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.dark .dark\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.dark .dark\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.dark .dark\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.dark .dark\:text-orange-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 247, 237, var(--tw-text-opacity));
}
.dark .dark\:text-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 237, 213, var(--tw-text-opacity));
}
.dark .dark\:text-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 215, 170, var(--tw-text-opacity));
}
.dark .dark\:text-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(253, 186, 116, var(--tw-text-opacity));
}
.dark .dark\:text-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 146, 60, var(--tw-text-opacity));
}
.dark .dark\:text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.dark .dark\:text-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(234, 88, 12, var(--tw-text-opacity));
}
.dark .dark\:text-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(194, 65, 12, var(--tw-text-opacity));
}
.dark .dark\:text-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(154, 52, 18, var(--tw-text-opacity));
}
.dark .dark\:text-orange-900 {
  --tw-text-opacity: 1;
  color: rgba(124, 45, 18, var(--tw-text-opacity));
}
.dark .dark\:text-amber-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.dark .dark\:text-amber-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.dark .dark\:text-amber-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.dark .dark\:text-amber-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.dark .dark\:text-amber-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.dark .dark\:text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.dark .dark\:text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.dark .dark\:text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.dark .dark\:text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.dark .dark\:text-amber-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 249, 195, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 240, 138, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(253, 224, 71, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(202, 138, 4, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}
.dark .dark\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(113, 63, 18, var(--tw-text-opacity));
}
.dark .dark\:text-lime-50 {
  --tw-text-opacity: 1;
  color: rgba(247, 254, 231, var(--tw-text-opacity));
}
.dark .dark\:text-lime-100 {
  --tw-text-opacity: 1;
  color: rgba(236, 252, 203, var(--tw-text-opacity));
}
.dark .dark\:text-lime-200 {
  --tw-text-opacity: 1;
  color: rgba(217, 249, 157, var(--tw-text-opacity));
}
.dark .dark\:text-lime-300 {
  --tw-text-opacity: 1;
  color: rgba(190, 242, 100, var(--tw-text-opacity));
}
.dark .dark\:text-lime-400 {
  --tw-text-opacity: 1;
  color: rgba(163, 230, 53, var(--tw-text-opacity));
}
.dark .dark\:text-lime-500 {
  --tw-text-opacity: 1;
  color: rgba(132, 204, 22, var(--tw-text-opacity));
}
.dark .dark\:text-lime-600 {
  --tw-text-opacity: 1;
  color: rgba(101, 163, 13, var(--tw-text-opacity));
}
.dark .dark\:text-lime-700 {
  --tw-text-opacity: 1;
  color: rgba(77, 124, 15, var(--tw-text-opacity));
}
.dark .dark\:text-lime-800 {
  --tw-text-opacity: 1;
  color: rgba(63, 98, 18, var(--tw-text-opacity));
}
.dark .dark\:text-lime-900 {
  --tw-text-opacity: 1;
  color: rgba(54, 83, 20, var(--tw-text-opacity));
}
.dark .dark\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 244, var(--tw-text-opacity));
}
.dark .dark\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(220, 252, 231, var(--tw-text-opacity));
}
.dark .dark\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.dark .dark\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.dark .dark\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}
.dark .dark\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}
.dark .dark\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}
.dark .dark\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(21, 128, 61, var(--tw-text-opacity));
}
.dark .dark\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(22, 101, 52, var(--tw-text-opacity));
}
.dark .dark\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(20, 83, 45, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.dark .dark\:text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.dark .dark\:text-teal-50 {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 250, var(--tw-text-opacity));
}
.dark .dark\:text-teal-100 {
  --tw-text-opacity: 1;
  color: rgba(204, 251, 241, var(--tw-text-opacity));
}
.dark .dark\:text-teal-200 {
  --tw-text-opacity: 1;
  color: rgba(153, 246, 228, var(--tw-text-opacity));
}
.dark .dark\:text-teal-300 {
  --tw-text-opacity: 1;
  color: rgba(94, 234, 212, var(--tw-text-opacity));
}
.dark .dark\:text-teal-400 {
  --tw-text-opacity: 1;
  color: rgba(45, 212, 191, var(--tw-text-opacity));
}
.dark .dark\:text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}
.dark .dark\:text-teal-600 {
  --tw-text-opacity: 1;
  color: rgba(13, 148, 136, var(--tw-text-opacity));
}
.dark .dark\:text-teal-700 {
  --tw-text-opacity: 1;
  color: rgba(15, 118, 110, var(--tw-text-opacity));
}
.dark .dark\:text-teal-800 {
  --tw-text-opacity: 1;
  color: rgba(17, 94, 89, var(--tw-text-opacity));
}
.dark .dark\:text-teal-900 {
  --tw-text-opacity: 1;
  color: rgba(19, 78, 74, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 254, 255, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 250, 254, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgba(165, 243, 252, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgba(103, 232, 249, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgba(34, 211, 238, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgba(6, 182, 212, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgba(8, 145, 178, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(14, 116, 144, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgba(21, 94, 117, var(--tw-text-opacity));
}
.dark .dark\:text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgba(22, 78, 99, var(--tw-text-opacity));
}
.dark .dark\:text-sky-50 {
  --tw-text-opacity: 1;
  color: rgba(240, 249, 255, var(--tw-text-opacity));
}
.dark .dark\:text-sky-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 254, var(--tw-text-opacity));
}
.dark .dark\:text-sky-200 {
  --tw-text-opacity: 1;
  color: rgba(186, 230, 253, var(--tw-text-opacity));
}
.dark .dark\:text-sky-300 {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}
.dark .dark\:text-sky-400 {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}
.dark .dark\:text-sky-500 {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}
.dark .dark\:text-sky-600 {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}
.dark .dark\:text-sky-700 {
  --tw-text-opacity: 1;
  color: rgba(3, 105, 161, var(--tw-text-opacity));
}
.dark .dark\:text-sky-800 {
  --tw-text-opacity: 1;
  color: rgba(7, 89, 133, var(--tw-text-opacity));
}
.dark .dark\:text-sky-900 {
  --tw-text-opacity: 1;
  color: rgba(12, 74, 110, var(--tw-text-opacity));
}
.dark .dark\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.dark .dark\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.dark .dark\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.dark .dark\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.dark .dark\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.dark .dark\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.dark .dark\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.dark .dark\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.dark .dark\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.dark .dark\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.dark .dark\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.dark .dark\:text-violet-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.dark .dark\:text-violet-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.dark .dark\:text-violet-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.dark .dark\:text-violet-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.dark .dark\:text-violet-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.dark .dark\:text-violet-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.dark .dark\:text-violet-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.dark .dark\:text-violet-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.dark .dark\:text-violet-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.dark .dark\:text-violet-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.dark .dark\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(250, 245, 255, var(--tw-text-opacity));
}
.dark .dark\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 232, 255, var(--tw-text-opacity));
}
.dark .dark\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(233, 213, 255, var(--tw-text-opacity));
}
.dark .dark\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(216, 180, 254, var(--tw-text-opacity));
}
.dark .dark\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(192, 132, 252, var(--tw-text-opacity));
}
.dark .dark\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(168, 85, 247, var(--tw-text-opacity));
}
.dark .dark\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(147, 51, 234, var(--tw-text-opacity));
}
.dark .dark\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(126, 34, 206, var(--tw-text-opacity));
}
.dark .dark\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(107, 33, 168, var(--tw-text-opacity));
}
.dark .dark\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(88, 28, 135, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 244, 255, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgba(250, 232, 255, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgba(245, 208, 254, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgba(240, 171, 252, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgba(232, 121, 249, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgba(217, 70, 239, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgba(192, 38, 211, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgba(162, 28, 175, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgba(134, 25, 143, var(--tw-text-opacity));
}
.dark .dark\:text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgba(112, 26, 117, var(--tw-text-opacity));
}
.dark .dark\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.dark .dark\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.dark .dark\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.dark .dark\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.dark .dark\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.dark .dark\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.dark .dark\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.dark .dark\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.dark .dark\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.dark .dark\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.dark .dark\:text-rose-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 242, var(--tw-text-opacity));
}
.dark .dark\:text-rose-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 228, 230, var(--tw-text-opacity));
}
.dark .dark\:text-rose-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 205, 211, var(--tw-text-opacity));
}
.dark .dark\:text-rose-300 {
  --tw-text-opacity: 1;
  color: rgba(253, 164, 175, var(--tw-text-opacity));
}
.dark .dark\:text-rose-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 113, 133, var(--tw-text-opacity));
}
.dark .dark\:text-rose-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 63, 94, var(--tw-text-opacity));
}
.dark .dark\:text-rose-600 {
  --tw-text-opacity: 1;
  color: rgba(225, 29, 72, var(--tw-text-opacity));
}
.dark .dark\:text-rose-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 18, 60, var(--tw-text-opacity));
}
.dark .dark\:text-rose-800 {
  --tw-text-opacity: 1;
  color: rgba(159, 18, 57, var(--tw-text-opacity));
}
.dark .dark\:text-rose-900 {
  --tw-text-opacity: 1;
  color: rgba(136, 19, 55, var(--tw-text-opacity));
}
.dark .dark\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .hover\:dark\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.dark .hover\:dark\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.dark .dark\:hover\:bg-inherit:hover {
  background-color: inherit;
}
.dark .dark\:hover\:bg-current:hover {
  background-color: currentColor;
}
.dark .dark\:hover\:bg-transparent:hover {
  background-color: initial;
}
.dark .dark\:hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-zinc-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-neutral-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-stone-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-lime-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-emerald-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-teal-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-cyan-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-sky-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-violet-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-fuchsia-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-rose-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-700\/90:hover {
  background-color: rgba(55, 65, 81, 0.9);
}
.dark .hover\:dark\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.dark .dark\:hover\:bg-gray-600\/40:hover {
  background-color: rgba(75, 85, 99, 0.4);
}
.dark .dark\:hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}
.dark .dark\:hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}
.dark .dark\:hover\:text-inherit:hover {
  color: inherit;
}
.dark .dark\:hover\:text-current:hover {
  color: currentColor;
}
.dark .dark\:hover\:text-transparent:hover {
  color: transparent;
}
.dark .dark\:hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-50:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 250, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-100:hover {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-400:hover {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-600:hover {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-slate-900:hover {
  --tw-text-opacity: 1;
  color: rgba(15, 23, 42, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-100:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 244, 245, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-200:hover {
  --tw-text-opacity: 1;
  color: rgba(228, 228, 231, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-300:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-400:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 161, 170, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-500:hover {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-600:hover {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-700:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 63, 70, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-800:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-zinc-900:hover {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 27, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-300:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-400:hover {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-500:hover {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-600:hover {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-700:hover {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-800:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-neutral-900:hover {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 249, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 244, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-200:hover {
  --tw-text-opacity: 1;
  color: rgba(231, 229, 228, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-300:hover {
  --tw-text-opacity: 1;
  color: rgba(214, 211, 209, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-400:hover {
  --tw-text-opacity: 1;
  color: rgba(168, 162, 158, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 113, 108, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-600:hover {
  --tw-text-opacity: 1;
  color: rgba(87, 83, 78, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-700:hover {
  --tw-text-opacity: 1;
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-800:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 36, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-stone-900:hover {
  --tw-text-opacity: 1;
  color: rgba(28, 25, 23, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 247, 237, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 237, 213, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 215, 170, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 186, 116, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 146, 60, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 88, 12, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(194, 65, 12, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgba(154, 52, 18, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 45, 18, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-amber-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 252, 232, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 249, 195, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 240, 138, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 224, 71, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(202, 138, 4, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(113, 63, 18, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-50:hover {
  --tw-text-opacity: 1;
  color: rgba(247, 254, 231, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-100:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 252, 203, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-200:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 249, 157, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-300:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 242, 100, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-400:hover {
  --tw-text-opacity: 1;
  color: rgba(163, 230, 53, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-500:hover {
  --tw-text-opacity: 1;
  color: rgba(132, 204, 22, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-600:hover {
  --tw-text-opacity: 1;
  color: rgba(101, 163, 13, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgba(77, 124, 15, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-800:hover {
  --tw-text-opacity: 1;
  color: rgba(63, 98, 18, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-lime-900:hover {
  --tw-text-opacity: 1;
  color: rgba(54, 83, 20, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 244, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 252, 231, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 247, 208, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 239, 172, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(21, 128, 61, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 101, 52, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(20, 83, 45, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-emerald-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 253, 250, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-100:hover {
  --tw-text-opacity: 1;
  color: rgba(204, 251, 241, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-200:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 246, 228, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-300:hover {
  --tw-text-opacity: 1;
  color: rgba(94, 234, 212, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-400:hover {
  --tw-text-opacity: 1;
  color: rgba(45, 212, 191, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-500:hover {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-600:hover {
  --tw-text-opacity: 1;
  color: rgba(13, 148, 136, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgba(15, 118, 110, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-800:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 94, 89, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-teal-900:hover {
  --tw-text-opacity: 1;
  color: rgba(19, 78, 74, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 254, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-100:hover {
  --tw-text-opacity: 1;
  color: rgba(207, 250, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-200:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 243, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-300:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 232, 249, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-400:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 211, 238, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 182, 212, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-600:hover {
  --tw-text-opacity: 1;
  color: rgba(8, 145, 178, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 116, 144, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-800:hover {
  --tw-text-opacity: 1;
  color: rgba(21, 94, 117, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-cyan-900:hover {
  --tw-text-opacity: 1;
  color: rgba(22, 78, 99, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-50:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 249, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 242, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgba(186, 230, 253, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgba(3, 105, 161, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-800:hover {
  --tw-text-opacity: 1;
  color: rgba(7, 89, 133, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-sky-900:hover {
  --tw-text-opacity: 1;
  color: rgba(12, 74, 110, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-violet-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 245, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 232, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(233, 213, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(216, 180, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(192, 132, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(168, 85, 247, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 51, 234, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(126, 34, 206, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 33, 168, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(88, 28, 135, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 244, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-100:hover {
  --tw-text-opacity: 1;
  color: rgba(250, 232, 255, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-200:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 208, 254, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-300:hover {
  --tw-text-opacity: 1;
  color: rgba(240, 171, 252, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-400:hover {
  --tw-text-opacity: 1;
  color: rgba(232, 121, 249, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-500:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 70, 239, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-600:hover {
  --tw-text-opacity: 1;
  color: rgba(192, 38, 211, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-700:hover {
  --tw-text-opacity: 1;
  color: rgba(162, 28, 175, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-800:hover {
  --tw-text-opacity: 1;
  color: rgba(134, 25, 143, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-fuchsia-900:hover {
  --tw-text-opacity: 1;
  color: rgba(112, 26, 117, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 241, 242, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-100:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 228, 230, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 205, 211, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-300:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 164, 175, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 113, 133, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-500:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 63, 94, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-600:hover {
  --tw-text-opacity: 1;
  color: rgba(225, 29, 72, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 18, 60, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-800:hover {
  --tw-text-opacity: 1;
  color: rgba(159, 18, 57, var(--tw-text-opacity));
}
.dark .dark\:hover\:text-rose-900:hover {
  --tw-text-opacity: 1;
  color: rgba(136, 19, 55, var(--tw-text-opacity));
}
.dark .dark\:active\:border-gray-500:active {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.dark .dark\:active\:bg-inherit:active {
  background-color: inherit;
}
.dark .dark\:active\:bg-current:active {
  background-color: currentColor;
}
.dark .dark\:active\:bg-transparent:active {
  background-color: initial;
}
.dark .dark\:active\:bg-black:active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-white:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-slate-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-gray-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 161, 170, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-zinc-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 163, 163, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-neutral-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 244, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 229, 228, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 211, 209, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 162, 158, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 113, 108, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(87, 83, 78, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 64, 60, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 36, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-stone-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 25, 23, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-red-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 247, 237, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 237, 213, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 146, 60, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 65, 12, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 52, 18, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-orange-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 45, 18, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-amber-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 224, 71, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 204, 21, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 98, 7, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(133, 77, 14, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-yellow-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 63, 18, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 254, 231, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 252, 203, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 249, 157, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 242, 100, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(163, 230, 53, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 204, 22, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 163, 13, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 124, 15, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 98, 18, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-lime-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 244, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 239, 172, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 101, 52, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-green-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 83, 45, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-emerald-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 253, 250, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 234, 212, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 118, 110, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 94, 89, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-teal-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 78, 74, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 254, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 250, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 243, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 232, 249, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 211, 238, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 182, 212, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(8, 145, 178, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 116, 144, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 94, 117, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-cyan-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 78, 99, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-sky-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-blue-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-indigo-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-violet-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 180, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 132, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 51, 234, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 34, 206, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 33, 168, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-purple-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(88, 28, 135, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 244, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 232, 255, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 208, 254, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 171, 252, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 121, 249, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 70, 239, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 38, 211, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 28, 175, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(134, 25, 143, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-fuchsia-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 26, 117, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-pink-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 230, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 211, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 164, 175, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-400:active {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 63, 94, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-rose-900:active {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 19, 55, var(--tw-bg-opacity));
}
.dark .dark\:active\:bg-opacity-40:active {
  --tw-bg-opacity: 0.4;
}
.group:hover .dark .group-hover\:dark\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
@media print {
  .print\:hidden {
    display: none;
  }
}
@media (min-width: 576) {
  .xs\:flex {
    display: flex;
  }
}
@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm\:my-0 {
    margin-bottom: 0;
    margin-top: 0;
  }
  .sm\:block {
    display: block;
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-6 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}
@media (min-width: 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:mr-4 {
    margin-right: 1rem;
  }
  .md\:block {
    display: block;
  }
  .md\:inline-block {
    display: inline-block;
  }
  .md\:flex {
    display: flex;
  }
  .md\:inline-flex {
    display: inline-flex;
  }
  .md\:grid {
    display: grid;
  }
  .md\:w-52 {
    width: 13rem;
  }
  .md\:w-\[260px\] {
    width: 260px;
  }
  .md\:min-w-\[340px\] {
    min-width: 340px;
  }
  .md\:min-w-\[450px\] {
    min-width: 450px;
  }
  .md\:min-w-\[200px\] {
    min-width: 200px;
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  [dir="ltr"] .ltr\:md\:mr-3 {
    margin-right: 0.75rem;
  }
  [dir="ltr"] .ltr\:md\:ml-2 {
    margin-left: 0.5rem;
  }
  [dir="ltr"] .ltr\:md\:border-r {
    border-right-width: 1px;
  }
  [dir="ltr"] .ltr\:md\:border-l {
    border-left-width: 1px;
  }
  [dir="rtl"] .rtl\:md\:ml-3 {
    margin-left: 0.75rem;
  }
  [dir="rtl"] .rtl\:md\:mr-2 {
    margin-right: 0.5rem;
  }
  [dir="rtl"] .rtl\:md\:border-l {
    border-left-width: 1px;
  }
  [dir="rtl"] .rtl\:md\:border-r {
    border-right-width: 1px;
  }
  .dark .md\:dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
}
@media (min-width: 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg\:mb-0 {
    margin-bottom: 0;
  }
  .lg\:block {
    display: block;
  }
  .lg\:inline-block {
    display: inline-block;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:w-\[280px\] {
    width: 280px;
  }
  .lg\:w-52 {
    width: 13rem;
  }
  .lg\:w-\[400px\] {
    width: 400px;
  }
  .lg\:min-w-\[800px\] {
    min-width: 800px;
  }
  .lg\:min-w-\[600px\] {
    min-width: 600px;
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:border-0 {
    border-width: 0;
  }
  .lg\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  [dir="ltr"] .ltr\:lg\:border-l {
    border-left-width: 1px;
  }
  [dir="rtl"] .rtl\:lg\:border-r {
    border-right-width: 1px;
  }
}
@media (min-width: 1280px) {
  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xl\:block {
    display: block;
  }
  .xl\:flex {
    display: flex;
  }
  .xl\:grid {
    display: grid;
  }
  .xl\:w-\[380px\] {
    width: 380px;
  }
  .xl\:w-\[260px\] {
    width: 260px;
  }
  .xl\:min-w-\[450px\] {
    min-width: 450px;
  }
  .xl\:min-w-\[800px\] {
    min-width: 800px;
  }
  .xl\:max-w-\[360px\] {
    max-width: 360px;
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-col {
    flex-direction: column;
  }
  .xl\:items-center {
    align-items: center;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  [dir="ltr"] .ltr\:xl\:ml-\[280px\] {
    margin-left: 280px;
  }
  [dir="rtl"] .rtl\:xl\:mr-\[280px\] {
    margin-right: 280px;
  }
}
@media (min-width: 1536px) {
  .\32xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .\32xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .\32xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .\32xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .\32xl\:min-w-\[360px\] {
    min-width: 360px;
  }
  .\32xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.PhoneInput input:not(.react-phone-number-input__input) {
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-radius: 0.2em;
  border: 1px solid #808080;
  font-size: 20px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.PhoneInputInput {
  height: 3rem;
}
.PhoneInputCountryIcon {
  width: 2.3rem !important;
  height: 1.6rem !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.PhoneInputCountrySelectArrow {
  width: 0.5rem !important;
  height: 0.5rem !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.PhoneInputCountry {
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  border-radius: 0.2em;
  outline: none;
  appearance: none;
}
.PhoneInputCountry {
  border: 1px solid #808080;
}
.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.4rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid #808080;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.min-h-full {
  min-height: 100%;
}
:is(.dark .dark\:shadow-black\/20) {
  --tw-shadow-color: rgb(0 0 0 / 0.2);
  --tw-shadow: var(--tw-shadow-colored);
}
.gap-x-2 {
  column-gap: 0.5rem;
}
.scroll-none {
  -ms-overflow-style: none;
  scrollbar-width: 1px;
}
.scroll-none::-webkit-scrollbar {
  display: none;
}
.hidden-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 5px;
}
.hidden-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.hidden-scroll::-webkit-scrollbar-thumb {
  background: rgb(187, 185, 185);
  border-radius: 15px;
  height: 10px;
}
.justify-evenly {
  justify-content: space-evenly;
}
.bg-auto {
  background-size: auto;
}
.apexcharts-toolbar {
  display: none !important;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.htmlh1 {
  font-size: 2rem;
  font-weight: bolder;
}
.htmlh2 {
  font-size: 1.5rem;
  font-weight: bolder;
}
.htmlh3 {
  font-size: 1.17rem;
  font-weight: bolder;
}
.htmlh4 {
  font-size: 1rem;
  font-weight: bolder;
}
.htmlol {
  list-style: auto;
}
.htmlul {
  list-style: disc;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-0 {
  padding-left: 0;
  padding-right: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.video-responsive {
  overflow: hidden;
  height: 50px;
  width: 18%;
}
.video-responsive iframe {
  height: 50px;
  width: 18%;
}
.ddd {
  width: 72%;
}
.rrr {
  width: 80%;
}
.border-r-2 {
  border-right-width: 2px;
}
.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.editorheight {
  min-height: 500px;
}
@media (min-width: 1280px) {
  .xl\:w-3\/5 {
    width: 60%;
  }
}
.select-error {
  border: 2px solid red;
  border-radius: 0.5rem;
}
.z-auto {
  z-index: auto !important;
}
@media (min-width: 1280px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
